import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import {
  candidateClient,
  contractClient,
  contractorClient,
  demandClient,
  evaluationClient,
  interviewClient,
  interviewerClient,
} from '@services/api/marketplace/demand';
import { convertDemands, getTagsFromPayload, UpdateContractForm } from '@store/marketplace/helpers';

import { TCreateInterviewForm } from '@pages/Marketplace/ManageDemands/settings';
import { ObjectTags, Tag, Tags } from '@proto/profiler/tags/v1/tags_pb';
import { TCreateContractForm } from '@pages/Marketplace/Dashboards/CreateContracForm';
import {
  Contract_Traits,
  CreateContractRequest,
  CreateContractResponse,
  DeleteContractRequest,
  UpdateContractDescriptionRequest,
  UpdateContractTraitsRequest,
} from '@proto/marketplace/demand/v1/contract_pb';

import { Timestamp } from '@bufbuild/protobuf';
import { sortDemandsBySupply, transformFeedItems } from '@services/api/marketplace/config';
import { Entity } from '@proto/grpc/type/v1/entity_pb';
import { showSnackbar } from '@store/snackbars';
import { useAppDispatch } from '@store/helpers';
import { marketplaceSlice } from '@store/marketplace/slices';
import { Profile } from '@proto/profiler/user/v1/profile_pb';
import { Org } from '@proto/profiler/biz/v1/org_pb';
import {
  AllocateSlotsRequest,
  CreateInterviewRequest,
  CreateInterviewRequest_NewInterview,
  CreateInterviewResponse,
  Interview_Traits,
  UpdateInterviewRequest,
  UpdateInterviewRequirementsRequest,
} from '@proto/marketplace/demand/v1/interview_pb';
import {
  Demand_Status_Status,
  DemandEntityId,
  GetDemandRequest,
  GetDemandResponse,
  ListAppliedDemandsRequest,
  ListAppliedDemandsResponse,
  SetDemandStatusRequest,
} from '@proto/marketplace/demand/v1/demand_pb';
import {
  ApplyOnContractsRequest,
  ApproveContractorRequest,
  Contractors,
  RejectContractorRequest,
} from '@proto/marketplace/demand/v1/contractor_pb';
import {
  AcceptEvaluationFeedbackRequest,
  AddEvaluationScheduleSlotsRequest,
  AddEvaluationScheduleSlotsResponse,
  DeleteEvaluationScheduleSlotRequest,
  DeleteEvaluationScheduleSlotResponse,
  Evaluation,
  EvaluationScheduleSlots,
  GetEvaluationRequest,
  GetEvaluationResponse,
  GetInterviewerAvailabilityRequest,
  ListEvaluationsRequest,
  OccupySlotRequest,
  SaveEvaluationFeedbackDraftRequest,
  SendEvaluationFeedbackRequest,
} from '@proto/marketplace/demand/v1/evaluation_pb';
import {
  CreateDashboardRequest,
  CreateDashboardResponse,
  GetDashboardRequest,
  GetDashboardResponse,
  ListDashboardsRequest,
  ListDashboardsResponse,
  ListFeedItemsRequest,
  ListFeedItemsRequest_Offset,
  ListFeedItemsResponse,
  UpdateDashboardRequest,
} from '@proto/marketplace/dashboard/v1/dashboard_pb';
import {
  ApplyAsInterviewerRequest,
  ApproveInterviewerRequest,
  AssignInterviewerToEvaluationRequest,
  RejectInterviewerRequest,
} from '@proto/marketplace/demand/v1/interviewer_pb';
import { InviteCandidatesRequest } from '@proto/marketplace/demand/v1/candidate_pb';
import { UUID, UUIDS } from '@proto/grpc/type/v1/uuid_pb';
import { getConnectClient } from '@services/api/helpers';
import { DashboardAPI } from '@proto/api/marketplace/v1/dashboad_connect';
import { ListEvaluationsResponse } from '@proto/api/marketplace/v1/evaluation_pb';
import { isInvalidTimestamp } from '@utils/invalidTimestamp';
import { useFeed } from '../../zustand/feed';

const { setContractors } = marketplaceSlice.actions;

export const dashboardClient = getConnectClient(
  `${process.env.REACT_APP_HTTP_SCHEMA}://${process.env.REACT_APP_APIGW_SERVICE}.${process.env.REACT_APP_API_DOMAIN}`,
  DashboardAPI
);

// Hook for getting a demand
export const useGetDemand = (payload: { demandId: string; type: 'interview' | 'contract' }) => {
  const getDemand = async (): Promise<GetDemandResponse> => {
    return demandClient.getDemand(
      new GetDemandRequest({
        entityId: new DemandEntityId({
          id: {
            case: payload.type,
            value: new UUID({ value: payload.demandId }),
          },
        }),
      })
    );
  };

  return useQuery({
    queryKey: ['getDemand', payload.demandId],
    queryFn: getDemand,
    refetchOnMount: 'always',
    select: (response) => {
      return response.demand;
    },
  });
};

// Hook for getting an evaluation
export const useGetEvaluation = (payload: { evaluationId: string | undefined }) => {
  const getEvaluation = async (): Promise<GetEvaluationResponse> => {
    return evaluationClient.getEvaluation(
      new GetEvaluationRequest({ evaluationId: new UUID({ value: payload.evaluationId }) })
    );
  };

  return useQuery({
    queryKey: ['getEvaluation'],
    refetchOnMount: 'always',
    queryFn: getEvaluation,
  });
};

export const useAddEvaluationScheduleSlots = () => {
  const queryClient = useQueryClient();
  const addEvaluationScheduleSlots = async (payload: { from: Timestamp; to: Timestamp }) => {
    return evaluationClient.addEvaluationScheduleSlots(
      new AddEvaluationScheduleSlotsRequest({
        slots: new EvaluationScheduleSlots({
          from: payload.from,
          to: payload.to,
        }),
      })
    );
  };

  return useMutation({
    mutationFn: addEvaluationScheduleSlots,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getInterviewerAvailability'] });
    },
  });
};

// // Hook for suggesting evaluation schedule slots
// export const useSuggestEvaluationScheduleSlots = () => {
//     const suggestEvaluationScheduleSlots = async (
//         payload: SuggestEvaluationScheduleSlotsRequest
//     ): Promise<SuggestEvaluationScheduleSlotsServiceResponse> => {
//         return evaluationClient.suggestEvaluationScheduleSlots(payload);
//     };
//     const queryClient = useQueryClient();
//
//     return useMutation({
//         mutationFn: suggestEvaluationScheduleSlots,
//         onSuccess: () => {
//             queryClient.invalidateQueries({queryKey: ['listEvaluationScheduleSlots']});
//         },
//     });
// };

export const useAddEvaluationScheduleSlot = () => {
  const addEvaluationScheduleSlot = async (
    payload: AddEvaluationScheduleSlotsRequest
  ): Promise<AddEvaluationScheduleSlotsResponse> => {
    return evaluationClient.addEvaluationScheduleSlots(payload);
  };
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: addEvaluationScheduleSlot,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['listEvaluationScheduleSlots'] });
    },
  });
};

export const useDeleteEvaluationScheduleSlot = () => {
  const deleteEvaluationScheduleSlot = async (
    payload: DeleteEvaluationScheduleSlotRequest
  ): Promise<DeleteEvaluationScheduleSlotResponse> => {
    return evaluationClient.deleteEvaluationScheduleSlot(payload);
  };
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteEvaluationScheduleSlot,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['listEvaluationScheduleSlots'] });
    },
  });
};

// Hook for listing dashboards
export const useListDashboards = () => {
  const listDashboards = async (): Promise<ListDashboardsResponse> => {
    return dashboardClient.listDashboards(new ListDashboardsRequest());
  };

  return useQuery({
    queryKey: ['listDashboards'],
    queryFn: listDashboards,
  });
};

export const useListAppliedDemands = (payload?: { refetch?: boolean }) => {
  const listAppliedDemands = async (): Promise<ListAppliedDemandsResponse> => {
    try {
      return await demandClient.listAppliedDemands(new ListAppliedDemandsRequest());
    } catch (error) {
      console.error('Помилка виклику listEvaluations:', error);
      throw error;
    }
  };

  return useQuery({
    queryKey: ['listAppliedDemands'],
    queryFn: listAppliedDemands,
    select: (response) => {
      console.log({ response });
      return {
        demands:
          response?.demands && sortDemandsBySupply(transformFeedItems(response?.demands.demands)),
      };
    },
    refetchOnMount: payload?.refetch ? 'always' : false,
  });
};

export const useListFeedItems = () => {
  const { currentEntityType, isPinnedReturn } = useFeed();

  const listFeedItems = async (): Promise<ListFeedItemsResponse> => {
    return dashboardClient.listFeedItems(
      new ListFeedItemsRequest({
        recommendations: true,
        filter: {
          case: 'offset',
          value: new ListFeedItemsRequest_Offset({
            timestamp: Timestamp.fromDate(new Date()),
            demandType: currentEntityType,
            pinned: isPinnedReturn,
          }),
        },
      })
    );
  };

  return useQuery({
    queryKey: ['listFeedItems', currentEntityType, isPinnedReturn],
    queryFn: listFeedItems,
    select: (response) => {
      return {
        feedItems: response?.demands && transformFeedItems(response?.demands.demands),
        recomendations: response?.demands?.recommendations,
      };
    },
  });
};

export const useListFeedItemsByDemand = (payload: Entity | undefined) => {
  const listFeedItems = async (): Promise<ListFeedItemsResponse> => {
    return dashboardClient.listFeedItems(
      new ListFeedItemsRequest({
        recommendations: true,
        filter: {
          case: 'demand',
          value: payload!,
        },
      })
    );
  };

  return useQuery({
    queryKey: ['listFeedItemsByDemand', payload?.id],
    queryFn: listFeedItems,
    enabled: Boolean(payload),
    select: (response) => {
      return {
        feedItems: response?.demands && transformFeedItems(response?.demands.demands),
        recommendations: response.demands?.recommendations,
        feedOwner: response.demands?.owner &&
          Object.keys(response.demands?.owner).length && {
            ownerName:
              (response.demands?.owner?.owner?.value as Org)?.name ||
              `${(response.demands?.owner?.owner?.value as Profile)?.name?.firstName || ''} ${
                (response.demands?.owner?.owner?.value as Profile)?.name?.lastName || ''
              }`,
            ownerNickname: (response.demands?.owner?.owner?.value as Profile)?.name?.nickName,
            ownerId:
              (response.demands?.owner?.owner?.value as Org)?.orgId ||
              (response.demands?.owner?.owner?.value as Profile)?.profileId,
            ownerType: response.demands.owner.owner.case,
          },
      };
    },
  });
};

export const useClearListFeedItemsByDemand = () => {
  const queryClient = useQueryClient();

  const clearListFeedItemsByDemand = (id?: string) => {
    if (id) {
      queryClient.invalidateQueries({ queryKey: ['listFeedItemsByDemand', id] });
    } else {
      queryClient.invalidateQueries({ queryKey: ['listFeedItemsByDemand'] });
    }
  };

  return clearListFeedItemsByDemand;
};

export const useGetDashboard = (dashboardId: string) => {
  const getDashboard = async (): Promise<GetDashboardResponse> => {
    return dashboardClient.getDashboard(
      new GetDashboardRequest({ dashboardId: new UUID({ value: dashboardId }) })
    );
  };

  return useQuery({
    queryKey: ['getDashboard', dashboardId],
    queryFn: getDashboard,
    select: (data) => ({
      demands: convertDemands(data?.demands?.demands),
      kanbanBoard: data.dashboard.value,
      recommendations: data.demands?.recommendations,
      response: data,
    }),
  });
};

// Hook for creating a dashboard
export const useCreateDashboard = () => {
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();

  const createDashboard = async (
    payload: CreateDashboardRequest
  ): Promise<CreateDashboardResponse> => {
    return dashboardClient.createDashboard(payload);
  };

  return useMutation({
    mutationFn: createDashboard,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['listDashboards'] });
      dispatch(
        showSnackbar({ id: 'create-dashboard', message: 'Dashboard created', severity: 'success' })
      );
    },
    onError: (error) => {
      dispatch(showSnackbar({ id: 'create-dashboard', message: error.message, severity: 'error' }));
    },
  });
};

export const useCreateInterview = () => {
  const queryClient = useQueryClient();

  const createInterview = async (
    payload: TCreateInterviewForm
  ): Promise<CreateInterviewResponse> => {
    return interviewClient.createInterview(
      new CreateInterviewRequest({
        dashboard: {
          case: 'id',
          value: new UUID({ value: payload.dashboardId }),
        },
        requirements: payload.requirements,
        interview: {
          value: new CreateInterviewRequest_NewInterview({
            traits: new Interview_Traits({
              price: payload.price,
              experience: payload.experience,
            }),
            tags: new Tags({
              tags: getTagsFromPayload(payload),
            }),
          }),
          case: 'newInterview',
        },
      })
    );
  };

  return useMutation({
    mutationFn: createInterview,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getDashboard'] });
    },
  });
};

export const useCreateContract = () => {
  const queryClient = useQueryClient();

  const createContract = async (payload: TCreateContractForm): Promise<CreateContractResponse> => {
    return contractClient.createContract(
      new CreateContractRequest({
        dashboard: {
          case: 'id',
          value: new UUID({ value: payload.dashboardId }),
        },
        traits: new Contract_Traits({
          rate: payload.rate,
          capacity: payload.capacity,
          experience: payload.experience,
        }),
        description: payload.description,
        tags: new Tags({
          tags: getTagsFromPayload(payload),
        }),
        createInterview: payload.createInterview,
      })
    );
  };

  return useMutation({
    mutationFn: createContract,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getDashboard'] });
    },
  });
};

export const useSetDemandstatus = () => {
  const setDemandStatus = async (payload: { demandId: string; status: Demand_Status_Status }) => {
    return demandClient.setDemandStatus(
      new SetDemandStatusRequest({
        demandId: new UUID({ value: payload.demandId }),
        status: payload.status,
      })
    );
  };
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: setDemandStatus,
    onSuccess: (payload) => {
      queryClient.invalidateQueries({ queryKey: ['getDemand'] });
      queryClient.invalidateQueries({ queryKey: ['getDashboard'] });
    },
  });
};

export const useApplyAsContractor = () => {
  const applyAsContractor = async (payload: { contractors: Contractors }) => {
    return contractorClient.applyOnContracts(
      new ApplyOnContractsRequest({
        applyAs: {
          case: 'contractors',
          value: payload.contractors,
        },
      })
    );
  };
  const dispatch = useAppDispatch();

  return useMutation({
    mutationFn: applyAsContractor,
    onSuccess: () => {
      dispatch(setContractors(undefined));
      dispatch(
        showSnackbar({
          id: 'apply-as-contractor',
          message: 'You have successfully applied to the contract',
          severity: 'success',
        })
      );
    },
    onError: (error) => {
      dispatch(
        showSnackbar({
          id: 'apply-as-contractor',
          message: error.message,
          severity: 'error',
        })
      );
    },
  });
};

export const useUpdateDashboardInfo = () => {
  const queryClient = useQueryClient();
  const updateDashboardInfo = async (payload: { dashboardId: string; title: string }) => {
    return dashboardClient.updateDashboard(
      new UpdateDashboardRequest({
        dashboardId: new UUID({ value: payload.dashboardId }),
        title: payload.title,
      })
    );
  };

  return useMutation({
    mutationFn: updateDashboardInfo,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getDashboard'] });
    },
  });
};

export const useUpdateContractTraits = () => {
  const queryClient = useQueryClient();
  const updateContractTraits = async (payload: UpdateContractForm) => {
    return contractClient.updateContractTraits(
      new UpdateContractTraitsRequest({
        contractId: payload.contractId,
        traits: new Contract_Traits({
          rate: payload.rate,
          capacity: payload.capacity,
          experience: payload.experience,
        }),
      })
    );
  };

  return useMutation({
    mutationFn: updateContractTraits,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getDemand'] });
    },
  });
};

export const useUpdateContractDescription = () => {
  const queryClient = useQueryClient();
  const updateContractDescription = async (payload: {
    description: string;
    contractId: string;
  }) => {
    return contractClient.updateContractDescription(
      new UpdateContractDescriptionRequest({
        contractId: new UUID({ value: payload.contractId }),
        description: payload.description,
      })
    );
  };

  return useMutation({
    mutationFn: updateContractDescription,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getDemand'] });
    },
  });
};

export const useDeleteContract = () => {
  const queryClient = useQueryClient();
  const deleteContract = async (contractId: string) => {
    return contractClient.deleteContract(
      new DeleteContractRequest({ contractId: new UUID({ value: contractId }) })
    );
  };

  return useMutation({
    mutationFn: deleteContract,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getDashboard'] });
    },
  });
};

export const useUpdateInterviewInfo = () => {
  const queryClient = useQueryClient();
  const updateInterviewInfo = async (payload: {
    price: number;
    experience: number;
    interviewId: string;
  }) => {
    return interviewClient.updateInterview(
      new UpdateInterviewRequest({
        interviewId: new UUID({ value: payload.interviewId }),
        traits: new Interview_Traits({
          price: payload.price,
          experience: payload.experience,
        }),
      })
    );
  };

  return useMutation({
    mutationFn: updateInterviewInfo,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getDemand'] });
    },
  });
};

export const useCreateInterviewForContract = () => {
  const queryClient = useQueryClient();
  const createInterviewForContract = async (payload: {
    dashboardId: string;
    contractId: string;
    requirements: string;
  }) => {
    return interviewClient.createInterview(
      new CreateInterviewRequest({
        dashboard: {
          case: 'id',
          value: new UUID({ value: payload.dashboardId }),
        },
        requirements: payload.requirements,
        interview: {
          value: new UUID({ value: payload.contractId }),
          case: 'contractId',
        },
      })
    );
  };

  return useMutation({
    mutationFn: createInterviewForContract,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getDemand'] });
    },
  });
};

export const useApplyAsInterviewer = () => {
  const queryClient = useQueryClient();

  const applyAsInterviewer = async (payload: string) => {
    return interviewerClient.applyAsInterviewer(
      new ApplyAsInterviewerRequest({
        interviewId: new UUID({ value: payload }),
      })
    );
  };
  const dispatch = useAppDispatch();

  return useMutation({
    mutationFn: applyAsInterviewer,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['listAppliedDemands'] });
      dispatch(
        showSnackbar({
          id: 'apply-as-interviewer',
          message: 'You have successfully applied to the interview',
          severity: 'success',
        })
      );
    },
    onError: (error) => {
      dispatch(
        showSnackbar({
          id: 'apply-as-interviewer',
          message: error.message,
          severity: 'error',
        })
      );
    },
  });
};

export const useApproveContractor = () => {
  const queryClient = useQueryClient();
  const approveContractor = async (payload: { supplyId: string }) => {
    return contractorClient.approveContractor(
      new ApproveContractorRequest({
        supplyId: new UUID({ value: payload.supplyId }),
      })
    );
  };

  return useMutation({
    mutationFn: approveContractor,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getDemand'] });
    },
  });
};

export const useRejectContractor = () => {
  const queryClient = useQueryClient();
  const rejectContractor = async (payload: { supplyId: string }) => {
    return contractorClient.rejectContractor(
      new RejectContractorRequest({
        supplyId: new UUID({ value: payload.supplyId }),
      })
    );
  };

  return useMutation({
    mutationFn: rejectContractor,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getDemand'] });
    },
  });
};

export const useApproveInterviewer = () => {
  const queryClient = useQueryClient();
  const approveInterviewer = async (payload: { supplyId: string; interviewId: string }) => {
    return interviewerClient.approveInterviewer(
      new ApproveInterviewerRequest({
        interviewId: new UUID({ value: payload.interviewId }),
        supplyId: new UUID({ value: payload.supplyId }),
      })
    );
  };

  return useMutation({
    mutationFn: approveInterviewer,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getDemand'] });
    },
  });
};

export const useRejectInterviewer = () => {
  const queryClient = useQueryClient();
  const rejectInterviewer = async (payload: { supplyId: string; interviewId: string }) => {
    return interviewerClient.rejectInterviewer(
      new RejectInterviewerRequest({
        interviewId: new UUID({ value: payload.interviewId }),
        supplyId: new UUID({ value: payload.supplyId }),
      })
    );
  };

  return useMutation({
    mutationFn: rejectInterviewer,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getDemand'] });
    },
  });
};

export const useAssignInterviewerToEvaluation = () => {
  const queryClient = useQueryClient();
  const assignInterviewerToEvaluation = async (payload: UUID | undefined) => {
    return interviewerClient.assignInterviewerToEvaluation(
      new AssignInterviewerToEvaluationRequest({
        interviewId: payload,
      })
    );
  };

  return useMutation({
    mutationFn: assignInterviewerToEvaluation,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getDemand'] });
    },
  });
};

export const useInviteCandidates = () => {
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();
  const inviteCandidates = async (payload: { interviewId: string; profileIds: string[] }) => {
    return candidateClient.inviteCandidates(
      new InviteCandidatesRequest({
        interviewId: new UUID({ value: payload.interviewId }),
        profiles: new UUIDS({ values: payload.profileIds }),
      })
    );
  };

  return useMutation({
    mutationFn: inviteCandidates,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['invitesList'] });
      dispatch(
        showSnackbar({
          id: 'invite-candidates',
          message: 'Candidates invited',
          severity: 'success',
        })
      );
    },
    onError: (error) => {
      dispatch(
        showSnackbar({ id: 'invite-candidates', message: error.message, severity: 'error' })
      );
    },
  });
};

export const useSaveEvaluationFeedbackDraft = () => {
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();

  const saveEvaluationFeedbackDraft = async (payload: {
    evaluationId: string;
    feedback: string;
  }) => {
    return evaluationClient.saveEvaluationFeedbackDraft(
      new SaveEvaluationFeedbackDraftRequest({
        evaluationId: new UUID({ value: payload.evaluationId }),
        feedback: payload.feedback,
      })
    );
  };

  return useMutation({
    mutationFn: saveEvaluationFeedbackDraft,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getEvaluation'] });
      dispatch(
        showSnackbar({
          id: 'save-evaluation-feedback-draft',
          message: 'Feedback saved',
          severity: 'success',
        })
      );
    },
    onError: (error) => {
      dispatch(
        showSnackbar({
          id: 'save-evaluation-feedback-draft',
          message: error.message,
          severity: 'error',
        })
      );
    },
  });
};

export const useSendEvaluationFeedback = () => {
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  const sendEvaluationFeedback = async (payload: { evaluationId: string }) => {
    return evaluationClient.sendEvaluationFeedback(
      new SendEvaluationFeedbackRequest({ evaluationId: new UUID({ value: payload.evaluationId }) })
    );
  };

  return useMutation({
    mutationFn: sendEvaluationFeedback,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getEvaluation'] });
      dispatch(
        showSnackbar({
          id: 'send-evaluation-feedback',
          message: 'Feedback sent',
          severity: 'success',
        })
      );
    },
    onError: (error) => {
      dispatch(
        showSnackbar({ id: 'send-evaluation-feedback', message: error.message, severity: 'error' })
      );
    },
  });
};

export const useUpdateInterviewRequirements = () => {
  const queryClient = useQueryClient();
  const updateInterviewRequirements = async (payload: {
    interviewId: string;
    requirements: string;
  }) => {
    return interviewClient.updateInterviewRequirements(
      new UpdateInterviewRequirementsRequest({
        interviewId: new UUID({ value: payload.interviewId }),
        requirements: payload.requirements,
      })
    );
  };

  return useMutation({
    mutationFn: updateInterviewRequirements,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getDemand'] });
    },
  });
};

function transformTagsStructure(tags: ObjectTags[]) {
  return tags.reduce((acc, item) => {
    const entityId = item?.entity?.id?.value as string;
    const tagsArray = item?.tags?.tags as Tag[];

    acc[entityId] = tagsArray;
    return acc;
  }, {} as Record<string, Tag[]>);
}

export type SortedEvaluations = {
  notScheduled: Evaluation[];
  scheduled: Evaluation[];
  completed: Evaluation[];
};

export function categorizeEvaluations(evaluations: Evaluation[]): SortedEvaluations {
  const now = new Date();

  return evaluations.reduce(
    (result, evaluation) => {
      const { scheduledAt } = evaluation?.status || {};
      if (!scheduledAt || !isInvalidTimestamp(scheduledAt.toJsonString())) {
        result.notScheduled.push(evaluation);
      } else {
        const scheduledDate = new Date(Number(scheduledAt.seconds) * 1000);
        const plusOneHour = new Date(scheduledDate.getTime() + 60 * 60 * 1000);

        if (plusOneHour > now) {
          result.scheduled.push(evaluation);
        } else {
          result.completed.push(evaluation);
        }
      }
      return result;
    },
    { notScheduled: [], scheduled: [], completed: [] } as {
      notScheduled: Evaluation[];
      scheduled: Evaluation[];
      completed: Evaluation[];
    }
  );
}

export const useListEvaluations = (payload?: { refetch?: boolean }) => {
  const listEvaluations = async (): Promise<ListEvaluationsResponse> => {
    try {
      return await evaluationClient.listEvaluations(new ListEvaluationsRequest());
    } catch (error) {
      console.error('Помилка виклику listEvaluations:', error);
      throw error;
    }
  };

  return useQuery({
    queryKey: ['listEvaluations'],
    select: (data) => {
      return {
        tags: transformTagsStructure(data?.tags || []),
        evaluations: data.evaluations?.evaluations,
        sortedEvalutions:
          data.evaluations?.evaluations && categorizeEvaluations(data.evaluations?.evaluations),
      };
    },
    queryFn: listEvaluations,
    refetchOnMount: payload?.refetch ? 'always' : false,
  });
};

export const useGetInterviewerrAvailability = (payload: {
  case: 'evaluationId' | 'interviewerId';
  value: UUID;
  isEnabled?: boolean;
}) => {
  const getInterviewerAvailability = async () => {
    return evaluationClient.getInterviewerAvailability(
      new GetInterviewerAvailabilityRequest({
        by: {
          case: payload.case,
          value: payload.value,
        },
      })
    );
  };

  return useQuery({
    queryKey: ['getInterviewerAvailability', payload.value],
    queryFn: getInterviewerAvailability,
    enabled: !!payload.value && payload.isEnabled,
  });
};

export const useOccupySlot = () => {
  const queryClient = useQueryClient();
  const occupySlot = async (payload: { evaluationId: UUID; from: Timestamp }) => {
    return evaluationClient.occupySlot(
      new OccupySlotRequest({
        evaluationId: payload.evaluationId,
        from: payload.from,
      })
    );
  };

  return useMutation({
    mutationFn: occupySlot,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getInterviewerAvailability'] });
      queryClient.invalidateQueries({ queryKey: ['listEvaluations'] });
    },
  });
};

export const useAcceptEvaluationFeedback = () => {
  const queryClient = useQueryClient();
  const acceptEvaluationFeedback = async (payload: { evaluationId: UUID; interviewId: UUID }) => {
    return evaluationClient.acceptEvaluationFeedback(
      new AcceptEvaluationFeedbackRequest({
        evaluationId: payload.evaluationId,
        interviewId: payload.interviewId,
      })
    );
  };

  return useMutation({
    mutationFn: acceptEvaluationFeedback,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getEvaluation'] });
    },
  });
};

export const useAllocateSlots = () => {
  const allocateSlots = async (props: { interviewId: string }) => {
    return interviewClient.allocateSlots(
      new AllocateSlotsRequest({
        metadata: {
          interview_id: props.interviewId,
          slots: '50',
        },
      })
    );
  };

  return useMutation({
    mutationFn: allocateSlots,
  });
};
