// @generated by protoc-gen-connect-es v1.4.0 with parameter "target=ts"
// @generated from file api/marketplace/v1/break_down.proto (package api.marketplace.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import {
  AddStepRequest,
  AddStepResponse,
  GetStepResponse,
  RemoveStepRequest,
  RemoveStepResponse,
  UpdateStepRequest,
  UpdateStepResponse,
  UpdateStepsPositionsRequest,
  UpdateStepsPositionsResponse,
} from '../../../marketplace/product/v1/break_down_pb.js';
import { MethodKind } from '@bufbuild/protobuf';
import { GetStepRequest } from './break_down_pb.js';

/**
 * @generated from service api.marketplace.v1.BreakDownAPI
 */
export const BreakDownAPI = {
  typeName: 'api.marketplace.v1.BreakDownAPI',
  methods: {
    /**
     * @generated from rpc api.marketplace.v1.BreakDownAPI.AddStep
     */
    addStep: {
      name: 'AddStep',
      I: AddStepRequest,
      O: AddStepResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc api.marketplace.v1.BreakDownAPI.RemoveStep
     */
    removeStep: {
      name: 'RemoveStep',
      I: RemoveStepRequest,
      O: RemoveStepResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc api.marketplace.v1.BreakDownAPI.GetStep
     */
    getStep: {
      name: 'GetStep',
      I: GetStepRequest,
      O: GetStepResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc api.marketplace.v1.BreakDownAPI.UpdateStep
     */
    updateStep: {
      name: 'UpdateStep',
      I: UpdateStepRequest,
      O: UpdateStepResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc api.marketplace.v1.BreakDownAPI.UpdateStepsPositions
     */
    updateStepsPositions: {
      name: 'UpdateStepsPositions',
      I: UpdateStepsPositionsRequest,
      O: UpdateStepsPositionsResponse,
      kind: MethodKind.Unary,
    },
  },
} as const;
