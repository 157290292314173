// @generated by protoc-gen-connect-es v1.4.0 with parameter "target=ts"
// @generated from file api/marketplace/v1/interview.proto (package api.marketplace.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import {
  AllocateSlotsRequest,
  AllocateSlotsResponse,
  CreateInterviewRequest,
  CreateInterviewResponse,
  DeleteInterviewRequest,
  DeleteInterviewResponse,
  ListInterviewsForContractResponse,
  UpdateInterviewRequest,
  UpdateInterviewRequirementsRequest,
  UpdateInterviewRequirementsResponse,
  UpdateInterviewResponse,
} from '../../../marketplace/demand/v1/interview_pb.js';
import { MethodKind } from '@bufbuild/protobuf';
import { ListInterviewsForContractRequest } from './interview_pb.js';

/**
 * @generated from service api.marketplace.v1.InterviewAPI
 */
export const InterviewAPI = {
  typeName: 'api.marketplace.v1.InterviewAPI',
  methods: {
    /**
     * @generated from rpc api.marketplace.v1.InterviewAPI.CreateInterview
     */
    createInterview: {
      name: 'CreateInterview',
      I: CreateInterviewRequest,
      O: CreateInterviewResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc api.marketplace.v1.InterviewAPI.UpdateInterview
     */
    updateInterview: {
      name: 'UpdateInterview',
      I: UpdateInterviewRequest,
      O: UpdateInterviewResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc api.marketplace.v1.InterviewAPI.UpdateInterviewRequirements
     */
    updateInterviewRequirements: {
      name: 'UpdateInterviewRequirements',
      I: UpdateInterviewRequirementsRequest,
      O: UpdateInterviewRequirementsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc api.marketplace.v1.InterviewAPI.DeleteInterview
     */
    deleteInterview: {
      name: 'DeleteInterview',
      I: DeleteInterviewRequest,
      O: DeleteInterviewResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc api.marketplace.v1.InterviewAPI.ListInterviewsForContract
     */
    listInterviewsForContract: {
      name: 'ListInterviewsForContract',
      I: ListInterviewsForContractRequest,
      O: ListInterviewsForContractResponse,
      kind: MethodKind.Unary,
    },
    /**
     * TMP - DEVELOPMENT ONLY
     *
     * @generated from rpc api.marketplace.v1.InterviewAPI.AllocateSlots
     */
    allocateSlots: {
      name: 'AllocateSlots',
      I: AllocateSlotsRequest,
      O: AllocateSlotsResponse,
      kind: MethodKind.Unary,
    },
  },
} as const;
