import { useController, useFormContext } from 'react-hook-form';
import Box from '@mui/material/Box';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';

type TSelectControllerProps = {
  name: string;
  label: string;
  options: { label: string; value: any }[];
};

export const SelectController = (props: TSelectControllerProps): JSX.Element => {
  const { name, label, options } = props;

  const { control } = useFormContext();
  const {
    field: { onChange, value },
  } = useController({ control, name });

  return (
    <Box>
      <Typography variant="subtitle2" className="!mb-2">
        {label}
      </Typography>
      <Select value={value} onChange={onChange} fullWidth>
        {options.map((item) => {
          return (
            <MenuItem key={item.value} value={item.value}>
              <Typography textTransform="capitalize">{item.label}</Typography>
            </MenuItem>
          );
        })}
      </Select>
    </Box>
  );
};
