// @generated by protoc-gen-es v1.9.0 with parameter "target=ts"
// @generated from file api/profiler/v1/org.proto (package api.profiler.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type {
  BinaryReadOptions,
  FieldList,
  JsonReadOptions,
  JsonValue,
  PartialMessage,
  PlainMessage,
} from '@bufbuild/protobuf';
import { Message, proto3 } from '@bufbuild/protobuf';
import { Filter } from '../../../grpc/type/v1/entity_pb.js';
import { UUIDS } from '../../../grpc/type/v1/uuid_pb.js';

/**
 * @generated from message api.profiler.v1.GetOrgRequest
 */
export class GetOrgRequest extends Message<GetOrgRequest> {
  /**
   * @generated from field: grpc.type.v1.Filter filter = 1;
   */
  filter?: Filter;

  constructor(data?: PartialMessage<GetOrgRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'api.profiler.v1.GetOrgRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'filter', kind: 'message', T: Filter },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetOrgRequest {
    return new GetOrgRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetOrgRequest {
    return new GetOrgRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetOrgRequest {
    return new GetOrgRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: GetOrgRequest | PlainMessage<GetOrgRequest> | undefined,
    b: GetOrgRequest | PlainMessage<GetOrgRequest> | undefined
  ): boolean {
    return proto3.util.equals(GetOrgRequest, a, b);
  }
}

/**
 * @generated from message api.profiler.v1.ListOrgsRequest
 */
export class ListOrgsRequest extends Message<ListOrgsRequest> {
  /**
   * @generated from field: grpc.type.v1.UUIDS ids = 1;
   */
  ids?: UUIDS;

  constructor(data?: PartialMessage<ListOrgsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'api.profiler.v1.ListOrgsRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'ids', kind: 'message', T: UUIDS },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListOrgsRequest {
    return new ListOrgsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListOrgsRequest {
    return new ListOrgsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListOrgsRequest {
    return new ListOrgsRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: ListOrgsRequest | PlainMessage<ListOrgsRequest> | undefined,
    b: ListOrgsRequest | PlainMessage<ListOrgsRequest> | undefined
  ): boolean {
    return proto3.util.equals(ListOrgsRequest, a, b);
  }
}
