// @generated by protoc-gen-es v1.9.0 with parameter "target=ts"
// @generated from file api/marketplace/v1/break_down.proto (package api.marketplace.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type {
  BinaryReadOptions,
  FieldList,
  JsonReadOptions,
  JsonValue,
  PartialMessage,
  PlainMessage,
} from '@bufbuild/protobuf';
import { Message, proto3 } from '@bufbuild/protobuf';
import { UUID } from '../../../grpc/type/v1/uuid_pb.js';

/**
 * @generated from message api.marketplace.v1.GetStepRequest
 */
export class GetStepRequest extends Message<GetStepRequest> {
  /**
   * @generated from field: grpc.type.v1.UUID step_id = 1;
   */
  stepId?: UUID;

  constructor(data?: PartialMessage<GetStepRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'api.marketplace.v1.GetStepRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'step_id', kind: 'message', T: UUID },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetStepRequest {
    return new GetStepRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetStepRequest {
    return new GetStepRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetStepRequest {
    return new GetStepRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: GetStepRequest | PlainMessage<GetStepRequest> | undefined,
    b: GetStepRequest | PlainMessage<GetStepRequest> | undefined
  ): boolean {
    return proto3.util.equals(GetStepRequest, a, b);
  }
}
