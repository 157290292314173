import * as React from 'react';
import { useEffect, useState } from 'react';
import { Box, Chip, Tooltip, Typography } from '@mui/material';
import { TagTypeEnum, TTag } from '@services/api/profiler/tags';
import { transformTagsToTagsByType } from '@utils/transformTagsToTagsByType';
import InfoIcon from '@mui/icons-material/Info';
import { useParams } from 'react-router-dom';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import Card from '@components/Card/Card';
import {
  useApproveContractor,
  useApproveInterviewer,
  useRejectContractor,
  useRejectInterviewer,
} from '@services/queries/MarketplaceQueries';
import { Profile } from '@proto/profiler/user/v1/profile_pb';
import { Supply } from '@proto/marketplace/demand/v1/supply_pb';
import { isInvalidTimestamp } from '@utils/invalidTimestamp';

type UserSupplierCardProps = {
  supplier: Profile;
  demandType: string;
  supply: Supply;
};

const UserSupplierCard = (props: UserSupplierCardProps) => {
  const { supplier, demandType, supply } = props;
  const { demandId } = useParams();
  const [tagByType, setTagByType] = useState<Array<[TagTypeEnum, TTag[]]>>([]);
  const { mutate: approveContractor } = useApproveContractor();
  const { mutate: rejectContractor } = useRejectContractor();
  const { mutate: approveInterviewer } = useApproveInterviewer();
  const { mutate: rejectInterviewer } = useRejectInterviewer();
  const isApproved = isInvalidTimestamp(`${supply?.status?.approvedAt?.toJsonString()}`);
  const isRejected = isInvalidTimestamp(`${supply.status?.rejectedAt?.toJsonString()}`);

  const approveSupplierHandler = () => {
    if (demandId)
      return demandType === 'contract'
        ? approveContractor({
            supplyId: supply.supplyId?.value as string,
          })
        : approveInterviewer({
            interviewId: demandId,
            supplyId: supply.supplyId?.value as string,
          });
    return null;
  };
  const rejectSupplierHandler = () => {
    if (demandId)
      return demandType === 'contract'
        ? rejectContractor({
            supplyId: supply.supplyId?.value as string,
          })
        : rejectInterviewer({
            interviewId: demandId,
            supplyId: supply.supplyId?.value as string,
          });
    return null;
  };

  useEffect(() => {
    setTagByType(transformTagsToTagsByType(supplier?.tags?.tags || []));
  }, [supplier?.tags?.tags]);

  return !isRejected ? (
    <Card>
      <Box display="flex" flexDirection="column" gap={1}>
        <Box display="flex" gap={1} alignItems="center">
          <Avatar
            sx={{
              width: 50,
              height: 50,
              backgroundColor: 'rgb(255, 255, 255, 0.2)',
              color: 'white',
            }}
            alt={supplier?.name?.nickName.toUpperCase() || 'User'}
            src={`${process.env.REACT_APP_AVATARS_ENDPOINT}/${supplier?.profileId}/${supplier?.profileId}`}
          />
          <Box>
            <Typography color="secondary" textTransform="capitalize" variant="subtitle2">
              {supplier?.name?.firstName} {supplier?.name?.lastName}
            </Typography>
            <Typography fontWeight="bold">@{supplier?.name?.nickName}</Typography>
          </Box>
        </Box>

        {supplier?.traits?.experience && (
          <Tooltip placement="right" title="years">
            <Box display="flex" gap={1} alignItems="center">
              <InfoIcon fontSize="small" />
              <Typography>Experience: {supplier.traits.experience}+ </Typography>
            </Box>
          </Tooltip>
        )}

        {supplier?.traits?.capacity && (
          <Tooltip placement="right" title="hours per week">
            <Box display="flex" gap={1} alignItems="center">
              <InfoIcon fontSize="small" />
              <Typography>Capacity: {supplier.traits.capacity} </Typography>
            </Box>
          </Tooltip>
        )}
        {supplier?.traits?.rate && <Typography>Rate: ${supplier.traits.rate}</Typography>}

        <Box display="flex" gap={1} alignItems="center" flexWrap="wrap">
          {tagByType
            .filter(
              ([type]) =>
                type.toString() !== 'TAG_TYPE_ROLE' && type.toString() !== 'TAG_TYPE_COUNTRY'
            )
            .map(([type, tags]) => (
              <Box display="flex" alignItems="center" gap={0.5} flexWrap="wrap">
                {tags.map((tag) => (
                  <Chip key={tag.value} label={tag.value} variant="outlined" />
                ))}
              </Box>
            ))}
        </Box>

        {!isApproved && (
          <Box display="flex" flexDirection="column" gap={1}>
            <Button onClick={approveSupplierHandler} variant="outlined" color="success">
              Approve
            </Button>
            <Button onClick={rejectSupplierHandler} variant="outlined" color="error">
              Reject
            </Button>
          </Box>
        )}
      </Box>
    </Card>
  ) : null;
};

export default UserSupplierCard;
