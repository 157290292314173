import Box from '@mui/material/Box';
import LoadingButton from '@mui/lab/LoadingButton';
import { Evaluation } from '@proto/marketplace/demand/v1/evaluation_pb';
import { useController, useFormContext } from 'react-hook-form';
import SaveIcon from '@mui/icons-material/Save';

const UpdateFeedbackButton = ({ evaluation }: { evaluation: Evaluation }) => {
  const { control } = useFormContext();
  const {
    field: { onChange, value },
  } = useController({ control, name: 'feedback' });

  return (
    <Box className="flex justify-end">
      <LoadingButton
        disabled={value === evaluation?.feedback?.text}
        type="submit"
        variant="contained"
      >
        <SaveIcon />
      </LoadingButton>
    </Box>
  );
};
export default UpdateFeedbackButton;
