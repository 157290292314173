import React from 'react';
import { Link } from 'react-router-dom';
import { Tooltip } from '@mui/material';
import Box from '@mui/material/Box';

const DisabledLink = ({
  to,
  disabled,
  noScheduled,
  children,
  handleOpen,
  className,
}: {
  to: string;
  noScheduled: boolean;
  handleOpen?: () => void;
  disabled: boolean;
  children: React.ReactNode;
  className?: string;
}) => {
  if (!disabled) {
    return (
      <Tooltip
        title="You cannot interact with this evaluation yet, please contact the manager interview"
        placement="top"
      >
        <div className={` ${className || ''}`} style={{ color: 'gray' }}>
          {children}
        </div>
      </Tooltip>
    );
  }

  if (noScheduled) {
    return <Box onClick={handleOpen}>{children}</Box>;
  }

  return (
    <Link className={className} to={to}>
      {children}
    </Link>
  );
};

export default DisabledLink;
