import Box from '@mui/material/Box';
import { useParams } from 'react-router-dom';
import { Stack, Typography } from '@mui/material';
import React from 'react';
import Card from '@components/Card/Card';
import EventIcon from '@mui/icons-material/Event';
import ParticipantCard from '@pages/Marketplace/Dashboards/ParticipantCard';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import ListInvitedCandidates from '@pages/Marketplace/ManageDemands/Interviews/ListInvitedCandidates';
import ScrollableContainer from '@components/ScrollableContainerІ';
import { useGetDemand } from '@services/queries/MarketplaceQueries';
import ListSuppliers from '@pages/Marketplace/Dashboards/ListSuppliers';
import { Interview } from '@proto/marketplace/demand/v1/interview_pb';
import { Evaluation_Profiles } from '@proto/marketplace/demand/v1/evaluation_pb';
import MDRenderer from '@components/Form/controllers/WysiwygController/MDRenderer';

const ManageEvaluationsList = () => {
  const { demandId } = useParams();
  const { data: demand } = useGetDemand({ demandId: demandId || '', type: 'interview' });

  return (
    <Box display="flex" gap={2}>
      <Box display="flex" gap={1} flexDirection="column" width="25%">
        <ScrollableContainer maxHeight="75vh">
          <Box display="flex" flexDirection="column" gap={2}>
            <Stack direction="row" alignItems="center" gap={1}>
              <Typography variant="subtitle2">Interviewers</Typography>
            </Stack>
            {demand?.supply?.view?.value ? (
              <Box display="flex" gap={2} flexDirection="column">
                <ListSuppliers demandType="interview" supply={demand?.supply} />
              </Box>
            ) : (
              <Typography>No interviewers</Typography>
            )}
          </Box>
        </ScrollableContainer>
      </Box>
      <Box width="25%">
        {demandId && <ListInvitedCandidates objectName="Interview" entityId={demandId} />}
      </Box>
      <Box width="50%">
        <ScrollableContainer maxHeight="75vh">
          <Box display="flex" flexDirection="column" gap={2}>
            <Typography variant="subtitle2">Evaluations</Typography>
            {(demand?.entity?.value as Interview)?.evaluations?.evaluations?.length ? (
              <Box display="flex" flexDirection="column" gap={1}>
                {(demand?.entity.value as Interview).evaluations?.evaluations?.map((evaluation) => {
                  return (
                    <Card>
                      <Box display="flex" flexDirection="column" gap={1}>
                        <Box display="flex" gap={1} alignItems="center">
                          <EventIcon />
                          <Typography variant="subtitle2">
                            {/* {evaluation?.createdAt && */}
                            {/*    format(new Date(evaluation?.createdAt.toString()), 'dd/MM/yyyy')} */}
                          </Typography>
                        </Box>
                        <Box display="flex" gap={2} justifyContent="space-between">
                          {(evaluation.participants.value as Evaluation_Profiles) &&
                            Object.entries(
                              evaluation.participants.value as Evaluation_Profiles
                            )?.map(([type, participant]: any) => {
                              return (
                                <Box display="flex" flexDirection="column" gap={1} width="50%">
                                  <Typography>{type.toUpperCase()}</Typography>
                                  {participant ? (
                                    <ParticipantCard participant={participant} />
                                  ) : (
                                    <Box display="flex" gap={1}>
                                      <HourglassEmptyIcon />
                                      <Typography>Wait</Typography>
                                    </Box>
                                  )}
                                </Box>
                              );
                            })}
                        </Box>

                        {evaluation?.feedback && (
                          <Box>
                            <Typography>Feedback:</Typography>
                            <MDRenderer content={evaluation.feedback.text} />
                          </Box>
                        )}
                      </Box>
                    </Card>
                  );
                })}
              </Box>
            ) : (
              <Typography>No evaluations yet</Typography>
            )}
          </Box>
        </ScrollableContainer>
      </Box>
    </Box>
  );
};

export default ManageEvaluationsList;
