import React, { Suspense } from 'react';
import { ManageMember } from '@components/MembesrList/ManageMember';
import Stack from '@mui/material/Stack';
import { Entity_Type } from '@proto/grpc/type/v1/entity_pb';
import { Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useDialog } from '@components/hooks/useDialog';
import AddMemberDialog from '@components/AddMemberDialog/AddMemberDialog';
import { userSlice } from '@store/profiler/slices';
import { useAppDispatch } from '@store/helpers';
import ManageSquadInvitesList from '@pages/Squads/ManageSquads/ManageSquadInvitesList';
import { useListMembers } from '@services/queries/MembershipsQueries';
import { ListMembersRequest } from '@proto/iam/membership/v1/membership_pb';
import { UUIDS } from '@proto/grpc/type/v1/uuid_pb';
import { Profile } from '@proto/profiler/user/v1/profile_pb';
import { useParams } from 'react-router-dom';
import { useGetSquad } from '@services/queries/SquadsQueries';
import { isUUID } from '@utils/isUUID';
import { replaceDashesWithSpaces } from '@utils/parseStrings';
import { Page } from '@components/Page/Page';
import Container from '@mui/material/Container';

const {
  actions: { deleteSelectedProfile },
} = userSlice;

const ManageSquadMembersList = () => {
  const { entityId } = useParams();
  const { data: squad } = useGetSquad({
    case: isUUID(entityId as string) ? 'id' : 'slug',
    value: isUUID(entityId as string)
      ? (entityId as string)
      : replaceDashesWithSpaces(entityId as string),
  });
  const { isOpen, handleOpen, handleClose } = useDialog();
  const dispatch = useAppDispatch();
  const { data: listMembers } = useListMembers(
    new ListMembersRequest({
      by: {
        case: 'entityIds',
        value: new UUIDS({ values: [`${entityId}`] }),
      },
    }),
    true
  );

  const rwx = squad?.squad?.rwx;

  const closeDialog = () => {
    dispatch(deleteSelectedProfile({ searchProfiles: [] }));
    handleClose();
  };

  return rwx?.r ? (
    <Page description="Orgs" title="Orgs">
      <Container>
        <Stack gap={0.5}>
          <Stack direction="row" alignItems="center" gap={1}>
            <Typography color="#bdbdbd" fontSize="13px" fontWeight="bold">
              Members
            </Typography>

            {rwx.w && (
              <AddIcon
                sx={{ width: '20px', cursor: 'pointer' }}
                onClick={() => handleOpen()}
                color="success"
              />
            )}
          </Stack>
          <ManageSquadInvitesList />
          <Stack gap={1.5}>
            {squad &&
              listMembers?.memberships[0].members
                .map((member) => member.profile.value as Profile)
                ?.map((member: Profile) => {
                  return (
                    squad && (
                      <ManageMember
                        rwx={rwx}
                        key={member?.profileId?.value as string}
                        currentEntity={squad?.squad}
                        entityId={entityId}
                        member={member}
                      />
                    )
                  );
                })}
          </Stack>
          <Suspense fallback={null}>
            <AddMemberDialog
              objectName={squad?.squad?.name}
              isOpen={isOpen}
              handleClose={closeDialog}
              entity={Entity_Type.SQUAD}
              entityId={entityId || ''}
            />
          </Suspense>
        </Stack>
      </Container>
    </Page>
  ) : null;
};
export default ManageSquadMembersList;
