import { createServiceCall, useAppDispatch, useAppSelector } from '@store/helpers';
import { useCallback } from 'react';
import { combineReducers } from '@reduxjs/toolkit';
import {
  AddTagsServiceRequest,
  AddTagsServiceResponse,
  ListTagsServiceRequest,
  ListTagsServiceResponse,
  RemoveTagsServiceRequest,
  RemoveTagsServiceResponse,
  SetTagsStatuServiceRequest,
  SetTagsStatuServiceResponse,
  tagsService,
} from '@services/api/profiler/tags';

import { getMyProfile } from '@store/profiler';

import { TagsState } from '@store/tags/tags';
import { AddTagsRequest, ListTagsRequest, TagType } from '@proto/profiler/tags/v1/tags_pb';
import { JWT } from '@services/jwt';
import { useQueryClient } from '@tanstack/react-query';
import { tagsSlice } from './slices';

const {
  actions: { setLanguage, setProgrammingLanguage, setCountries, setRoles, setIndustryDomains },
} = tagsSlice;

export const tagReducer = combineReducers({
  global: tagsSlice.reducer,
});

const addTags = createServiceCall<AddTagsServiceResponse, AddTagsServiceRequest>(
  tagsSlice.name,
  tagsService.addTags,
  ({ response }) => {
    const queryClient = useQueryClient();

    queryClient.invalidateQueries({ queryKey: ['myProfile'] });
  }
);

const listTags = createServiceCall<ListTagsServiceResponse, ListTagsServiceRequest>(
  tagsSlice.name,
  tagsService.listTags,
  ({ response, dispatch }) => {}
);

const getCountries = createServiceCall<ListTagsServiceResponse, ListTagsServiceRequest>(
  tagsSlice.name,
  tagsService.listTags,
  ({ response: { tags }, dispatch }) => {
    dispatch(setCountries({ tags }));
  }
);
const getRoles = createServiceCall<ListTagsServiceResponse, ListTagsServiceRequest>(
  tagsSlice.name,
  tagsService.listTags,
  ({ response: { tags }, dispatch }) => {
    dispatch(setRoles({ tags }));
  }
);
const getLanguages = createServiceCall<ListTagsServiceResponse, ListTagsServiceRequest>(
  tagsSlice.name,
  tagsService.listTags,
  ({ response: { tags }, dispatch }) => {
    dispatch(setLanguage({ tags }));
  }
);
const getIndustryDomains = createServiceCall<ListTagsServiceResponse, ListTagsServiceRequest>(
  tagsSlice.name,
  tagsService.listTags,
  ({ response: { tags }, dispatch }) => {
    dispatch(setIndustryDomains({ tags }));
  }
);
const getProgrammingLanguages = createServiceCall<ListTagsServiceResponse, ListTagsServiceRequest>(
  tagsSlice.name,
  tagsService.listTags,
  ({ response: { tags }, dispatch }) => {
    dispatch(setProgrammingLanguage({ tags }));
  }
);

const removeTags = createServiceCall<RemoveTagsServiceResponse, RemoveTagsServiceRequest>(
  tagsSlice.name,
  tagsService.removeTags,
  ({ response, dispatch }) => {
    dispatch(getMyProfile());
  }
);

const setTagstatus = createServiceCall<SetTagsStatuServiceResponse, SetTagsStatuServiceRequest>(
  tagsSlice.name,
  tagsService.setTagsStatus
);

export const useTags = (): TagsState & {
  addTags: (payload: AddTagsServiceRequest) => void;
  removeTags: (payload: RemoveTagsServiceRequest) => void;
  setTagstatus: (payload: any) => void;
  listTags: (payload: TagType) => void;
  getCountries: () => void;
  getLanguages: () => void;
  getRoles: () => void;
  getIndustryDomains: () => void;
  getProgrammingLanguages: () => void;
  tags: any;
  tagsForOrgs: any;
  tagsForProducts: any;
} => {
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();
  return {
    countries: useAppSelector((state) => state.tags.global.countries),
    roles: useAppSelector((state) => state.tags.global.roles),
    languages: useAppSelector((state) => state.tags.global.languages),
    industryDomains: useAppSelector((state) => state.tags.global.industryDomains),
    programmingLanguages: useAppSelector((state) => state.tags.global.programmingLanguages),
    tags: useAppSelector((state) => {
      return {
        '5': state.tags.global.countries,
        '2': state.tags.global.roles,
        '1': state.tags.global.languages,
        '3': state.tags.global.industryDomains,
        '4': state.tags.global.programmingLanguages,
      };
    }),
    tagsForOrgs: useAppSelector((state) => {
      return {
        5: state.tags.global.countries,
        1: state.tags.global.languages,
        3: state.tags.global.industryDomains,
      };
    }),
    tagsForProducts: useAppSelector((state) => {
      return {
        3: state.tags.global.industryDomains,
        4: state.tags.global.programmingLanguages,
      };
    }),
    addTags: useCallback(
      (payload) => {
        dispatch(
          addTags(
            new AddTagsRequest({
              active: true,
              tags: payload.tags,
            })
          )
        );
      },
      [dispatch]
    ),
    setTagstatus: useCallback(
      (payload) => {
        dispatch(setTagstatus(payload));
      },
      [dispatch]
    ),
    removeTags: useCallback(
      (payload) => {
        dispatch(removeTags(payload));
      },
      [dispatch]
    ),

    listTags: useCallback(
      (payload) => {
        const tokens = JWT.getJWTTokens();

        if (tokens) {
          dispatch(
            listTags(
              new ListTagsRequest({
                tagType: payload,
              })
            )
          );
        }
      },
      [dispatch]
    ),
    getCountries: useCallback(() => {
      const tokens = JWT.getJWTTokens();

      if (tokens) {
        dispatch(
          getCountries(
            new ListTagsRequest({
              tagType: TagType.COUNTRY,
            })
          )
        );
      }
    }, [dispatch]),
    getLanguages: useCallback(() => {
      const tokens = JWT.getJWTTokens();

      if (tokens) {
        dispatch(
          getLanguages(
            new ListTagsRequest({
              tagType: TagType.LANGUAGE,
            })
          )
        );
      }
    }, [dispatch]),
    getRoles: useCallback(() => {
      const tokens = JWT.getJWTTokens();

      if (tokens) {
        dispatch(
          getRoles(
            new ListTagsRequest({
              tagType: TagType.ROLE,
            })
          )
        );
      }
    }, [dispatch]),
    getIndustryDomains: useCallback(() => {
      const tokens = JWT.getJWTTokens();

      if (tokens) {
        dispatch(
          getIndustryDomains(
            new ListTagsRequest({
              tagType: TagType.INDUSTRY_DOMAIN,
            })
          )
        );
      }
    }, [dispatch]),
    getProgrammingLanguages: useCallback(() => {
      const tokens = JWT.getJWTTokens();

      if (tokens) {
        dispatch(
          getProgrammingLanguages(
            new ListTagsRequest({
              tagType: TagType.PROGRAMMING_LANGUAGE,
            })
          )
        );
      }
    }, [dispatch]),
  };
};
