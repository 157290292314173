import { getConnectClient, makeRequest, PlainMsg } from '@services/api/helpers';

import {
  CreateContractRequest,
  CreateContractResponse,
  DeleteContractRequest,
  DeleteContractResponse,
  UpdateContractDescriptionRequest,
  UpdateContractDescriptionResponse,
  UpdateContractTraitsRequest,
  UpdateContractTraitsResponse,
} from '@proto/marketplace/demand/v1/contract_pb';
import {
  CreateInterviewRequest,
  CreateInterviewResponse,
  DeleteInterviewRequest,
  UpdateInterviewRequest,
  UpdateInterviewRequirementsRequest,
  UpdateInterviewRequirementsResponse,
  UpdateInterviewResponse,
} from '@proto/marketplace/demand/v1/interview_pb';
import {
  GetDemandRequest,
  GetDemandResponse,
  ListAppliedDemandsRequest,
  ListAppliedDemandsResponse,
  SetDemandStatusRequest,
  SetDemandStatusResponse,
} from '@proto/marketplace/demand/v1/demand_pb';
import {
  ApplyAsInterviewerRequest,
  ApplyAsInterviewerResponse,
  ApproveInterviewerRequest,
  ApproveInterviewerResponse,
  RejectInterviewerRequest,
  RejectInterviewerResponse,
  RevokeInterviewerApplyRequest,
  RevokeInterviewerApplyResponse,
} from '@proto/marketplace/demand/v1/interviewer_pb';
import {
  ApproveContractorRequest,
  ApproveContractorResponse,
  RejectContractorRequest,
  RejectContractorResponse,
  RevokeContractorApplyRequest,
  RevokeContractorApplyResponse,
} from '@proto/marketplace/demand/v1/contractor_pb';
import {
  AddCandidateRequest,
  AddCandidateResponse,
  InviteCandidatesRequest,
  InviteCandidatesResponse,
  ListCandidatesRequest,
  ListCandidatesResponse,
  RemoveCandidateRequest,
  RemoveCandidateResponse,
} from '@proto/marketplace/demand/v1/candidate_pb';
import {
  GetEvaluationRequest,
  GetEvaluationResponse,
  ListEvaluationsRequest,
  ListEvaluationsResponse,
  SaveEvaluationFeedbackDraftRequest,
  SaveEvaluationFeedbackDraftResponse,
  SendEvaluationFeedbackRequest,
  SendEvaluationFeedbackResponse,
} from '@proto/marketplace/demand/v1/evaluation_pb';

import { DemandAPI } from '@proto/api/marketplace/v1/demand_connect';
import { ContractAPI } from '@proto/api/marketplace/v1/contract_connect';
import { ContractorAPI } from '@proto/api/marketplace/v1/contractor_connect';
import { InterviewAPI } from '@proto/api/marketplace/v1/interview_connect';
import { InterviewerAPI } from '@proto/api/marketplace/v1/interviewer_connect';
import { CandidateAPI } from '@proto/api/marketplace/v1/candidate_connect';
import { EvaluationAPI } from '@proto/api/marketplace/v1/evaluation_connect';
import { InterviewService } from '@proto/marketplace/demand/v1/interview_connect';

export type CreateContractServiceRequest = PlainMsg<CreateContractRequest>;
export type CreateContractServiceResponse = PlainMsg<CreateContractResponse>;
export type UpdateContractTraitsServiceRequest = PlainMsg<UpdateContractTraitsRequest>;
export type UpdateContractTraitsServiceResponse = PlainMsg<UpdateContractTraitsResponse>;
export type UpdateContractDescriptionServiceRequest = PlainMsg<UpdateContractDescriptionRequest>;
export type UpdateContractDescriptionServiceResponse = PlainMsg<UpdateContractDescriptionResponse>;
export type DeleteContractServiceRequest = PlainMsg<DeleteContractRequest>;
export type DeleteContractServiceResponse = PlainMsg<DeleteContractResponse>;
export type CreateInterviewServiceRequest = PlainMsg<CreateInterviewRequest>;
export type CreateInterviewServiceResponse = PlainMsg<CreateInterviewResponse>;

export type UpdateInterviewServiceRequest = PlainMsg<UpdateInterviewRequest>;
export type UpdateInterviewServiceResponse = PlainMsg<UpdateInterviewResponse>;
export type UpdateInterviewRequirementsServiceRequest =
  PlainMsg<UpdateInterviewRequirementsRequest>;
export type UpdateInterviewRequirementsServiceResponse =
  PlainMsg<UpdateInterviewRequirementsResponse>;
export type DeleteInterviewServiceRequest = PlainMsg<DeleteInterviewRequest>;

export type SetDemandStatusServiceRequest = PlainMsg<SetDemandStatusRequest>;
export type SetDemandStatusServiceResponse = PlainMsg<SetDemandStatusResponse>;
export type GetDemandServiceRequest = PlainMsg<GetDemandRequest>;
export type GetDemandServiceResponse = PlainMsg<GetDemandResponse>;
export type ApplyAsInterviewerServiceRequest = PlainMsg<ApplyAsInterviewerRequest>;
export type ApplyAsInterviewerServiceResponse = PlainMsg<ApplyAsInterviewerResponse>;
export type ApproveInterviewerServiceRequest = PlainMsg<ApproveInterviewerRequest>;
export type ApproveInterviewerServiceResponse = PlainMsg<ApproveInterviewerResponse>;
export type RejectInterviewerServiceRequest = PlainMsg<RejectInterviewerRequest>;
export type RejectInterviewerServiceResponse = PlainMsg<RejectInterviewerResponse>;
export type RevokeInterviewerApplyServiceRequest = PlainMsg<RevokeInterviewerApplyRequest>;
export type RevokeInterviewerApplyServiceResponse = PlainMsg<RevokeInterviewerApplyResponse>;
export type ListAppliedDemandsServiceRequest = PlainMsg<ListAppliedDemandsRequest>;
export type ListAppliedDemandsServiceResponse = PlainMsg<ListAppliedDemandsResponse>;
export type ApproveContractorServiceResponse = PlainMsg<ApproveContractorResponse>;
export type ApproveContractorServiceRequest = PlainMsg<ApproveContractorRequest>;
export type RejectContractorServiceRequest = PlainMsg<RejectContractorRequest>;
export type RejectContractorServiceResponse = PlainMsg<RejectContractorResponse>;
export type RevokeContractorApplyServiceRequest = PlainMsg<RevokeContractorApplyRequest>;
export type RevokeContractorApplyServiceResponse = PlainMsg<RevokeContractorApplyResponse>;
export type InviteCandidatesServiceRequest = PlainMsg<InviteCandidatesRequest>;
export type InviteCandidatesServiceResponse = PlainMsg<InviteCandidatesResponse>;
export type ListCandidatesServiceRequest = PlainMsg<ListCandidatesRequest>;
export type ListCandidatesServiceResponse = PlainMsg<ListCandidatesResponse>;
export type RemoveCandidateServiceRequest = PlainMsg<RemoveCandidateRequest>;
export type RemoveCandidateServiceResponse = PlainMsg<RemoveCandidateResponse>;
export type AddCandidateServiceRequest = PlainMsg<AddCandidateRequest>;
export type AddCandidateServiceResponse = PlainMsg<AddCandidateResponse>;
export type ListEvaluationServiceRequest = PlainMsg<ListEvaluationsRequest>;
export type ListEvaluationServiceResponse = PlainMsg<ListEvaluationsResponse>;
export type GetEvaluationServiceRequest = PlainMsg<GetEvaluationRequest>;
export type GetEvaluationServiceResponse = PlainMsg<GetEvaluationResponse>;
export type SendEvaluationFeedbackServiceRequest = PlainMsg<SendEvaluationFeedbackRequest>;
export type SendEvaluationFeedbackServiceResponse = PlainMsg<SendEvaluationFeedbackResponse>;
export type SaveEvaluationFeedbackDraftServiceRequest =
  PlainMsg<SaveEvaluationFeedbackDraftRequest>;
export type SaveEvaluationFeedbackDraftServiceResponse =
  PlainMsg<SaveEvaluationFeedbackDraftResponse>;

export const demandClient = getConnectClient(
  `${process.env.REACT_APP_HTTP_SCHEMA}://${process.env.REACT_APP_APIGW_SERVICE}.${process.env.REACT_APP_API_DOMAIN}`,
  DemandAPI
);

export const marketplaceClient = getConnectClient(
  `${process.env.REACT_APP_HTTP_SCHEMA}://${process.env.REACT_APP_MARKETPLACE_SERVICE}.${process.env.REACT_APP_API_DOMAIN}`,
  InterviewService
);

export const contractClient = getConnectClient(
  `${process.env.REACT_APP_HTTP_SCHEMA}://${process.env.REACT_APP_APIGW_SERVICE}.${process.env.REACT_APP_API_DOMAIN}`,
  ContractAPI
);

export const contractorClient = getConnectClient(
  `${process.env.REACT_APP_HTTP_SCHEMA}://${process.env.REACT_APP_APIGW_SERVICE}.${process.env.REACT_APP_API_DOMAIN}`,
  ContractorAPI
);

export const interviewClient = getConnectClient(
  `${process.env.REACT_APP_HTTP_SCHEMA}://${process.env.REACT_APP_APIGW_SERVICE}.${process.env.REACT_APP_API_DOMAIN}`,
  InterviewAPI
);

export const interviewerClient = getConnectClient(
  `${process.env.REACT_APP_HTTP_SCHEMA}://${process.env.REACT_APP_APIGW_SERVICE}.${process.env.REACT_APP_API_DOMAIN}`,
  InterviewerAPI
);

export const candidateClient = getConnectClient(
  `${process.env.REACT_APP_HTTP_SCHEMA}://${process.env.REACT_APP_APIGW_SERVICE}.${process.env.REACT_APP_API_DOMAIN}`,
  CandidateAPI
);

export const evaluationClient = getConnectClient(
  `${process.env.REACT_APP_HTTP_SCHEMA}://${process.env.REACT_APP_APIGW_SERVICE}.${process.env.REACT_APP_API_DOMAIN}`,
  EvaluationAPI
);

export const demandService = {
  listCandidates(request: ListCandidatesServiceRequest): Promise<ListCandidatesServiceResponse> {
    return makeRequest(candidateClient.listCandidates, request);
  },
  removeCandidate(request: RemoveCandidateServiceRequest): Promise<RemoveCandidateServiceResponse> {
    return makeRequest(candidateClient.removeCandidate, request);
  },
  addCandidate(request: AddCandidateServiceRequest): Promise<AddCandidateServiceResponse> {
    return makeRequest(candidateClient.addCandidate, request);
  },
  listEvaluations(): Promise<ListEvaluationServiceResponse> {
    return makeRequest(evaluationClient.listEvaluations, new ListEvaluationsRequest());
  },
  getEvaluation(request: GetEvaluationServiceRequest): Promise<GetEvaluationServiceResponse> {
    return makeRequest(evaluationClient.getEvaluation, request);
  },
  sendEvaluationFeedback(
    request: SendEvaluationFeedbackServiceRequest
  ): Promise<SendEvaluationFeedbackServiceResponse> {
    return makeRequest(evaluationClient.sendEvaluationFeedback, request);
  },
  saveEvaluationFeedbackDraft(
    request: SaveEvaluationFeedbackDraftServiceRequest
  ): Promise<SaveEvaluationFeedbackDraftServiceResponse> {
    return makeRequest(evaluationClient.saveEvaluationFeedbackDraft, request);
  },
  updateInterviewRequirements(
    request: UpdateInterviewRequirementsServiceRequest
  ): Promise<UpdateInterviewRequirementsServiceResponse> {
    return makeRequest(interviewClient.updateInterviewRequirements, request);
  },
};
