// @generated by protoc-gen-es v1.9.0 with parameter "target=ts"
// @generated from file marketplace/product/v1/break_down.proto (package marketplace.product.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type {
  BinaryReadOptions,
  FieldList,
  JsonReadOptions,
  JsonValue,
  PartialMessage,
  PlainMessage,
} from '@bufbuild/protobuf';
import { Message, proto3, Timestamp } from '@bufbuild/protobuf';
import { UUID } from '../../../grpc/type/v1/uuid_pb.js';
import { RWX } from '../../../iam/auth/v1/iam_pb.js';
import { Tags } from '../../../profiler/tags/v1/tags_pb.js';

/**
 * @generated from message marketplace.product.v1.BreakDown
 */
export class BreakDown extends Message<BreakDown> {
  /**
   * @generated from field: repeated marketplace.product.v1.Step steps = 1;
   */
  steps: Step[] = [];

  constructor(data?: PartialMessage<BreakDown>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.product.v1.BreakDown';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'steps', kind: 'message', T: Step, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): BreakDown {
    return new BreakDown().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): BreakDown {
    return new BreakDown().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): BreakDown {
    return new BreakDown().fromJsonString(jsonString, options);
  }

  static equals(
    a: BreakDown | PlainMessage<BreakDown> | undefined,
    b: BreakDown | PlainMessage<BreakDown> | undefined
  ): boolean {
    return proto3.util.equals(BreakDown, a, b);
  }
}

/**
 * @generated from message marketplace.product.v1.Step
 */
export class Step extends Message<Step> {
  /**
   * @generated from field: grpc.type.v1.UUID step_id = 1;
   */
  stepId?: UUID;

  /**
   * @generated from field: marketplace.product.v1.Step.Data data = 2;
   */
  data?: Step_Data;

  /**
   * @generated from field: marketplace.product.v1.Step.Status status = 3;
   */
  status?: Step_Status;

  /**
   * @generated from field: iam.auth.v1.RWX rwx = 4;
   */
  rwx?: RWX;

  constructor(data?: PartialMessage<Step>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.product.v1.Step';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'step_id', kind: 'message', T: UUID },
    { no: 2, name: 'data', kind: 'message', T: Step_Data },
    { no: 3, name: 'status', kind: 'message', T: Step_Status },
    { no: 4, name: 'rwx', kind: 'message', T: RWX },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Step {
    return new Step().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Step {
    return new Step().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Step {
    return new Step().fromJsonString(jsonString, options);
  }

  static equals(
    a: Step | PlainMessage<Step> | undefined,
    b: Step | PlainMessage<Step> | undefined
  ): boolean {
    return proto3.util.equals(Step, a, b);
  }
}

/**
 * @generated from message marketplace.product.v1.Step.Data
 */
export class Step_Data extends Message<Step_Data> {
  /**
   * @generated from field: string title = 1;
   */
  title = '';

  /**
   * @generated from field: string description = 2;
   */
  description = '';

  /**
   * @generated from field: marketplace.product.v1.Step.Estimate estimate = 3;
   */
  estimate?: Step_Estimate;

  /**
   * @generated from field: int32 position = 4;
   */
  position = 0;

  /**
   * @generated from field: profiler.tags.v1.Tags tags = 5;
   */
  tags?: Tags;

  constructor(data?: PartialMessage<Step_Data>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.product.v1.Step.Data';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'title', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'description', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 3, name: 'estimate', kind: 'message', T: Step_Estimate },
    { no: 4, name: 'position', kind: 'scalar', T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: 'tags', kind: 'message', T: Tags },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Step_Data {
    return new Step_Data().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Step_Data {
    return new Step_Data().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Step_Data {
    return new Step_Data().fromJsonString(jsonString, options);
  }

  static equals(
    a: Step_Data | PlainMessage<Step_Data> | undefined,
    b: Step_Data | PlainMessage<Step_Data> | undefined
  ): boolean {
    return proto3.util.equals(Step_Data, a, b);
  }
}

/**
 * @generated from message marketplace.product.v1.Step.Estimate
 */
export class Step_Estimate extends Message<Step_Estimate> {
  /**
   * @generated from oneof marketplace.product.v1.Step.Estimate.price
   */
  price:
    | {
        /**
         * @generated from field: marketplace.product.v1.Step.Estimate.Fix fix = 1;
         */
        value: Step_Estimate_Fix;
        case: 'fix';
      }
    | {
        /**
         * @generated from field: marketplace.product.v1.Step.Estimate.Rate rate = 2;
         */
        value: Step_Estimate_Rate;
        case: 'rate';
      }
    | { case: undefined; value?: undefined } = { case: undefined };

  /**
   * @generated from field: marketplace.product.v1.Step.Estimate.Complexity complexity = 3;
   */
  complexity = Step_Estimate_Complexity.UNSPECIFIED;

  constructor(data?: PartialMessage<Step_Estimate>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.product.v1.Step.Estimate';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'fix', kind: 'message', T: Step_Estimate_Fix, oneof: 'price' },
    { no: 2, name: 'rate', kind: 'message', T: Step_Estimate_Rate, oneof: 'price' },
    { no: 3, name: 'complexity', kind: 'enum', T: proto3.getEnumType(Step_Estimate_Complexity) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Step_Estimate {
    return new Step_Estimate().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Step_Estimate {
    return new Step_Estimate().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Step_Estimate {
    return new Step_Estimate().fromJsonString(jsonString, options);
  }

  static equals(
    a: Step_Estimate | PlainMessage<Step_Estimate> | undefined,
    b: Step_Estimate | PlainMessage<Step_Estimate> | undefined
  ): boolean {
    return proto3.util.equals(Step_Estimate, a, b);
  }
}

/**
 * @generated from enum marketplace.product.v1.Step.Estimate.Complexity
 */
export enum Step_Estimate_Complexity {
  /**
   * @generated from enum value: COMPLEXITY_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: COMPLEXITY_LOW = 1;
   */
  LOW = 1,

  /**
   * @generated from enum value: COMPLEXITY_MEDIUM = 2;
   */
  MEDIUM = 2,

  /**
   * @generated from enum value: COMPLEXITY_HIGH = 3;
   */
  HIGH = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(Step_Estimate_Complexity)
proto3.util.setEnumType(
  Step_Estimate_Complexity,
  'marketplace.product.v1.Step.Estimate.Complexity',
  [
    { no: 0, name: 'COMPLEXITY_UNSPECIFIED' },
    { no: 1, name: 'COMPLEXITY_LOW' },
    { no: 2, name: 'COMPLEXITY_MEDIUM' },
    { no: 3, name: 'COMPLEXITY_HIGH' },
  ]
);

/**
 * @generated from message marketplace.product.v1.Step.Estimate.Fix
 */
export class Step_Estimate_Fix extends Message<Step_Estimate_Fix> {
  /**
   * @generated from field: double price = 1;
   */
  price = 0;

  /**
   * @generated from field: marketplace.product.v1.Step.Estimate.Fix.Fibonacci fibonacci = 2;
   */
  fibonacci = Step_Estimate_Fix_Fibonacci.FIBONACCI_UNSPECIFIED;

  constructor(data?: PartialMessage<Step_Estimate_Fix>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.product.v1.Step.Estimate.Fix';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'price', kind: 'scalar', T: 1 /* ScalarType.DOUBLE */ },
    { no: 2, name: 'fibonacci', kind: 'enum', T: proto3.getEnumType(Step_Estimate_Fix_Fibonacci) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Step_Estimate_Fix {
    return new Step_Estimate_Fix().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Step_Estimate_Fix {
    return new Step_Estimate_Fix().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Step_Estimate_Fix {
    return new Step_Estimate_Fix().fromJsonString(jsonString, options);
  }

  static equals(
    a: Step_Estimate_Fix | PlainMessage<Step_Estimate_Fix> | undefined,
    b: Step_Estimate_Fix | PlainMessage<Step_Estimate_Fix> | undefined
  ): boolean {
    return proto3.util.equals(Step_Estimate_Fix, a, b);
  }
}

/**
 * @generated from enum marketplace.product.v1.Step.Estimate.Fix.Fibonacci
 */
export enum Step_Estimate_Fix_Fibonacci {
  /**
   * @generated from enum value: FIBONACCI_UNSPECIFIED = 0;
   */
  FIBONACCI_UNSPECIFIED = 0,

  /**
   * @generated from enum value: FIBONACCI_1 = 1;
   */
  FIBONACCI_1 = 1,

  /**
   * @generated from enum value: FIBONACCI_2 = 2;
   */
  FIBONACCI_2 = 2,

  /**
   * @generated from enum value: FIBONACCI_3 = 3;
   */
  FIBONACCI_3 = 3,

  /**
   * @generated from enum value: FIBONACCI_5 = 5;
   */
  FIBONACCI_5 = 5,

  /**
   * @generated from enum value: FIBONACCI_8 = 8;
   */
  FIBONACCI_8 = 8,

  /**
   * @generated from enum value: FIBONACCI_13 = 13;
   */
  FIBONACCI_13 = 13,

  /**
   * @generated from enum value: FIBONACCI_21 = 21;
   */
  FIBONACCI_21 = 21,

  /**
   * @generated from enum value: FIBONACCI_34 = 34;
   */
  FIBONACCI_34 = 34,

  /**
   * @generated from enum value: FIBONACCI_55 = 55;
   */
  FIBONACCI_55 = 55,

  /**
   * @generated from enum value: FIBONACCI_89 = 89;
   */
  FIBONACCI_89 = 89,
}
// Retrieve enum metadata with: proto3.getEnumType(Step_Estimate_Fix_Fibonacci)
proto3.util.setEnumType(
  Step_Estimate_Fix_Fibonacci,
  'marketplace.product.v1.Step.Estimate.Fix.Fibonacci',
  [
    { no: 0, name: 'FIBONACCI_UNSPECIFIED' },
    { no: 1, name: 'FIBONACCI_1' },
    { no: 2, name: 'FIBONACCI_2' },
    { no: 3, name: 'FIBONACCI_3' },
    { no: 5, name: 'FIBONACCI_5' },
    { no: 8, name: 'FIBONACCI_8' },
    { no: 13, name: 'FIBONACCI_13' },
    { no: 21, name: 'FIBONACCI_21' },
    { no: 34, name: 'FIBONACCI_34' },
    { no: 55, name: 'FIBONACCI_55' },
    { no: 89, name: 'FIBONACCI_89' },
  ]
);

/**
 * @generated from message marketplace.product.v1.Step.Estimate.Rate
 */
export class Step_Estimate_Rate extends Message<Step_Estimate_Rate> {
  /**
   * It can be sum of hourly rates of suppliers by default
   *
   * @generated from field: double rate = 1;
   */
  rate = 0;

  /**
   * @generated from field: int32 hours = 2;
   */
  hours = 0;

  constructor(data?: PartialMessage<Step_Estimate_Rate>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.product.v1.Step.Estimate.Rate';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'rate', kind: 'scalar', T: 1 /* ScalarType.DOUBLE */ },
    { no: 2, name: 'hours', kind: 'scalar', T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Step_Estimate_Rate {
    return new Step_Estimate_Rate().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Step_Estimate_Rate {
    return new Step_Estimate_Rate().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): Step_Estimate_Rate {
    return new Step_Estimate_Rate().fromJsonString(jsonString, options);
  }

  static equals(
    a: Step_Estimate_Rate | PlainMessage<Step_Estimate_Rate> | undefined,
    b: Step_Estimate_Rate | PlainMessage<Step_Estimate_Rate> | undefined
  ): boolean {
    return proto3.util.equals(Step_Estimate_Rate, a, b);
  }
}

/**
 * @generated from message marketplace.product.v1.Step.Status
 */
export class Step_Status extends Message<Step_Status> {
  /**
   * @generated from field: google.protobuf.Timestamp started = 3;
   */
  started?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp taken = 4;
   */
  taken?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp confirmed = 5;
   */
  confirmed?: Timestamp;

  constructor(data?: PartialMessage<Step_Status>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.product.v1.Step.Status';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 3, name: 'started', kind: 'message', T: Timestamp },
    { no: 4, name: 'taken', kind: 'message', T: Timestamp },
    { no: 5, name: 'confirmed', kind: 'message', T: Timestamp },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Step_Status {
    return new Step_Status().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Step_Status {
    return new Step_Status().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Step_Status {
    return new Step_Status().fromJsonString(jsonString, options);
  }

  static equals(
    a: Step_Status | PlainMessage<Step_Status> | undefined,
    b: Step_Status | PlainMessage<Step_Status> | undefined
  ): boolean {
    return proto3.util.equals(Step_Status, a, b);
  }
}

/**
 * @generated from message marketplace.product.v1.AddStepRequest
 */
export class AddStepRequest extends Message<AddStepRequest> {
  /**
   * @generated from field: grpc.type.v1.UUID product_id = 1;
   */
  productId?: UUID;

  /**
   * @generated from field: marketplace.product.v1.Step.Data data = 2;
   */
  data?: Step_Data;

  constructor(data?: PartialMessage<AddStepRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.product.v1.AddStepRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'product_id', kind: 'message', T: UUID },
    { no: 2, name: 'data', kind: 'message', T: Step_Data },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AddStepRequest {
    return new AddStepRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AddStepRequest {
    return new AddStepRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AddStepRequest {
    return new AddStepRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: AddStepRequest | PlainMessage<AddStepRequest> | undefined,
    b: AddStepRequest | PlainMessage<AddStepRequest> | undefined
  ): boolean {
    return proto3.util.equals(AddStepRequest, a, b);
  }
}

/**
 * @generated from message marketplace.product.v1.AddStepResponse
 */
export class AddStepResponse extends Message<AddStepResponse> {
  /**
   * @generated from field: grpc.type.v1.UUID step_id = 1;
   */
  stepId?: UUID;

  constructor(data?: PartialMessage<AddStepResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.product.v1.AddStepResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'step_id', kind: 'message', T: UUID },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AddStepResponse {
    return new AddStepResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AddStepResponse {
    return new AddStepResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AddStepResponse {
    return new AddStepResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: AddStepResponse | PlainMessage<AddStepResponse> | undefined,
    b: AddStepResponse | PlainMessage<AddStepResponse> | undefined
  ): boolean {
    return proto3.util.equals(AddStepResponse, a, b);
  }
}

/**
 * @generated from message marketplace.product.v1.RemoveStepRequest
 */
export class RemoveStepRequest extends Message<RemoveStepRequest> {
  /**
   * @generated from field: grpc.type.v1.UUID step_id = 1;
   */
  stepId?: UUID;

  constructor(data?: PartialMessage<RemoveStepRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.product.v1.RemoveStepRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'step_id', kind: 'message', T: UUID },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RemoveStepRequest {
    return new RemoveStepRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RemoveStepRequest {
    return new RemoveStepRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RemoveStepRequest {
    return new RemoveStepRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: RemoveStepRequest | PlainMessage<RemoveStepRequest> | undefined,
    b: RemoveStepRequest | PlainMessage<RemoveStepRequest> | undefined
  ): boolean {
    return proto3.util.equals(RemoveStepRequest, a, b);
  }
}

/**
 * @generated from message marketplace.product.v1.RemoveStepResponse
 */
export class RemoveStepResponse extends Message<RemoveStepResponse> {
  constructor(data?: PartialMessage<RemoveStepResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.product.v1.RemoveStepResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => []);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RemoveStepResponse {
    return new RemoveStepResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RemoveStepResponse {
    return new RemoveStepResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): RemoveStepResponse {
    return new RemoveStepResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: RemoveStepResponse | PlainMessage<RemoveStepResponse> | undefined,
    b: RemoveStepResponse | PlainMessage<RemoveStepResponse> | undefined
  ): boolean {
    return proto3.util.equals(RemoveStepResponse, a, b);
  }
}

/**
 * @generated from message marketplace.product.v1.GetStepRequest
 */
export class GetStepRequest extends Message<GetStepRequest> {
  /**
   * @generated from field: grpc.type.v1.UUID step_id = 1;
   */
  stepId?: UUID;

  /**
   * @generated from field: iam.auth.v1.RWX rwx = 2;
   */
  rwx?: RWX;

  constructor(data?: PartialMessage<GetStepRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.product.v1.GetStepRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'step_id', kind: 'message', T: UUID },
    { no: 2, name: 'rwx', kind: 'message', T: RWX },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetStepRequest {
    return new GetStepRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetStepRequest {
    return new GetStepRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetStepRequest {
    return new GetStepRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: GetStepRequest | PlainMessage<GetStepRequest> | undefined,
    b: GetStepRequest | PlainMessage<GetStepRequest> | undefined
  ): boolean {
    return proto3.util.equals(GetStepRequest, a, b);
  }
}

/**
 * @generated from message marketplace.product.v1.GetStepResponse
 */
export class GetStepResponse extends Message<GetStepResponse> {
  /**
   * @generated from field: marketplace.product.v1.Step step = 1;
   */
  step?: Step;

  constructor(data?: PartialMessage<GetStepResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.product.v1.GetStepResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'step', kind: 'message', T: Step },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetStepResponse {
    return new GetStepResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetStepResponse {
    return new GetStepResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetStepResponse {
    return new GetStepResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: GetStepResponse | PlainMessage<GetStepResponse> | undefined,
    b: GetStepResponse | PlainMessage<GetStepResponse> | undefined
  ): boolean {
    return proto3.util.equals(GetStepResponse, a, b);
  }
}

/**
 * @generated from message marketplace.product.v1.UpdateStepRequest
 */
export class UpdateStepRequest extends Message<UpdateStepRequest> {
  /**
   * @generated from field: marketplace.product.v1.Step step = 1;
   */
  step?: Step;

  constructor(data?: PartialMessage<UpdateStepRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.product.v1.UpdateStepRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'step', kind: 'message', T: Step },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateStepRequest {
    return new UpdateStepRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateStepRequest {
    return new UpdateStepRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateStepRequest {
    return new UpdateStepRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: UpdateStepRequest | PlainMessage<UpdateStepRequest> | undefined,
    b: UpdateStepRequest | PlainMessage<UpdateStepRequest> | undefined
  ): boolean {
    return proto3.util.equals(UpdateStepRequest, a, b);
  }
}

/**
 * @generated from message marketplace.product.v1.UpdateStepResponse
 */
export class UpdateStepResponse extends Message<UpdateStepResponse> {
  constructor(data?: PartialMessage<UpdateStepResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.product.v1.UpdateStepResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => []);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateStepResponse {
    return new UpdateStepResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateStepResponse {
    return new UpdateStepResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): UpdateStepResponse {
    return new UpdateStepResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: UpdateStepResponse | PlainMessage<UpdateStepResponse> | undefined,
    b: UpdateStepResponse | PlainMessage<UpdateStepResponse> | undefined
  ): boolean {
    return proto3.util.equals(UpdateStepResponse, a, b);
  }
}

/**
 * @generated from message marketplace.product.v1.UpdateStepsPositionsRequest
 */
export class UpdateStepsPositionsRequest extends Message<UpdateStepsPositionsRequest> {
  /**
   * @generated from field: map<int32, grpc.type.v1.UUID> positions = 1;
   */
  positions: { [key: number]: UUID } = {};

  constructor(data?: PartialMessage<UpdateStepsPositionsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.product.v1.UpdateStepsPositionsRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    {
      no: 1,
      name: 'positions',
      kind: 'map',
      K: 5 /* ScalarType.INT32 */,
      V: { kind: 'message', T: UUID },
    },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): UpdateStepsPositionsRequest {
    return new UpdateStepsPositionsRequest().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): UpdateStepsPositionsRequest {
    return new UpdateStepsPositionsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): UpdateStepsPositionsRequest {
    return new UpdateStepsPositionsRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: UpdateStepsPositionsRequest | PlainMessage<UpdateStepsPositionsRequest> | undefined,
    b: UpdateStepsPositionsRequest | PlainMessage<UpdateStepsPositionsRequest> | undefined
  ): boolean {
    return proto3.util.equals(UpdateStepsPositionsRequest, a, b);
  }
}

/**
 * @generated from message marketplace.product.v1.UpdateStepsPositionsResponse
 */
export class UpdateStepsPositionsResponse extends Message<UpdateStepsPositionsResponse> {
  constructor(data?: PartialMessage<UpdateStepsPositionsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.product.v1.UpdateStepsPositionsResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => []);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): UpdateStepsPositionsResponse {
    return new UpdateStepsPositionsResponse().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): UpdateStepsPositionsResponse {
    return new UpdateStepsPositionsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): UpdateStepsPositionsResponse {
    return new UpdateStepsPositionsResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: UpdateStepsPositionsResponse | PlainMessage<UpdateStepsPositionsResponse> | undefined,
    b: UpdateStepsPositionsResponse | PlainMessage<UpdateStepsPositionsResponse> | undefined
  ): boolean {
    return proto3.util.equals(UpdateStepsPositionsResponse, a, b);
  }
}
