// @generated by protoc-gen-es v1.9.0 with parameter "target=ts"
// @generated from file marketplace/demand/v1/evaluation.proto (package marketplace.demand.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type {
  BinaryReadOptions,
  FieldList,
  JsonReadOptions,
  JsonValue,
  PartialMessage,
  PlainMessage,
} from '@bufbuild/protobuf';
import { Message, proto3, Timestamp } from '@bufbuild/protobuf';
import { UUID } from '../../../grpc/type/v1/uuid_pb.js';
import { Profile } from '../../../profiler/user/v1/profile_pb.js';

/**
 * @generated from message marketplace.demand.v1.SendEvaluationFeedbackRequest
 */
export class SendEvaluationFeedbackRequest extends Message<SendEvaluationFeedbackRequest> {
  /**
   * @generated from field: grpc.type.v1.UUID evaluation_id = 1;
   */
  evaluationId?: UUID;

  constructor(data?: PartialMessage<SendEvaluationFeedbackRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.demand.v1.SendEvaluationFeedbackRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'evaluation_id', kind: 'message', T: UUID },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): SendEvaluationFeedbackRequest {
    return new SendEvaluationFeedbackRequest().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): SendEvaluationFeedbackRequest {
    return new SendEvaluationFeedbackRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): SendEvaluationFeedbackRequest {
    return new SendEvaluationFeedbackRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: SendEvaluationFeedbackRequest | PlainMessage<SendEvaluationFeedbackRequest> | undefined,
    b: SendEvaluationFeedbackRequest | PlainMessage<SendEvaluationFeedbackRequest> | undefined
  ): boolean {
    return proto3.util.equals(SendEvaluationFeedbackRequest, a, b);
  }
}

/**
 * @generated from message marketplace.demand.v1.SendEvaluationFeedbackResponse
 */
export class SendEvaluationFeedbackResponse extends Message<SendEvaluationFeedbackResponse> {
  constructor(data?: PartialMessage<SendEvaluationFeedbackResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.demand.v1.SendEvaluationFeedbackResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => []);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): SendEvaluationFeedbackResponse {
    return new SendEvaluationFeedbackResponse().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): SendEvaluationFeedbackResponse {
    return new SendEvaluationFeedbackResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): SendEvaluationFeedbackResponse {
    return new SendEvaluationFeedbackResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: SendEvaluationFeedbackResponse | PlainMessage<SendEvaluationFeedbackResponse> | undefined,
    b: SendEvaluationFeedbackResponse | PlainMessage<SendEvaluationFeedbackResponse> | undefined
  ): boolean {
    return proto3.util.equals(SendEvaluationFeedbackResponse, a, b);
  }
}

/**
 * @generated from message marketplace.demand.v1.GetEvaluationFeedbackDraftRequest
 */
export class GetEvaluationFeedbackDraftRequest extends Message<GetEvaluationFeedbackDraftRequest> {
  /**
   * @generated from field: grpc.type.v1.UUID evaluation_id = 1;
   */
  evaluationId?: UUID;

  constructor(data?: PartialMessage<GetEvaluationFeedbackDraftRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.demand.v1.GetEvaluationFeedbackDraftRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'evaluation_id', kind: 'message', T: UUID },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): GetEvaluationFeedbackDraftRequest {
    return new GetEvaluationFeedbackDraftRequest().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): GetEvaluationFeedbackDraftRequest {
    return new GetEvaluationFeedbackDraftRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): GetEvaluationFeedbackDraftRequest {
    return new GetEvaluationFeedbackDraftRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a:
      | GetEvaluationFeedbackDraftRequest
      | PlainMessage<GetEvaluationFeedbackDraftRequest>
      | undefined,
    b:
      | GetEvaluationFeedbackDraftRequest
      | PlainMessage<GetEvaluationFeedbackDraftRequest>
      | undefined
  ): boolean {
    return proto3.util.equals(GetEvaluationFeedbackDraftRequest, a, b);
  }
}

/**
 * @generated from message marketplace.demand.v1.GetEvaluationFeedbackDraftResponse
 */
export class GetEvaluationFeedbackDraftResponse extends Message<GetEvaluationFeedbackDraftResponse> {
  /**
   * @generated from field: string feedback = 1;
   */
  feedback = '';

  constructor(data?: PartialMessage<GetEvaluationFeedbackDraftResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.demand.v1.GetEvaluationFeedbackDraftResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'feedback', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): GetEvaluationFeedbackDraftResponse {
    return new GetEvaluationFeedbackDraftResponse().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): GetEvaluationFeedbackDraftResponse {
    return new GetEvaluationFeedbackDraftResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): GetEvaluationFeedbackDraftResponse {
    return new GetEvaluationFeedbackDraftResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a:
      | GetEvaluationFeedbackDraftResponse
      | PlainMessage<GetEvaluationFeedbackDraftResponse>
      | undefined,
    b:
      | GetEvaluationFeedbackDraftResponse
      | PlainMessage<GetEvaluationFeedbackDraftResponse>
      | undefined
  ): boolean {
    return proto3.util.equals(GetEvaluationFeedbackDraftResponse, a, b);
  }
}

/**
 * @generated from message marketplace.demand.v1.SaveEvaluationFeedbackDraftRequest
 */
export class SaveEvaluationFeedbackDraftRequest extends Message<SaveEvaluationFeedbackDraftRequest> {
  /**
   * @generated from field: grpc.type.v1.UUID evaluation_id = 1;
   */
  evaluationId?: UUID;

  /**
   * @generated from field: string feedback = 2;
   */
  feedback = '';

  constructor(data?: PartialMessage<SaveEvaluationFeedbackDraftRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.demand.v1.SaveEvaluationFeedbackDraftRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'evaluation_id', kind: 'message', T: UUID },
    { no: 2, name: 'feedback', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): SaveEvaluationFeedbackDraftRequest {
    return new SaveEvaluationFeedbackDraftRequest().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): SaveEvaluationFeedbackDraftRequest {
    return new SaveEvaluationFeedbackDraftRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): SaveEvaluationFeedbackDraftRequest {
    return new SaveEvaluationFeedbackDraftRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a:
      | SaveEvaluationFeedbackDraftRequest
      | PlainMessage<SaveEvaluationFeedbackDraftRequest>
      | undefined,
    b:
      | SaveEvaluationFeedbackDraftRequest
      | PlainMessage<SaveEvaluationFeedbackDraftRequest>
      | undefined
  ): boolean {
    return proto3.util.equals(SaveEvaluationFeedbackDraftRequest, a, b);
  }
}

/**
 * @generated from message marketplace.demand.v1.SaveEvaluationFeedbackDraftResponse
 */
export class SaveEvaluationFeedbackDraftResponse extends Message<SaveEvaluationFeedbackDraftResponse> {
  constructor(data?: PartialMessage<SaveEvaluationFeedbackDraftResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.demand.v1.SaveEvaluationFeedbackDraftResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => []);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): SaveEvaluationFeedbackDraftResponse {
    return new SaveEvaluationFeedbackDraftResponse().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): SaveEvaluationFeedbackDraftResponse {
    return new SaveEvaluationFeedbackDraftResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): SaveEvaluationFeedbackDraftResponse {
    return new SaveEvaluationFeedbackDraftResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a:
      | SaveEvaluationFeedbackDraftResponse
      | PlainMessage<SaveEvaluationFeedbackDraftResponse>
      | undefined,
    b:
      | SaveEvaluationFeedbackDraftResponse
      | PlainMessage<SaveEvaluationFeedbackDraftResponse>
      | undefined
  ): boolean {
    return proto3.util.equals(SaveEvaluationFeedbackDraftResponse, a, b);
  }
}

/**
 * @generated from message marketplace.demand.v1.Evaluation
 */
export class Evaluation extends Message<Evaluation> {
  /**
   * @generated from field: grpc.type.v1.UUID evaluation_id = 1;
   */
  evaluationId?: UUID;

  /**
   * @generated from field: grpc.type.v1.UUID interview_id = 2;
   */
  interviewId?: UUID;

  /**
   * @generated from field: marketplace.demand.v1.Evaluation.Status status = 3;
   */
  status?: Evaluation_Status;

  /**
   * @generated from field: optional marketplace.demand.v1.Evaluation.FeedBack feedback = 4;
   */
  feedback?: Evaluation_FeedBack;

  /**
   * @generated from field: optional string requirements = 5;
   */
  requirements?: string;

  /**
   * @generated from oneof marketplace.demand.v1.Evaluation.participants
   */
  participants:
    | {
        /**
         * @generated from field: marketplace.demand.v1.Evaluation.Profiles profiles = 6;
         */
        value: Evaluation_Profiles;
        case: 'profiles';
      }
    | {
        /**
         * @generated from field: marketplace.demand.v1.Evaluation.Participant interviewer = 7;
         */
        value: Evaluation_Participant;
        case: 'interviewer';
      }
    | {
        /**
         * @generated from field: marketplace.demand.v1.Evaluation.Participant candidate = 8;
         */
        value: Evaluation_Participant;
        case: 'candidate';
      }
    | { case: undefined; value?: undefined } = { case: undefined };

  /**
   * @generated from field: optional bool has_available_slots = 9;
   */
  hasAvailableSlots?: boolean;

  constructor(data?: PartialMessage<Evaluation>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.demand.v1.Evaluation';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'evaluation_id', kind: 'message', T: UUID },
    { no: 2, name: 'interview_id', kind: 'message', T: UUID },
    { no: 3, name: 'status', kind: 'message', T: Evaluation_Status },
    { no: 4, name: 'feedback', kind: 'message', T: Evaluation_FeedBack, opt: true },
    { no: 5, name: 'requirements', kind: 'scalar', T: 9 /* ScalarType.STRING */, opt: true },
    { no: 6, name: 'profiles', kind: 'message', T: Evaluation_Profiles, oneof: 'participants' },
    {
      no: 7,
      name: 'interviewer',
      kind: 'message',
      T: Evaluation_Participant,
      oneof: 'participants',
    },
    { no: 8, name: 'candidate', kind: 'message', T: Evaluation_Participant, oneof: 'participants' },
    { no: 9, name: 'has_available_slots', kind: 'scalar', T: 8 /* ScalarType.BOOL */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Evaluation {
    return new Evaluation().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Evaluation {
    return new Evaluation().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Evaluation {
    return new Evaluation().fromJsonString(jsonString, options);
  }

  static equals(
    a: Evaluation | PlainMessage<Evaluation> | undefined,
    b: Evaluation | PlainMessage<Evaluation> | undefined
  ): boolean {
    return proto3.util.equals(Evaluation, a, b);
  }
}

/**
 * @generated from message marketplace.demand.v1.Evaluation.Participant
 */
export class Evaluation_Participant extends Message<Evaluation_Participant> {
  constructor(data?: PartialMessage<Evaluation_Participant>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.demand.v1.Evaluation.Participant';
  static readonly fields: FieldList = proto3.util.newFieldList(() => []);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): Evaluation_Participant {
    return new Evaluation_Participant().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): Evaluation_Participant {
    return new Evaluation_Participant().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): Evaluation_Participant {
    return new Evaluation_Participant().fromJsonString(jsonString, options);
  }

  static equals(
    a: Evaluation_Participant | PlainMessage<Evaluation_Participant> | undefined,
    b: Evaluation_Participant | PlainMessage<Evaluation_Participant> | undefined
  ): boolean {
    return proto3.util.equals(Evaluation_Participant, a, b);
  }
}

/**
 * @generated from message marketplace.demand.v1.Evaluation.Profiles
 */
export class Evaluation_Profiles extends Message<Evaluation_Profiles> {
  /**
   * @generated from field: profiler.user.v1.Profile candidate = 1;
   */
  candidate?: Profile;

  /**
   * @generated from field: profiler.user.v1.Profile interviewer = 2;
   */
  interviewer?: Profile;

  constructor(data?: PartialMessage<Evaluation_Profiles>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.demand.v1.Evaluation.Profiles';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'candidate', kind: 'message', T: Profile },
    { no: 2, name: 'interviewer', kind: 'message', T: Profile },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Evaluation_Profiles {
    return new Evaluation_Profiles().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Evaluation_Profiles {
    return new Evaluation_Profiles().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): Evaluation_Profiles {
    return new Evaluation_Profiles().fromJsonString(jsonString, options);
  }

  static equals(
    a: Evaluation_Profiles | PlainMessage<Evaluation_Profiles> | undefined,
    b: Evaluation_Profiles | PlainMessage<Evaluation_Profiles> | undefined
  ): boolean {
    return proto3.util.equals(Evaluation_Profiles, a, b);
  }
}

/**
 * @generated from message marketplace.demand.v1.Evaluation.FeedBack
 */
export class Evaluation_FeedBack extends Message<Evaluation_FeedBack> {
  /**
   * @generated from field: string text = 1;
   */
  text = '';

  /**
   * @generated from field: marketplace.demand.v1.Evaluation.FeedBack.Status status = 2;
   */
  status?: Evaluation_FeedBack_Status;

  constructor(data?: PartialMessage<Evaluation_FeedBack>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.demand.v1.Evaluation.FeedBack';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'text', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'status', kind: 'message', T: Evaluation_FeedBack_Status },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Evaluation_FeedBack {
    return new Evaluation_FeedBack().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Evaluation_FeedBack {
    return new Evaluation_FeedBack().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): Evaluation_FeedBack {
    return new Evaluation_FeedBack().fromJsonString(jsonString, options);
  }

  static equals(
    a: Evaluation_FeedBack | PlainMessage<Evaluation_FeedBack> | undefined,
    b: Evaluation_FeedBack | PlainMessage<Evaluation_FeedBack> | undefined
  ): boolean {
    return proto3.util.equals(Evaluation_FeedBack, a, b);
  }
}

/**
 * @generated from message marketplace.demand.v1.Evaluation.FeedBack.Status
 */
export class Evaluation_FeedBack_Status extends Message<Evaluation_FeedBack_Status> {
  /**
   * @generated from field: google.protobuf.Timestamp sent = 1;
   */
  sent?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp accepted = 2;
   */
  accepted?: Timestamp;

  constructor(data?: PartialMessage<Evaluation_FeedBack_Status>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.demand.v1.Evaluation.FeedBack.Status';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'sent', kind: 'message', T: Timestamp },
    { no: 2, name: 'accepted', kind: 'message', T: Timestamp },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): Evaluation_FeedBack_Status {
    return new Evaluation_FeedBack_Status().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): Evaluation_FeedBack_Status {
    return new Evaluation_FeedBack_Status().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): Evaluation_FeedBack_Status {
    return new Evaluation_FeedBack_Status().fromJsonString(jsonString, options);
  }

  static equals(
    a: Evaluation_FeedBack_Status | PlainMessage<Evaluation_FeedBack_Status> | undefined,
    b: Evaluation_FeedBack_Status | PlainMessage<Evaluation_FeedBack_Status> | undefined
  ): boolean {
    return proto3.util.equals(Evaluation_FeedBack_Status, a, b);
  }
}

/**
 * @generated from message marketplace.demand.v1.Evaluation.Status
 */
export class Evaluation_Status extends Message<Evaluation_Status> {
  /**
   * @generated from field: google.protobuf.Timestamp created_at = 1;
   */
  createdAt?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp scheduled_at = 2;
   */
  scheduledAt?: Timestamp;

  constructor(data?: PartialMessage<Evaluation_Status>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.demand.v1.Evaluation.Status';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'created_at', kind: 'message', T: Timestamp },
    { no: 2, name: 'scheduled_at', kind: 'message', T: Timestamp },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Evaluation_Status {
    return new Evaluation_Status().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Evaluation_Status {
    return new Evaluation_Status().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Evaluation_Status {
    return new Evaluation_Status().fromJsonString(jsonString, options);
  }

  static equals(
    a: Evaluation_Status | PlainMessage<Evaluation_Status> | undefined,
    b: Evaluation_Status | PlainMessage<Evaluation_Status> | undefined
  ): boolean {
    return proto3.util.equals(Evaluation_Status, a, b);
  }
}

/**
 * @generated from message marketplace.demand.v1.Evaluations
 */
export class Evaluations extends Message<Evaluations> {
  /**
   * @generated from field: repeated marketplace.demand.v1.Evaluation evaluations = 1;
   */
  evaluations: Evaluation[] = [];

  constructor(data?: PartialMessage<Evaluations>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.demand.v1.Evaluations';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'evaluations', kind: 'message', T: Evaluation, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Evaluations {
    return new Evaluations().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Evaluations {
    return new Evaluations().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Evaluations {
    return new Evaluations().fromJsonString(jsonString, options);
  }

  static equals(
    a: Evaluations | PlainMessage<Evaluations> | undefined,
    b: Evaluations | PlainMessage<Evaluations> | undefined
  ): boolean {
    return proto3.util.equals(Evaluations, a, b);
  }
}

/**
 * @generated from message marketplace.demand.v1.GetEvaluationRequest
 */
export class GetEvaluationRequest extends Message<GetEvaluationRequest> {
  /**
   * @generated from field: grpc.type.v1.UUID evaluation_id = 1;
   */
  evaluationId?: UUID;

  /**
   * @generated from field: optional string requirements = 2;
   */
  requirements?: string;

  constructor(data?: PartialMessage<GetEvaluationRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.demand.v1.GetEvaluationRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'evaluation_id', kind: 'message', T: UUID },
    { no: 2, name: 'requirements', kind: 'scalar', T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetEvaluationRequest {
    return new GetEvaluationRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetEvaluationRequest {
    return new GetEvaluationRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): GetEvaluationRequest {
    return new GetEvaluationRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: GetEvaluationRequest | PlainMessage<GetEvaluationRequest> | undefined,
    b: GetEvaluationRequest | PlainMessage<GetEvaluationRequest> | undefined
  ): boolean {
    return proto3.util.equals(GetEvaluationRequest, a, b);
  }
}

/**
 * @generated from message marketplace.demand.v1.GetEvaluationResponse
 */
export class GetEvaluationResponse extends Message<GetEvaluationResponse> {
  /**
   * @generated from field: marketplace.demand.v1.Evaluation evaluation = 1;
   */
  evaluation?: Evaluation;

  constructor(data?: PartialMessage<GetEvaluationResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.demand.v1.GetEvaluationResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'evaluation', kind: 'message', T: Evaluation },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): GetEvaluationResponse {
    return new GetEvaluationResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetEvaluationResponse {
    return new GetEvaluationResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): GetEvaluationResponse {
    return new GetEvaluationResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: GetEvaluationResponse | PlainMessage<GetEvaluationResponse> | undefined,
    b: GetEvaluationResponse | PlainMessage<GetEvaluationResponse> | undefined
  ): boolean {
    return proto3.util.equals(GetEvaluationResponse, a, b);
  }
}

/**
 * @generated from message marketplace.demand.v1.ListEvaluationsRequest
 */
export class ListEvaluationsRequest extends Message<ListEvaluationsRequest> {
  constructor(data?: PartialMessage<ListEvaluationsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.demand.v1.ListEvaluationsRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => []);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): ListEvaluationsRequest {
    return new ListEvaluationsRequest().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): ListEvaluationsRequest {
    return new ListEvaluationsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): ListEvaluationsRequest {
    return new ListEvaluationsRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: ListEvaluationsRequest | PlainMessage<ListEvaluationsRequest> | undefined,
    b: ListEvaluationsRequest | PlainMessage<ListEvaluationsRequest> | undefined
  ): boolean {
    return proto3.util.equals(ListEvaluationsRequest, a, b);
  }
}

/**
 * @generated from message marketplace.demand.v1.ListEvaluationsResponse
 */
export class ListEvaluationsResponse extends Message<ListEvaluationsResponse> {
  /**
   * @generated from field: marketplace.demand.v1.Evaluations evaluations = 1;
   */
  evaluations?: Evaluations;

  constructor(data?: PartialMessage<ListEvaluationsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.demand.v1.ListEvaluationsResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'evaluations', kind: 'message', T: Evaluations },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): ListEvaluationsResponse {
    return new ListEvaluationsResponse().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): ListEvaluationsResponse {
    return new ListEvaluationsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): ListEvaluationsResponse {
    return new ListEvaluationsResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: ListEvaluationsResponse | PlainMessage<ListEvaluationsResponse> | undefined,
    b: ListEvaluationsResponse | PlainMessage<ListEvaluationsResponse> | undefined
  ): boolean {
    return proto3.util.equals(ListEvaluationsResponse, a, b);
  }
}

/**
 * @generated from message marketplace.demand.v1.EvaluationScheduleSlots
 */
export class EvaluationScheduleSlots extends Message<EvaluationScheduleSlots> {
  /**
   * @generated from field: google.protobuf.Timestamp from = 1;
   */
  from?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp to = 2;
   */
  to?: Timestamp;

  constructor(data?: PartialMessage<EvaluationScheduleSlots>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.demand.v1.EvaluationScheduleSlots';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'from', kind: 'message', T: Timestamp },
    { no: 2, name: 'to', kind: 'message', T: Timestamp },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): EvaluationScheduleSlots {
    return new EvaluationScheduleSlots().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): EvaluationScheduleSlots {
    return new EvaluationScheduleSlots().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): EvaluationScheduleSlots {
    return new EvaluationScheduleSlots().fromJsonString(jsonString, options);
  }

  static equals(
    a: EvaluationScheduleSlots | PlainMessage<EvaluationScheduleSlots> | undefined,
    b: EvaluationScheduleSlots | PlainMessage<EvaluationScheduleSlots> | undefined
  ): boolean {
    return proto3.util.equals(EvaluationScheduleSlots, a, b);
  }
}

/**
 * @generated from message marketplace.demand.v1.AddEvaluationScheduleSlotsRequest
 */
export class AddEvaluationScheduleSlotsRequest extends Message<AddEvaluationScheduleSlotsRequest> {
  /**
   * @generated from field: marketplace.demand.v1.EvaluationScheduleSlots slots = 1;
   */
  slots?: EvaluationScheduleSlots;

  constructor(data?: PartialMessage<AddEvaluationScheduleSlotsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.demand.v1.AddEvaluationScheduleSlotsRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'slots', kind: 'message', T: EvaluationScheduleSlots },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): AddEvaluationScheduleSlotsRequest {
    return new AddEvaluationScheduleSlotsRequest().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): AddEvaluationScheduleSlotsRequest {
    return new AddEvaluationScheduleSlotsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): AddEvaluationScheduleSlotsRequest {
    return new AddEvaluationScheduleSlotsRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a:
      | AddEvaluationScheduleSlotsRequest
      | PlainMessage<AddEvaluationScheduleSlotsRequest>
      | undefined,
    b:
      | AddEvaluationScheduleSlotsRequest
      | PlainMessage<AddEvaluationScheduleSlotsRequest>
      | undefined
  ): boolean {
    return proto3.util.equals(AddEvaluationScheduleSlotsRequest, a, b);
  }
}

/**
 * @generated from message marketplace.demand.v1.AddEvaluationScheduleSlotsResponse
 */
export class AddEvaluationScheduleSlotsResponse extends Message<AddEvaluationScheduleSlotsResponse> {
  constructor(data?: PartialMessage<AddEvaluationScheduleSlotsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.demand.v1.AddEvaluationScheduleSlotsResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => []);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): AddEvaluationScheduleSlotsResponse {
    return new AddEvaluationScheduleSlotsResponse().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): AddEvaluationScheduleSlotsResponse {
    return new AddEvaluationScheduleSlotsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): AddEvaluationScheduleSlotsResponse {
    return new AddEvaluationScheduleSlotsResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a:
      | AddEvaluationScheduleSlotsResponse
      | PlainMessage<AddEvaluationScheduleSlotsResponse>
      | undefined,
    b:
      | AddEvaluationScheduleSlotsResponse
      | PlainMessage<AddEvaluationScheduleSlotsResponse>
      | undefined
  ): boolean {
    return proto3.util.equals(AddEvaluationScheduleSlotsResponse, a, b);
  }
}

/**
 * @generated from message marketplace.demand.v1.DeleteEvaluationScheduleSlotRequest
 */
export class DeleteEvaluationScheduleSlotRequest extends Message<DeleteEvaluationScheduleSlotRequest> {
  /**
   * @generated from field: google.protobuf.Timestamp from = 1;
   */
  from?: Timestamp;

  constructor(data?: PartialMessage<DeleteEvaluationScheduleSlotRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.demand.v1.DeleteEvaluationScheduleSlotRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'from', kind: 'message', T: Timestamp },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): DeleteEvaluationScheduleSlotRequest {
    return new DeleteEvaluationScheduleSlotRequest().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): DeleteEvaluationScheduleSlotRequest {
    return new DeleteEvaluationScheduleSlotRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): DeleteEvaluationScheduleSlotRequest {
    return new DeleteEvaluationScheduleSlotRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a:
      | DeleteEvaluationScheduleSlotRequest
      | PlainMessage<DeleteEvaluationScheduleSlotRequest>
      | undefined,
    b:
      | DeleteEvaluationScheduleSlotRequest
      | PlainMessage<DeleteEvaluationScheduleSlotRequest>
      | undefined
  ): boolean {
    return proto3.util.equals(DeleteEvaluationScheduleSlotRequest, a, b);
  }
}

/**
 * @generated from message marketplace.demand.v1.DeleteEvaluationScheduleSlotResponse
 */
export class DeleteEvaluationScheduleSlotResponse extends Message<DeleteEvaluationScheduleSlotResponse> {
  constructor(data?: PartialMessage<DeleteEvaluationScheduleSlotResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.demand.v1.DeleteEvaluationScheduleSlotResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => []);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): DeleteEvaluationScheduleSlotResponse {
    return new DeleteEvaluationScheduleSlotResponse().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): DeleteEvaluationScheduleSlotResponse {
    return new DeleteEvaluationScheduleSlotResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): DeleteEvaluationScheduleSlotResponse {
    return new DeleteEvaluationScheduleSlotResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a:
      | DeleteEvaluationScheduleSlotResponse
      | PlainMessage<DeleteEvaluationScheduleSlotResponse>
      | undefined,
    b:
      | DeleteEvaluationScheduleSlotResponse
      | PlainMessage<DeleteEvaluationScheduleSlotResponse>
      | undefined
  ): boolean {
    return proto3.util.equals(DeleteEvaluationScheduleSlotResponse, a, b);
  }
}

/**
 * @generated from message marketplace.demand.v1.GetInterviewerAvailabilityRequest
 */
export class GetInterviewerAvailabilityRequest extends Message<GetInterviewerAvailabilityRequest> {
  /**
   * @generated from oneof marketplace.demand.v1.GetInterviewerAvailabilityRequest.by
   */
  by:
    | {
        /**
         * @generated from field: grpc.type.v1.UUID evaluation_id = 1;
         */
        value: UUID;
        case: 'evaluationId';
      }
    | {
        /**
         * @generated from field: grpc.type.v1.UUID interviewer_id = 2;
         */
        value: UUID;
        case: 'interviewerId';
      }
    | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<GetInterviewerAvailabilityRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.demand.v1.GetInterviewerAvailabilityRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'evaluation_id', kind: 'message', T: UUID, oneof: 'by' },
    { no: 2, name: 'interviewer_id', kind: 'message', T: UUID, oneof: 'by' },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): GetInterviewerAvailabilityRequest {
    return new GetInterviewerAvailabilityRequest().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): GetInterviewerAvailabilityRequest {
    return new GetInterviewerAvailabilityRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): GetInterviewerAvailabilityRequest {
    return new GetInterviewerAvailabilityRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a:
      | GetInterviewerAvailabilityRequest
      | PlainMessage<GetInterviewerAvailabilityRequest>
      | undefined,
    b:
      | GetInterviewerAvailabilityRequest
      | PlainMessage<GetInterviewerAvailabilityRequest>
      | undefined
  ): boolean {
    return proto3.util.equals(GetInterviewerAvailabilityRequest, a, b);
  }
}

/**
 * @generated from message marketplace.demand.v1.GetInterviewerAvailabilityResponse
 */
export class GetInterviewerAvailabilityResponse extends Message<GetInterviewerAvailabilityResponse> {
  /**
   * @generated from field: repeated marketplace.demand.v1.EvaluationScheduleSlots slots = 1;
   */
  slots: EvaluationScheduleSlots[] = [];

  /**
   * @generated from field: repeated google.protobuf.Timestamp occupied_slots = 2;
   */
  occupiedSlots: Timestamp[] = [];

  constructor(data?: PartialMessage<GetInterviewerAvailabilityResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.demand.v1.GetInterviewerAvailabilityResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'slots', kind: 'message', T: EvaluationScheduleSlots, repeated: true },
    { no: 2, name: 'occupied_slots', kind: 'message', T: Timestamp, repeated: true },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): GetInterviewerAvailabilityResponse {
    return new GetInterviewerAvailabilityResponse().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): GetInterviewerAvailabilityResponse {
    return new GetInterviewerAvailabilityResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): GetInterviewerAvailabilityResponse {
    return new GetInterviewerAvailabilityResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a:
      | GetInterviewerAvailabilityResponse
      | PlainMessage<GetInterviewerAvailabilityResponse>
      | undefined,
    b:
      | GetInterviewerAvailabilityResponse
      | PlainMessage<GetInterviewerAvailabilityResponse>
      | undefined
  ): boolean {
    return proto3.util.equals(GetInterviewerAvailabilityResponse, a, b);
  }
}

/**
 * @generated from message marketplace.demand.v1.OccupySlotRequest
 */
export class OccupySlotRequest extends Message<OccupySlotRequest> {
  /**
   * @generated from field: grpc.type.v1.UUID evaluation_id = 1;
   */
  evaluationId?: UUID;

  /**
   * @generated from field: google.protobuf.Timestamp from = 2;
   */
  from?: Timestamp;

  constructor(data?: PartialMessage<OccupySlotRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.demand.v1.OccupySlotRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'evaluation_id', kind: 'message', T: UUID },
    { no: 2, name: 'from', kind: 'message', T: Timestamp },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): OccupySlotRequest {
    return new OccupySlotRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): OccupySlotRequest {
    return new OccupySlotRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): OccupySlotRequest {
    return new OccupySlotRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: OccupySlotRequest | PlainMessage<OccupySlotRequest> | undefined,
    b: OccupySlotRequest | PlainMessage<OccupySlotRequest> | undefined
  ): boolean {
    return proto3.util.equals(OccupySlotRequest, a, b);
  }
}

/**
 * @generated from message marketplace.demand.v1.OccupySlotResponse
 */
export class OccupySlotResponse extends Message<OccupySlotResponse> {
  constructor(data?: PartialMessage<OccupySlotResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.demand.v1.OccupySlotResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => []);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): OccupySlotResponse {
    return new OccupySlotResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): OccupySlotResponse {
    return new OccupySlotResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): OccupySlotResponse {
    return new OccupySlotResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: OccupySlotResponse | PlainMessage<OccupySlotResponse> | undefined,
    b: OccupySlotResponse | PlainMessage<OccupySlotResponse> | undefined
  ): boolean {
    return proto3.util.equals(OccupySlotResponse, a, b);
  }
}

/**
 * @generated from message marketplace.demand.v1.RescheduleEvaluationRequest
 */
export class RescheduleEvaluationRequest extends Message<RescheduleEvaluationRequest> {
  /**
   * @generated from field: grpc.type.v1.UUID evaluation_id = 1;
   */
  evaluationId?: UUID;

  constructor(data?: PartialMessage<RescheduleEvaluationRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.demand.v1.RescheduleEvaluationRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'evaluation_id', kind: 'message', T: UUID },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): RescheduleEvaluationRequest {
    return new RescheduleEvaluationRequest().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): RescheduleEvaluationRequest {
    return new RescheduleEvaluationRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): RescheduleEvaluationRequest {
    return new RescheduleEvaluationRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: RescheduleEvaluationRequest | PlainMessage<RescheduleEvaluationRequest> | undefined,
    b: RescheduleEvaluationRequest | PlainMessage<RescheduleEvaluationRequest> | undefined
  ): boolean {
    return proto3.util.equals(RescheduleEvaluationRequest, a, b);
  }
}

/**
 * @generated from message marketplace.demand.v1.RescheduleEvaluationResponse
 */
export class RescheduleEvaluationResponse extends Message<RescheduleEvaluationResponse> {
  constructor(data?: PartialMessage<RescheduleEvaluationResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.demand.v1.RescheduleEvaluationResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => []);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): RescheduleEvaluationResponse {
    return new RescheduleEvaluationResponse().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): RescheduleEvaluationResponse {
    return new RescheduleEvaluationResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): RescheduleEvaluationResponse {
    return new RescheduleEvaluationResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: RescheduleEvaluationResponse | PlainMessage<RescheduleEvaluationResponse> | undefined,
    b: RescheduleEvaluationResponse | PlainMessage<RescheduleEvaluationResponse> | undefined
  ): boolean {
    return proto3.util.equals(RescheduleEvaluationResponse, a, b);
  }
}

/**
 * @generated from message marketplace.demand.v1.CompleteEvaluationRequest
 */
export class CompleteEvaluationRequest extends Message<CompleteEvaluationRequest> {
  /**
   * @generated from field: grpc.type.v1.UUID evaluation_id = 1;
   */
  evaluationId?: UUID;

  constructor(data?: PartialMessage<CompleteEvaluationRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.demand.v1.CompleteEvaluationRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'evaluation_id', kind: 'message', T: UUID },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): CompleteEvaluationRequest {
    return new CompleteEvaluationRequest().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): CompleteEvaluationRequest {
    return new CompleteEvaluationRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): CompleteEvaluationRequest {
    return new CompleteEvaluationRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: CompleteEvaluationRequest | PlainMessage<CompleteEvaluationRequest> | undefined,
    b: CompleteEvaluationRequest | PlainMessage<CompleteEvaluationRequest> | undefined
  ): boolean {
    return proto3.util.equals(CompleteEvaluationRequest, a, b);
  }
}

/**
 * @generated from message marketplace.demand.v1.CompleteEvaluationResponse
 */
export class CompleteEvaluationResponse extends Message<CompleteEvaluationResponse> {
  constructor(data?: PartialMessage<CompleteEvaluationResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.demand.v1.CompleteEvaluationResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => []);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): CompleteEvaluationResponse {
    return new CompleteEvaluationResponse().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): CompleteEvaluationResponse {
    return new CompleteEvaluationResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): CompleteEvaluationResponse {
    return new CompleteEvaluationResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: CompleteEvaluationResponse | PlainMessage<CompleteEvaluationResponse> | undefined,
    b: CompleteEvaluationResponse | PlainMessage<CompleteEvaluationResponse> | undefined
  ): boolean {
    return proto3.util.equals(CompleteEvaluationResponse, a, b);
  }
}

/**
 * @generated from message marketplace.demand.v1.AcceptEvaluationFeedbackRequest
 */
export class AcceptEvaluationFeedbackRequest extends Message<AcceptEvaluationFeedbackRequest> {
  /**
   * @generated from field: grpc.type.v1.UUID evaluation_id = 1;
   */
  evaluationId?: UUID;

  /**
   * @generated from field: grpc.type.v1.UUID interview_id = 2;
   */
  interviewId?: UUID;

  constructor(data?: PartialMessage<AcceptEvaluationFeedbackRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.demand.v1.AcceptEvaluationFeedbackRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'evaluation_id', kind: 'message', T: UUID },
    { no: 2, name: 'interview_id', kind: 'message', T: UUID },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): AcceptEvaluationFeedbackRequest {
    return new AcceptEvaluationFeedbackRequest().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): AcceptEvaluationFeedbackRequest {
    return new AcceptEvaluationFeedbackRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): AcceptEvaluationFeedbackRequest {
    return new AcceptEvaluationFeedbackRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: AcceptEvaluationFeedbackRequest | PlainMessage<AcceptEvaluationFeedbackRequest> | undefined,
    b: AcceptEvaluationFeedbackRequest | PlainMessage<AcceptEvaluationFeedbackRequest> | undefined
  ): boolean {
    return proto3.util.equals(AcceptEvaluationFeedbackRequest, a, b);
  }
}

/**
 * @generated from message marketplace.demand.v1.AcceptEvaluationFeedbackResponse
 */
export class AcceptEvaluationFeedbackResponse extends Message<AcceptEvaluationFeedbackResponse> {
  constructor(data?: PartialMessage<AcceptEvaluationFeedbackResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.demand.v1.AcceptEvaluationFeedbackResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => []);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): AcceptEvaluationFeedbackResponse {
    return new AcceptEvaluationFeedbackResponse().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): AcceptEvaluationFeedbackResponse {
    return new AcceptEvaluationFeedbackResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): AcceptEvaluationFeedbackResponse {
    return new AcceptEvaluationFeedbackResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a:
      | AcceptEvaluationFeedbackResponse
      | PlainMessage<AcceptEvaluationFeedbackResponse>
      | undefined,
    b: AcceptEvaluationFeedbackResponse | PlainMessage<AcceptEvaluationFeedbackResponse> | undefined
  ): boolean {
    return proto3.util.equals(AcceptEvaluationFeedbackResponse, a, b);
  }
}
