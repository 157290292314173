// @generated by protoc-gen-connect-es v1.4.0 with parameter "target=ts"
// @generated from file api/profiler/v1/org.proto (package api.profiler.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import {
  CreateOrgRequest,
  CreateOrgResponse,
  GetOrgResponse,
  ListOrgsResponse,
  SearchOrgsRequest,
  SearchOrgsResponse,
  SetOrgStatusRequest,
  SetOrgStatusResponse,
  UpdateOrgInfoRequest,
  UpdateOrgInfoResponse,
} from '../../../profiler/biz/v1/org_pb.js';
import { Empty, MethodKind } from '@bufbuild/protobuf';
import { GetOrgRequest, ListOrgsRequest } from './org_pb.js';

/**
 * @generated from service api.profiler.v1.OrgAPI
 */
export const OrgAPI = {
  typeName: 'api.profiler.v1.OrgAPI',
  methods: {
    /**
     * @generated from rpc api.profiler.v1.OrgAPI.CreateOrg
     */
    createOrg: {
      name: 'CreateOrg',
      I: CreateOrgRequest,
      O: CreateOrgResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc api.profiler.v1.OrgAPI.GetOrg
     */
    getOrg: {
      name: 'GetOrg',
      I: GetOrgRequest,
      O: GetOrgResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc api.profiler.v1.OrgAPI.SetOrgStatus
     */
    setOrgStatus: {
      name: 'SetOrgStatus',
      I: SetOrgStatusRequest,
      O: SetOrgStatusResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc api.profiler.v1.OrgAPI.ListOrgs
     */
    listOrgs: {
      name: 'ListOrgs',
      I: ListOrgsRequest,
      O: ListOrgsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc api.profiler.v1.OrgAPI.SearchOrgs
     */
    searchOrgs: {
      name: 'SearchOrgs',
      I: SearchOrgsRequest,
      O: SearchOrgsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc api.profiler.v1.OrgAPI.UpdateOrgInfo
     */
    updateOrgInfo: {
      name: 'UpdateOrgInfo',
      I: UpdateOrgInfoRequest,
      O: UpdateOrgInfoResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc api.profiler.v1.OrgAPI.ListUserOrgs
     */
    listUserOrgs: {
      name: 'ListUserOrgs',
      I: Empty,
      O: ListOrgsResponse,
      kind: MethodKind.Unary,
    },
  },
} as const;
