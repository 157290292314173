import { Step_Estimate_Fix, Step_Estimate_Rate } from '@proto/marketplace/product/v1/break_down_pb';
import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Chip } from '@mui/material';
import { complexityColors, sortStepsByPosition } from '@pages/Product/DnD';
import { useParams } from 'react-router-dom';
import { useGetProduct } from '@services/queries/ProductsQueries';
import { Page } from '@components/Page/Page';
import Container from '@mui/material/Container';
import BackArrow from '@components/BackArrow/BackArrow';

const BreakdownInfo = () => {
  const { productId } = useParams();
  const { data: product, error: productError } = useGetProduct(productId as string);
  const steps = sortStepsByPosition(product?.product?.breakDown?.steps || []);

  return (
    <Page
      description={`Steps ${product?.product?.title}`}
      title={`Steps ${product?.product?.title}`}
    >
      <Container>
        <BackArrow link={`/product/${productId}`} />

        <div className="flex flex-col space-y-4">
          {steps.map((step, index) => (
            <div
              key={step.stepId?.value || Math.random()}
              className="p-4 border border-[rgba(255,255,255,0.23)] rounded-lg shadow-md flex flex-col gap-2"
            >
              <Box className="flex items-center justify-between">
                <h2 className="text-lg font-bold ">{step.data?.title || 'No Title'}</h2>
              </Box>

              <Box className="flex gap-4">
                <Box className="flex items-center gap-2">
                  <Typography>Roles </Typography>
                  <Box>
                    {step.data?.tags?.tags.map((tag) => (
                      <Chip label={tag.value} />
                    ))}
                  </Box>
                </Box>
                <Box className="flex items-center gap-2">
                  <Typography>Complexity </Typography>
                  <Box
                    className={`p-[12px] ${
                      complexityColors[
                        step.data?.estimate?.complexity as keyof typeof complexityColors
                      ]
                    } text-[#000] rounded-full `}
                  />
                </Box>
                <Box className="flex items-center gap-2">
                  <Typography className="capitalize">
                    {step?.data?.estimate?.price.case} price
                  </Typography>
                  <Chip
                    label={`$${
                      (step.data?.estimate?.price.value as Step_Estimate_Rate).rate ||
                      (step.data?.estimate?.price.value as Step_Estimate_Fix).price
                    }`}
                  />
                </Box>
                <Box className="flex items-center gap-2">
                  <Typography className="capitalize">
                    Estimates{' '}
                    {(step.data?.estimate?.price.value as Step_Estimate_Rate).hours
                      ? 'Hours'
                      : 'Fibonacci'}
                  </Typography>
                  <Chip
                    label={`${
                      (step.data?.estimate?.price.value as Step_Estimate_Rate).hours ||
                      (step.data?.estimate?.price.value as Step_Estimate_Fix).fibonacci
                    }`}
                  />
                </Box>
              </Box>
              {/* <div className="mt-2 text-sm text-gray-500"> */}
              {/*    Status: {step.status ? JSON.stringify(step.status) : 'No Status'} */}
              {/* </div> */}

              <p className="text-sm text-gray-400">{step.data?.description || 'No Description'}</p>
            </div>
          ))}
        </div>
      </Container>
    </Page>
  );
};

export default BreakdownInfo;
