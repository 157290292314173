import { create } from 'zustand/react';
import { Tag } from '@proto/profiler/tags/v1/tags_pb';

interface MarketplaceState {
  searchText: string | undefined;
  searchTags: Tag[] | undefined;
  setSearchText: (searchText: string | undefined) => void;
  setSearchTags: (searchTag: Tag) => void;
}

export const useMarketplace = create<MarketplaceState>((set, get) => ({
  searchText: '',
  searchTags: undefined,
  setSearchText: (searchText) => set({ searchText }),
  setSearchTags: (searchTag) =>
    set((state) => {
      const tags = state.searchTags || [];
      const index = tags.findIndex((tag) => tag.tagId === searchTag.tagId);
      if (index === -1) {
        tags.push(searchTag);
      } else {
        tags.splice(index, 1);
      }
      return { searchTags: tags };
    }),
}));
