import Joi from 'joi';
import {
  Step,
  Step_Estimate_Fix,
  Step_Estimate_Rate,
} from '@proto/marketplace/product/v1/break_down_pb';

export enum FieldNames {
  TITLE = 'title',
  DESCRIPTION = 'description',
  PRICE_CASE = 'priceCase',
  PRICE = 'price',
  HOURS = 'hours',
  FIBONACCI = 'fibonacci',
  COMPLEXITY = 'complexity',
  ROLES = 'roles',
}

export const getDefaultValues = (step: Step | undefined) => ({
  [FieldNames.TITLE]: step?.data?.title || '',
  [FieldNames.DESCRIPTION]: step?.data?.description || '',
  [FieldNames.PRICE_CASE]: step?.data?.estimate?.price?.case || 'rate' || 'fix',
  [FieldNames.PRICE]:
    (step?.data?.estimate?.price?.value as Step_Estimate_Fix)?.price ||
    (step?.data?.estimate?.price?.value as Step_Estimate_Rate)?.rate ||
    0,
  [FieldNames.FIBONACCI]: (step?.data?.estimate?.price?.value as Step_Estimate_Fix)?.fibonacci || 1,
  [FieldNames.HOURS]: (step?.data?.estimate?.price?.value as Step_Estimate_Rate)?.hours || 0,
  [FieldNames.COMPLEXITY]: step?.data?.estimate?.complexity || 1,
  [FieldNames.ROLES]: step?.data?.tags?.tags || [],
});

export const createStepSchema = {
  [FieldNames.TITLE]: Joi.string().required().min(2).max(128).messages({
    'string.empty': 'enter the name of the product',
    'string.min': 'product name must be at least 2 characters long',
    'string.max': 'product name must be less than or equal to 128 characters long',
  }),
  [FieldNames.DESCRIPTION]: Joi.string().required().min(2).max(512).messages({
    'string.empty': 'enter a description of the product',
    'string.min': 'the description of the product must be at least 2 characters long',
    'string.max':
      'the description of the product must be less than or equal to 512 characters long',
  }),
  [FieldNames.PRICE_CASE]: Joi.string().required().valid('fix', 'rate').messages({
    'string.empty': 'select the type of price',
    'any.only': 'select the type of price',
  }),
  [FieldNames.PRICE]: Joi.number().required().min(1).messages({
    'number.empty': 'enter the price',
    'number.min': 'the price must be greater than 0',
  }),
  [FieldNames.HOURS]: Joi.number().allow(),
  [FieldNames.FIBONACCI]: Joi.number().allow(),
  [FieldNames.COMPLEXITY]: Joi.number().required().valid(1, 2, 3).messages({
    'number.empty': 'select the complexity',
    'any.only': 'select the complexity',
  }),
  [FieldNames.ROLES]: Joi.array().allow(),
};
