'use client';

import React from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import Typography from '@mui/material/Typography';

// Заголовки
const Heading1 = ({ children, ...props }: any) => (
  <Typography variant="h4" gutterBottom {...props}>
    {children}
  </Typography>
);

const Heading2 = ({ children, ...props }: any) => (
  <Typography variant="h5" gutterBottom {...props}>
    {children}
  </Typography>
);

const Heading3 = ({ children, ...props }: any) => (
  <Typography variant="h6" gutterBottom {...props}>
    {children}
  </Typography>
);

// Параграф
const Paragraph = ({ children, ...props }: any) => (
  <Typography variant="body1" paragraph {...props}>
    {children}
  </Typography>
);

const Image = (props: any) => <img className="rounded-[24px]" alt="" {...props} />;

const UnorderedList = (props: any) => (
  <ul className=" mb-8 list-inside list-disc text-grey-2 dark:text-neutral-5" {...props} />
);

const OrderedList = (props: any) => (
  <ol
    className="mb-4 list-inside list-decimal font-semibold text-black-1  dark:text-grey-5"
    {...props}
  />
);

const MDRenderer = ({ content }: { content: string }) => {
  return (
    <div className="mx-auto  grid">
      <ReactMarkdown
        rehypePlugins={[rehypeRaw]}
        components={{
          h1: Heading1,
          h2: Heading2,
          h3: Heading3,
          p: Paragraph,
          img: Image,
          ul: UnorderedList,
          ol: OrderedList,
        }}
      >
        {content}
      </ReactMarkdown>
    </div>
  );
};

export default MDRenderer;
