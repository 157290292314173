import Box from '@mui/material/Box';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const BackArrow = ({ link }: { link?: string }) => {
  const navigate = useNavigate();

  return (
    <Box py={4}>
      <ArrowBackIcon
        cursor="pointer"
        onClick={() => {
          if (link) {
            navigate(link);
          } else {
            navigate(-1);
          }
        }}
      />
    </Box>
  );
};
export default BackArrow;
