import { useNavigate, useParams } from 'react-router-dom';
import * as React from 'react';
import { Box, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EventIcon from '@mui/icons-material/Event';
import SelectDemandStatus from '@pages/Marketplace/Dashboards/SelectDemandStatus';
import UpdateInterviewRequirements from '@pages/Marketplace/Dashboards/UpdateInterviewRequirements';
import UpdateDemandTraits from '@pages/Marketplace/Dashboards/UpdateDemandTraits';
import Container from '@mui/material/Container';
import Tags from '@components/Tags/Tags';
import { useAllocateSlots, useGetDemand } from '@services/queries/MarketplaceQueries';
import ManageEvaluationsList from '@pages/Marketplace/Dashboards/ManageEvaluationsList';
import Button from '@mui/material/Button';

const ManageInterviewPage = () => {
  const { demandId, dashboardId } = useParams();
  const navigate = useNavigate();
  const { data: demand } = useGetDemand({ demandId: demandId || '', type: 'interview' });
  const interview = demand?.entity?.value;
  const { mutate } = useAllocateSlots();

  return (
    <Box px={3}>
      <Container>
        <Box display="flex" flexDirection="column" py={2} gap={2}>
          <ArrowBackIcon cursor="pointer" onClick={() => navigate(-1)} />
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="subtitle2">{demand?.entity.case?.toUpperCase()}</Typography>
            <Box display="flex" gap={1} alignItems="center">
              <EventIcon />
              {
                // TODO fix date
                /* <Typography variant="subtitle2"> */
              }
              {/*    {interview?.createdAt && format(new Date(interview?.createdAt), 'dd/MM/yyyy')} */}
              {/* </Typography> */}
            </Box>
          </Box>
          <Box display="flex">
            <SelectDemandStatus status={demand?.status?.status?.toString() || ''} />
          </Box>
          <Box display="flex" justifyContent="space-between">
            <Box display="flex" flexDirection="column" gap={1}>
              {demand && <UpdateDemandTraits demand={demand} />}
              {interview?.tags?.tags && <Tags tags={interview.tags.tags} />}
              {demand && <UpdateInterviewRequirements demand={demand} />}
            </Box>
          </Box>
          <Typography variant="subtitle2">Manage evaluations</Typography>
          <ManageEvaluationsList />
          <Button onClick={() => mutate({ interviewId: demandId as string })}>Allocate</Button>
        </Box>
      </Container>
    </Box>
  );
};

export default ManageInterviewPage;
