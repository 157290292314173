import { createSelectOptions } from '@utils/createSelectOptionsFromEnum';
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import { useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import { useSetDemandstatus } from '@services/queries/MarketplaceQueries';
import { Demand_Status_Status } from '@proto/marketplace/demand/v1/demand_pb';

type SelectDemandStatusProps = {
  status: string;
};

const SelectDemandStatus = (props: SelectDemandStatusProps) => {
  const { status } = props;
  const { demandId } = useParams();
  const [statusValue, setStatus] = useState(status);
  const { mutate } = useSetDemandstatus();

  const demandStatusOptions = createSelectOptions(Demand_Status_Status, [
    Demand_Status_Status.UNSPECIFIED,
    Demand_Status_Status.IN_PROGRESS,
    Demand_Status_Status.DONE,
    status !== Demand_Status_Status.DRAFT.toString()
      ? Demand_Status_Status.DRAFT
      : Demand_Status_Status.UNSPECIFIED,
  ]);

  const handleChange = (event: SelectChangeEvent) => {
    setStatus(event.target.value as string);
    mutate({
      demandId: demandId || '',
      status: Number(event.target.value as string),
    });
  };

  useEffect(() => {
    setStatus(status);
  }, [status]);

  return (
    <div>
      {statusValue && (
        <Box width="200px">
          <FormControl fullWidth>
            <InputLabel id="select-demand-label">Status</InputLabel>
            <Select
              labelId="select-demand-label"
              id="select-demand"
              value={statusValue}
              label="Status"
              onChange={handleChange}
            >
              {demandStatusOptions.map((item) => {
                return (
                  <MenuItem key={item.value} value={item.value}>
                    <Typography textTransform="capitalize">{item.label}</Typography>
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Box>
      )}
    </div>
  );
};

export default SelectDemandStatus;
