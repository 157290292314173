// @generated by protoc-gen-es v1.9.0 with parameter "target=ts"
// @generated from file marketplace/demand/v1/contract.proto (package marketplace.demand.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type {
  BinaryReadOptions,
  FieldList,
  JsonReadOptions,
  JsonValue,
  PartialMessage,
  PlainMessage,
} from '@bufbuild/protobuf';
import { Message, proto3, Timestamp } from '@bufbuild/protobuf';
import { UUID } from '../../../grpc/type/v1/uuid_pb.js';
import { Tags } from '../../../profiler/tags/v1/tags_pb.js';

/**
 * @generated from message marketplace.demand.v1.Contract
 */
export class Contract extends Message<Contract> {
  /**
   * @generated from field: grpc.type.v1.UUID contract_id = 1;
   */
  contractId?: UUID;

  /**
   * @generated from field: string description = 2;
   */
  description = '';

  /**
   * @generated from field: marketplace.demand.v1.Contract.Traits traits = 3;
   */
  traits?: Contract_Traits;

  /**
   * @generated from field: profiler.tags.v1.Tags tags = 4;
   */
  tags?: Tags;

  /**
   * @generated from field: google.protobuf.Timestamp created_at = 5;
   */
  createdAt?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp updated_at = 6;
   */
  updatedAt?: Timestamp;

  /**
   * @generated from field: optional grpc.type.v1.UUID interview_id = 7;
   */
  interviewId?: UUID;

  /**
   * @generated from field: grpc.type.v1.UUID dashboard_id = 8;
   */
  dashboardId?: UUID;

  /**
   * @generated from field: marketplace.demand.v1.Contract.CheckOut check_out = 9;
   */
  checkOut?: Contract_CheckOut;

  constructor(data?: PartialMessage<Contract>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.demand.v1.Contract';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'contract_id', kind: 'message', T: UUID },
    { no: 2, name: 'description', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 3, name: 'traits', kind: 'message', T: Contract_Traits },
    { no: 4, name: 'tags', kind: 'message', T: Tags },
    { no: 5, name: 'created_at', kind: 'message', T: Timestamp },
    { no: 6, name: 'updated_at', kind: 'message', T: Timestamp },
    { no: 7, name: 'interview_id', kind: 'message', T: UUID, opt: true },
    { no: 8, name: 'dashboard_id', kind: 'message', T: UUID },
    { no: 9, name: 'check_out', kind: 'message', T: Contract_CheckOut },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Contract {
    return new Contract().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Contract {
    return new Contract().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Contract {
    return new Contract().fromJsonString(jsonString, options);
  }

  static equals(
    a: Contract | PlainMessage<Contract> | undefined,
    b: Contract | PlainMessage<Contract> | undefined
  ): boolean {
    return proto3.util.equals(Contract, a, b);
  }
}

/**
 * @generated from enum marketplace.demand.v1.Contract.Period
 */
export enum Contract_Period {
  /**
   * @generated from enum value: PERIOD_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: PERIOD_DAILY = 1;
   */
  DAILY = 1,

  /**
   * @generated from enum value: PERIOD_WEEKLY = 2;
   */
  WEEKLY = 2,

  /**
   * @generated from enum value: PERIOD_MONTHLY = 3;
   */
  MONTHLY = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(Contract_Period)
proto3.util.setEnumType(Contract_Period, 'marketplace.demand.v1.Contract.Period', [
  { no: 0, name: 'PERIOD_UNSPECIFIED' },
  { no: 1, name: 'PERIOD_DAILY' },
  { no: 2, name: 'PERIOD_WEEKLY' },
  { no: 3, name: 'PERIOD_MONTHLY' },
]);

/**
 * @generated from message marketplace.demand.v1.Contract.Traits
 */
export class Contract_Traits extends Message<Contract_Traits> {
  /**
   * @generated from field: int32 rate = 1;
   */
  rate = 0;

  /**
   * @generated from field: int32 capacity = 2;
   */
  capacity = 0;

  /**
   * @generated from field: int32 experience = 3;
   */
  experience = 0;

  /**
   * @generated from field: bool squaders_only = 4;
   */
  squadersOnly = false;

  constructor(data?: PartialMessage<Contract_Traits>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.demand.v1.Contract.Traits';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'rate', kind: 'scalar', T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: 'capacity', kind: 'scalar', T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: 'experience', kind: 'scalar', T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: 'squaders_only', kind: 'scalar', T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Contract_Traits {
    return new Contract_Traits().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Contract_Traits {
    return new Contract_Traits().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Contract_Traits {
    return new Contract_Traits().fromJsonString(jsonString, options);
  }

  static equals(
    a: Contract_Traits | PlainMessage<Contract_Traits> | undefined,
    b: Contract_Traits | PlainMessage<Contract_Traits> | undefined
  ): boolean {
    return proto3.util.equals(Contract_Traits, a, b);
  }
}

/**
 * @generated from message marketplace.demand.v1.Contract.CheckOut
 */
export class Contract_CheckOut extends Message<Contract_CheckOut> {
  /**
   * @generated from field: marketplace.demand.v1.Contract.Period period = 1;
   */
  period = Contract_Period.UNSPECIFIED;

  /**
   * @generated from field: google.protobuf.Timestamp start = 2;
   */
  start?: Timestamp;

  constructor(data?: PartialMessage<Contract_CheckOut>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.demand.v1.Contract.CheckOut';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'period', kind: 'enum', T: proto3.getEnumType(Contract_Period) },
    { no: 2, name: 'start', kind: 'message', T: Timestamp },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Contract_CheckOut {
    return new Contract_CheckOut().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Contract_CheckOut {
    return new Contract_CheckOut().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Contract_CheckOut {
    return new Contract_CheckOut().fromJsonString(jsonString, options);
  }

  static equals(
    a: Contract_CheckOut | PlainMessage<Contract_CheckOut> | undefined,
    b: Contract_CheckOut | PlainMessage<Contract_CheckOut> | undefined
  ): boolean {
    return proto3.util.equals(Contract_CheckOut, a, b);
  }
}

/**
 * @generated from message marketplace.demand.v1.CreateContractRequest
 */
export class CreateContractRequest extends Message<CreateContractRequest> {
  /**
   * @generated from oneof marketplace.demand.v1.CreateContractRequest.dashboard
   */
  dashboard:
    | {
        /**
         * @generated from field: grpc.type.v1.UUID id = 1;
         */
        value: UUID;
        case: 'id';
      }
    | {
        /**
         * @generated from field: string nickname = 2;
         */
        value: string;
        case: 'nickname';
      }
    | { case: undefined; value?: undefined } = { case: undefined };

  /**
   * @generated from field: string description = 3;
   */
  description = '';

  /**
   * @generated from field: profiler.tags.v1.Tags tags = 4;
   */
  tags?: Tags;

  /**
   * @generated from field: marketplace.demand.v1.Contract.Traits traits = 5;
   */
  traits?: Contract_Traits;

  /**
   * @generated from field: bool create_interview = 6;
   */
  createInterview = false;

  constructor(data?: PartialMessage<CreateContractRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.demand.v1.CreateContractRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'id', kind: 'message', T: UUID, oneof: 'dashboard' },
    { no: 2, name: 'nickname', kind: 'scalar', T: 9 /* ScalarType.STRING */, oneof: 'dashboard' },
    { no: 3, name: 'description', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 4, name: 'tags', kind: 'message', T: Tags },
    { no: 5, name: 'traits', kind: 'message', T: Contract_Traits },
    { no: 6, name: 'create_interview', kind: 'scalar', T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): CreateContractRequest {
    return new CreateContractRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateContractRequest {
    return new CreateContractRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): CreateContractRequest {
    return new CreateContractRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: CreateContractRequest | PlainMessage<CreateContractRequest> | undefined,
    b: CreateContractRequest | PlainMessage<CreateContractRequest> | undefined
  ): boolean {
    return proto3.util.equals(CreateContractRequest, a, b);
  }
}

/**
 * @generated from message marketplace.demand.v1.CreateContractResponse
 */
export class CreateContractResponse extends Message<CreateContractResponse> {
  /**
   * @generated from field: grpc.type.v1.UUID contract_id = 1;
   */
  contractId?: UUID;

  /**
   * @generated from field: optional grpc.type.v1.UUID interview_id = 2;
   */
  interviewId?: UUID;

  constructor(data?: PartialMessage<CreateContractResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.demand.v1.CreateContractResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'contract_id', kind: 'message', T: UUID },
    { no: 2, name: 'interview_id', kind: 'message', T: UUID, opt: true },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): CreateContractResponse {
    return new CreateContractResponse().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): CreateContractResponse {
    return new CreateContractResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): CreateContractResponse {
    return new CreateContractResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: CreateContractResponse | PlainMessage<CreateContractResponse> | undefined,
    b: CreateContractResponse | PlainMessage<CreateContractResponse> | undefined
  ): boolean {
    return proto3.util.equals(CreateContractResponse, a, b);
  }
}

/**
 * @generated from message marketplace.demand.v1.UpdateContractTraitsRequest
 */
export class UpdateContractTraitsRequest extends Message<UpdateContractTraitsRequest> {
  /**
   * @generated from field: grpc.type.v1.UUID contract_id = 1;
   */
  contractId?: UUID;

  /**
   * @generated from field: marketplace.demand.v1.Contract.Traits traits = 2;
   */
  traits?: Contract_Traits;

  constructor(data?: PartialMessage<UpdateContractTraitsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.demand.v1.UpdateContractTraitsRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'contract_id', kind: 'message', T: UUID },
    { no: 2, name: 'traits', kind: 'message', T: Contract_Traits },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): UpdateContractTraitsRequest {
    return new UpdateContractTraitsRequest().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): UpdateContractTraitsRequest {
    return new UpdateContractTraitsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): UpdateContractTraitsRequest {
    return new UpdateContractTraitsRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: UpdateContractTraitsRequest | PlainMessage<UpdateContractTraitsRequest> | undefined,
    b: UpdateContractTraitsRequest | PlainMessage<UpdateContractTraitsRequest> | undefined
  ): boolean {
    return proto3.util.equals(UpdateContractTraitsRequest, a, b);
  }
}

/**
 * @generated from message marketplace.demand.v1.UpdateContractTraitsResponse
 */
export class UpdateContractTraitsResponse extends Message<UpdateContractTraitsResponse> {
  constructor(data?: PartialMessage<UpdateContractTraitsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.demand.v1.UpdateContractTraitsResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => []);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): UpdateContractTraitsResponse {
    return new UpdateContractTraitsResponse().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): UpdateContractTraitsResponse {
    return new UpdateContractTraitsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): UpdateContractTraitsResponse {
    return new UpdateContractTraitsResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: UpdateContractTraitsResponse | PlainMessage<UpdateContractTraitsResponse> | undefined,
    b: UpdateContractTraitsResponse | PlainMessage<UpdateContractTraitsResponse> | undefined
  ): boolean {
    return proto3.util.equals(UpdateContractTraitsResponse, a, b);
  }
}

/**
 * @generated from message marketplace.demand.v1.UpdateContractDescriptionRequest
 */
export class UpdateContractDescriptionRequest extends Message<UpdateContractDescriptionRequest> {
  /**
   * @generated from field: grpc.type.v1.UUID contract_id = 1;
   */
  contractId?: UUID;

  /**
   * @generated from field: string description = 2;
   */
  description = '';

  constructor(data?: PartialMessage<UpdateContractDescriptionRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.demand.v1.UpdateContractDescriptionRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'contract_id', kind: 'message', T: UUID },
    { no: 2, name: 'description', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): UpdateContractDescriptionRequest {
    return new UpdateContractDescriptionRequest().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): UpdateContractDescriptionRequest {
    return new UpdateContractDescriptionRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): UpdateContractDescriptionRequest {
    return new UpdateContractDescriptionRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a:
      | UpdateContractDescriptionRequest
      | PlainMessage<UpdateContractDescriptionRequest>
      | undefined,
    b: UpdateContractDescriptionRequest | PlainMessage<UpdateContractDescriptionRequest> | undefined
  ): boolean {
    return proto3.util.equals(UpdateContractDescriptionRequest, a, b);
  }
}

/**
 * @generated from message marketplace.demand.v1.UpdateContractDescriptionResponse
 */
export class UpdateContractDescriptionResponse extends Message<UpdateContractDescriptionResponse> {
  constructor(data?: PartialMessage<UpdateContractDescriptionResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.demand.v1.UpdateContractDescriptionResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => []);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): UpdateContractDescriptionResponse {
    return new UpdateContractDescriptionResponse().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): UpdateContractDescriptionResponse {
    return new UpdateContractDescriptionResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): UpdateContractDescriptionResponse {
    return new UpdateContractDescriptionResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a:
      | UpdateContractDescriptionResponse
      | PlainMessage<UpdateContractDescriptionResponse>
      | undefined,
    b:
      | UpdateContractDescriptionResponse
      | PlainMessage<UpdateContractDescriptionResponse>
      | undefined
  ): boolean {
    return proto3.util.equals(UpdateContractDescriptionResponse, a, b);
  }
}

/**
 * @generated from message marketplace.demand.v1.DeleteContractRequest
 */
export class DeleteContractRequest extends Message<DeleteContractRequest> {
  /**
   * @generated from field: grpc.type.v1.UUID contract_id = 1;
   */
  contractId?: UUID;

  constructor(data?: PartialMessage<DeleteContractRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.demand.v1.DeleteContractRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'contract_id', kind: 'message', T: UUID },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): DeleteContractRequest {
    return new DeleteContractRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteContractRequest {
    return new DeleteContractRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): DeleteContractRequest {
    return new DeleteContractRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: DeleteContractRequest | PlainMessage<DeleteContractRequest> | undefined,
    b: DeleteContractRequest | PlainMessage<DeleteContractRequest> | undefined
  ): boolean {
    return proto3.util.equals(DeleteContractRequest, a, b);
  }
}

/**
 * @generated from message marketplace.demand.v1.DeleteContractResponse
 */
export class DeleteContractResponse extends Message<DeleteContractResponse> {
  constructor(data?: PartialMessage<DeleteContractResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.demand.v1.DeleteContractResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => []);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): DeleteContractResponse {
    return new DeleteContractResponse().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): DeleteContractResponse {
    return new DeleteContractResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): DeleteContractResponse {
    return new DeleteContractResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: DeleteContractResponse | PlainMessage<DeleteContractResponse> | undefined,
    b: DeleteContractResponse | PlainMessage<DeleteContractResponse> | undefined
  ): boolean {
    return proto3.util.equals(DeleteContractResponse, a, b);
  }
}
