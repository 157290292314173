// @generated by protoc-gen-es v1.9.0 with parameter "target=ts"
// @generated from file profiler/biz/v1/org.proto (package profiler.biz.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type {
  BinaryReadOptions,
  FieldList,
  JsonReadOptions,
  JsonValue,
  PartialMessage,
  PlainMessage,
} from '@bufbuild/protobuf';
import { Message, proto3, Timestamp } from '@bufbuild/protobuf';
import { UUID } from '../../../grpc/type/v1/uuid_pb.js';
import { Tags } from '../../tags/v1/tags_pb.js';
import { IdsRWX, RWX } from '../../../iam/auth/v1/iam_pb.js';
import { Filter } from '../../../grpc/type/v1/entity_pb.js';

/**
 * @generated from message profiler.biz.v1.Org
 */
export class Org extends Message<Org> {
  /**
   * @generated from field: grpc.type.v1.UUID org_id = 1;
   */
  orgId?: UUID;

  /**
   * @generated from field: google.protobuf.Timestamp created = 2;
   */
  created?: Timestamp;

  /**
   * @generated from field: grpc.type.v1.UUID owner_id = 3;
   */
  ownerId?: UUID;

  /**
   * @generated from field: string name = 4;
   */
  name = '';

  /**
   * @generated from field: bool active = 5;
   */
  active = false;

  /**
   * @generated from field: string about = 6;
   */
  about = '';

  /**
   * @generated from field: string avatar = 7;
   */
  avatar = '';

  /**
   * @generated from field: profiler.tags.v1.Tags tags = 8;
   */
  tags?: Tags;

  /**
   * @generated from field: iam.auth.v1.RWX rwx = 9;
   */
  rwx?: RWX;

  constructor(data?: PartialMessage<Org>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.biz.v1.Org';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'org_id', kind: 'message', T: UUID },
    { no: 2, name: 'created', kind: 'message', T: Timestamp },
    { no: 3, name: 'owner_id', kind: 'message', T: UUID },
    { no: 4, name: 'name', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 5, name: 'active', kind: 'scalar', T: 8 /* ScalarType.BOOL */ },
    { no: 6, name: 'about', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 7, name: 'avatar', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 8, name: 'tags', kind: 'message', T: Tags },
    { no: 9, name: 'rwx', kind: 'message', T: RWX },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Org {
    return new Org().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Org {
    return new Org().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Org {
    return new Org().fromJsonString(jsonString, options);
  }

  static equals(
    a: Org | PlainMessage<Org> | undefined,
    b: Org | PlainMessage<Org> | undefined
  ): boolean {
    return proto3.util.equals(Org, a, b);
  }
}

/**
 * @generated from message profiler.biz.v1.Orgs
 */
export class Orgs extends Message<Orgs> {
  /**
   * @generated from field: repeated profiler.biz.v1.Org orgs = 1;
   */
  orgs: Org[] = [];

  constructor(data?: PartialMessage<Orgs>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.biz.v1.Orgs';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'orgs', kind: 'message', T: Org, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Orgs {
    return new Orgs().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Orgs {
    return new Orgs().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Orgs {
    return new Orgs().fromJsonString(jsonString, options);
  }

  static equals(
    a: Orgs | PlainMessage<Orgs> | undefined,
    b: Orgs | PlainMessage<Orgs> | undefined
  ): boolean {
    return proto3.util.equals(Orgs, a, b);
  }
}

/**
 * @generated from message profiler.biz.v1.CreateOrgRequest
 */
export class CreateOrgRequest extends Message<CreateOrgRequest> {
  /**
   * @generated from field: string name = 1;
   */
  name = '';

  /**
   * @generated from field: string about = 2;
   */
  about = '';

  /**
   * @generated from field: profiler.tags.v1.Tags tags = 3;
   */
  tags?: Tags;

  constructor(data?: PartialMessage<CreateOrgRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.biz.v1.CreateOrgRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'name', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'about', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 3, name: 'tags', kind: 'message', T: Tags },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateOrgRequest {
    return new CreateOrgRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateOrgRequest {
    return new CreateOrgRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateOrgRequest {
    return new CreateOrgRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: CreateOrgRequest | PlainMessage<CreateOrgRequest> | undefined,
    b: CreateOrgRequest | PlainMessage<CreateOrgRequest> | undefined
  ): boolean {
    return proto3.util.equals(CreateOrgRequest, a, b);
  }
}

/**
 * @generated from message profiler.biz.v1.CreateOrgResponse
 */
export class CreateOrgResponse extends Message<CreateOrgResponse> {
  /**
   * @generated from field: profiler.biz.v1.Org org = 1;
   */
  org?: Org;

  constructor(data?: PartialMessage<CreateOrgResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.biz.v1.CreateOrgResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'org', kind: 'message', T: Org },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateOrgResponse {
    return new CreateOrgResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateOrgResponse {
    return new CreateOrgResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateOrgResponse {
    return new CreateOrgResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: CreateOrgResponse | PlainMessage<CreateOrgResponse> | undefined,
    b: CreateOrgResponse | PlainMessage<CreateOrgResponse> | undefined
  ): boolean {
    return proto3.util.equals(CreateOrgResponse, a, b);
  }
}

/**
 * @generated from message profiler.biz.v1.GetOrgRequest
 */
export class GetOrgRequest extends Message<GetOrgRequest> {
  /**
   * @generated from field: grpc.type.v1.Filter filter = 1;
   */
  filter?: Filter;

  /**
   * @generated from field: iam.auth.v1.RWX rwx = 2;
   */
  rwx?: RWX;

  constructor(data?: PartialMessage<GetOrgRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.biz.v1.GetOrgRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'filter', kind: 'message', T: Filter },
    { no: 2, name: 'rwx', kind: 'message', T: RWX },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetOrgRequest {
    return new GetOrgRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetOrgRequest {
    return new GetOrgRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetOrgRequest {
    return new GetOrgRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: GetOrgRequest | PlainMessage<GetOrgRequest> | undefined,
    b: GetOrgRequest | PlainMessage<GetOrgRequest> | undefined
  ): boolean {
    return proto3.util.equals(GetOrgRequest, a, b);
  }
}

/**
 * @generated from message profiler.biz.v1.GetOrgResponse
 */
export class GetOrgResponse extends Message<GetOrgResponse> {
  /**
   * @generated from field: profiler.biz.v1.Org org = 1;
   */
  org?: Org;

  constructor(data?: PartialMessage<GetOrgResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.biz.v1.GetOrgResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'org', kind: 'message', T: Org },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetOrgResponse {
    return new GetOrgResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetOrgResponse {
    return new GetOrgResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetOrgResponse {
    return new GetOrgResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: GetOrgResponse | PlainMessage<GetOrgResponse> | undefined,
    b: GetOrgResponse | PlainMessage<GetOrgResponse> | undefined
  ): boolean {
    return proto3.util.equals(GetOrgResponse, a, b);
  }
}

/**
 * @generated from message profiler.biz.v1.SetOrgStatusRequest
 */
export class SetOrgStatusRequest extends Message<SetOrgStatusRequest> {
  /**
   * @generated from field: grpc.type.v1.UUID org_id = 1;
   */
  orgId?: UUID;

  /**
   * @generated from field: bool active = 2;
   */
  active = false;

  constructor(data?: PartialMessage<SetOrgStatusRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.biz.v1.SetOrgStatusRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'org_id', kind: 'message', T: UUID },
    { no: 2, name: 'active', kind: 'scalar', T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SetOrgStatusRequest {
    return new SetOrgStatusRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SetOrgStatusRequest {
    return new SetOrgStatusRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): SetOrgStatusRequest {
    return new SetOrgStatusRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: SetOrgStatusRequest | PlainMessage<SetOrgStatusRequest> | undefined,
    b: SetOrgStatusRequest | PlainMessage<SetOrgStatusRequest> | undefined
  ): boolean {
    return proto3.util.equals(SetOrgStatusRequest, a, b);
  }
}

/**
 * @generated from message profiler.biz.v1.SetOrgStatusResponse
 */
export class SetOrgStatusResponse extends Message<SetOrgStatusResponse> {
  constructor(data?: PartialMessage<SetOrgStatusResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.biz.v1.SetOrgStatusResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => []);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SetOrgStatusResponse {
    return new SetOrgStatusResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SetOrgStatusResponse {
    return new SetOrgStatusResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): SetOrgStatusResponse {
    return new SetOrgStatusResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: SetOrgStatusResponse | PlainMessage<SetOrgStatusResponse> | undefined,
    b: SetOrgStatusResponse | PlainMessage<SetOrgStatusResponse> | undefined
  ): boolean {
    return proto3.util.equals(SetOrgStatusResponse, a, b);
  }
}

/**
 * @generated from message profiler.biz.v1.SearchOrgsRequest
 */
export class SearchOrgsRequest extends Message<SearchOrgsRequest> {
  /**
   * @generated from field: optional string name = 1;
   */
  name?: string;

  /**
   * @generated from field: google.protobuf.Timestamp created = 2;
   */
  created?: Timestamp;

  /**
   * @generated from field: repeated int32 industry_doman_ids = 3;
   */
  industryDomanIds: number[] = [];

  constructor(data?: PartialMessage<SearchOrgsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.biz.v1.SearchOrgsRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'name', kind: 'scalar', T: 9 /* ScalarType.STRING */, opt: true },
    { no: 2, name: 'created', kind: 'message', T: Timestamp },
    {
      no: 3,
      name: 'industry_doman_ids',
      kind: 'scalar',
      T: 5 /* ScalarType.INT32 */,
      repeated: true,
    },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SearchOrgsRequest {
    return new SearchOrgsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SearchOrgsRequest {
    return new SearchOrgsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SearchOrgsRequest {
    return new SearchOrgsRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: SearchOrgsRequest | PlainMessage<SearchOrgsRequest> | undefined,
    b: SearchOrgsRequest | PlainMessage<SearchOrgsRequest> | undefined
  ): boolean {
    return proto3.util.equals(SearchOrgsRequest, a, b);
  }
}

/**
 * @generated from message profiler.biz.v1.SearchOrgsResponse
 */
export class SearchOrgsResponse extends Message<SearchOrgsResponse> {
  /**
   * @generated from field: profiler.biz.v1.Orgs orgs = 1;
   */
  orgs?: Orgs;

  constructor(data?: PartialMessage<SearchOrgsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.biz.v1.SearchOrgsResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'orgs', kind: 'message', T: Orgs },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SearchOrgsResponse {
    return new SearchOrgsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SearchOrgsResponse {
    return new SearchOrgsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): SearchOrgsResponse {
    return new SearchOrgsResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: SearchOrgsResponse | PlainMessage<SearchOrgsResponse> | undefined,
    b: SearchOrgsResponse | PlainMessage<SearchOrgsResponse> | undefined
  ): boolean {
    return proto3.util.equals(SearchOrgsResponse, a, b);
  }
}

/**
 * @generated from message profiler.biz.v1.ListOrgsRequest
 */
export class ListOrgsRequest extends Message<ListOrgsRequest> {
  /**
   * @generated from field: iam.auth.v1.IdsRWX ids = 1;
   */
  ids?: IdsRWX;

  constructor(data?: PartialMessage<ListOrgsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.biz.v1.ListOrgsRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'ids', kind: 'message', T: IdsRWX },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListOrgsRequest {
    return new ListOrgsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListOrgsRequest {
    return new ListOrgsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListOrgsRequest {
    return new ListOrgsRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: ListOrgsRequest | PlainMessage<ListOrgsRequest> | undefined,
    b: ListOrgsRequest | PlainMessage<ListOrgsRequest> | undefined
  ): boolean {
    return proto3.util.equals(ListOrgsRequest, a, b);
  }
}

/**
 * @generated from message profiler.biz.v1.ListOrgsResponse
 */
export class ListOrgsResponse extends Message<ListOrgsResponse> {
  /**
   * @generated from field: profiler.biz.v1.Orgs orgs = 1;
   */
  orgs?: Orgs;

  constructor(data?: PartialMessage<ListOrgsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.biz.v1.ListOrgsResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'orgs', kind: 'message', T: Orgs },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListOrgsResponse {
    return new ListOrgsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListOrgsResponse {
    return new ListOrgsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListOrgsResponse {
    return new ListOrgsResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: ListOrgsResponse | PlainMessage<ListOrgsResponse> | undefined,
    b: ListOrgsResponse | PlainMessage<ListOrgsResponse> | undefined
  ): boolean {
    return proto3.util.equals(ListOrgsResponse, a, b);
  }
}

/**
 * @generated from message profiler.biz.v1.UpdateOrgInfoRequest
 */
export class UpdateOrgInfoRequest extends Message<UpdateOrgInfoRequest> {
  /**
   * @generated from field: grpc.type.v1.UUID org_id = 1;
   */
  orgId?: UUID;

  /**
   * @generated from field: string about = 2;
   */
  about = '';

  constructor(data?: PartialMessage<UpdateOrgInfoRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.biz.v1.UpdateOrgInfoRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'org_id', kind: 'message', T: UUID },
    { no: 2, name: 'about', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateOrgInfoRequest {
    return new UpdateOrgInfoRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateOrgInfoRequest {
    return new UpdateOrgInfoRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): UpdateOrgInfoRequest {
    return new UpdateOrgInfoRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: UpdateOrgInfoRequest | PlainMessage<UpdateOrgInfoRequest> | undefined,
    b: UpdateOrgInfoRequest | PlainMessage<UpdateOrgInfoRequest> | undefined
  ): boolean {
    return proto3.util.equals(UpdateOrgInfoRequest, a, b);
  }
}

/**
 * @generated from message profiler.biz.v1.UpdateOrgInfoResponse
 */
export class UpdateOrgInfoResponse extends Message<UpdateOrgInfoResponse> {
  constructor(data?: PartialMessage<UpdateOrgInfoResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.biz.v1.UpdateOrgInfoResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => []);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): UpdateOrgInfoResponse {
    return new UpdateOrgInfoResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateOrgInfoResponse {
    return new UpdateOrgInfoResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): UpdateOrgInfoResponse {
    return new UpdateOrgInfoResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: UpdateOrgInfoResponse | PlainMessage<UpdateOrgInfoResponse> | undefined,
    b: UpdateOrgInfoResponse | PlainMessage<UpdateOrgInfoResponse> | undefined
  ): boolean {
    return proto3.util.equals(UpdateOrgInfoResponse, a, b);
  }
}
