import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { getConnectClient, PlainMsg } from '@services/api/helpers';
import { MembershipAPI } from '@proto/api/iam/v1/membership_connect';
import {
  ListMembersRequest,
  ListMembersResponse,
  ListProfilesMembershipsRequest,
  ListProfilesMembershipsResponse,
  RemoveMemberRequest,
  RemoveMemberResponse,
} from '@proto/iam/membership/v1/membership_pb';
import { useAppDispatch } from '@store/helpers';
import { showSnackbar } from '@store/snackbars';

export const membershipClient = getConnectClient(
  `${process.env.REACT_APP_HTTP_SCHEMA}://${process.env.REACT_APP_APIGW_SERVICE}.${process.env.REACT_APP_API_DOMAIN}`,
  MembershipAPI
);

type ListProfilesMembershipsServiceRequest = PlainMsg<ListProfilesMembershipsRequest>;
type ListProfilesMembershipsServiceResponse = PlainMsg<ListProfilesMembershipsResponse>;

export const useListProfileMemberShips = (payload: ListProfilesMembershipsServiceRequest) => {
  const membershipsResponse = async (): Promise<ListProfilesMembershipsServiceResponse> => {
    return membershipClient.listProfilesMemberships(payload);
  };

  return useQuery({
    queryKey: ['listProfileMemberShips', payload],
    queryFn: membershipsResponse,
  });
};

export const useListMembers = (payload: ListMembersRequest, enabled: boolean) => {
  const membershipsResponse = async (): Promise<ListMembersResponse> => {
    return membershipClient.listMembers(payload);
  };

  return useQuery({
    queryKey: ['listMembers', payload],
    queryFn: membershipsResponse,
    select: (data) => {
      return data;
    },
    enabled,
  });
};

export const useRemoveMember = () => {
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  const removeMember = async (payload: RemoveMemberRequest): Promise<RemoveMemberResponse> => {
    return membershipClient.removeMember(payload);
  };

  return useMutation({
    mutationFn: removeMember,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['listMembers'] });
      dispatch(showSnackbar({ id: 'roles', message: 'Member removed', severity: 'success' }));
    },
    onError: (error) => {
      dispatch(showSnackbar({ id: 'roles', message: error.message, severity: 'error' }));
    },
  });
};
