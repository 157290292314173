import { getConnectClient } from '@services/api/helpers';
import { BreakDownAPI } from '@proto/api/marketplace/v1/break_down_connect';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  AddStepRequest,
  AddStepResponse,
  GetStepRequest,
  GetStepResponse,
  RemoveStepRequest,
  RemoveStepResponse,
  UpdateStepRequest,
  UpdateStepResponse,
  UpdateStepsPositionsRequest,
} from '@proto/marketplace/product/v1/break_down_pb';
import { useAppDispatch } from '@store/helpers';
import { showSnackbar } from '@store/snackbars';

const stepsClient = getConnectClient(
  `${process.env.REACT_APP_HTTP_SCHEMA}://${process.env.REACT_APP_APIGW_SERVICE}.${process.env.REACT_APP_API_DOMAIN}`,
  BreakDownAPI
);

export const useAddStep = () => {
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  const addStep = async (payload: AddStepRequest): Promise<AddStepResponse> => {
    return stepsClient.addStep(payload);
  };

  return useMutation({
    mutationFn: addStep,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getProduct'] });
      dispatch(showSnackbar({ id: 'add-step', message: 'Step added', severity: 'success' }));
    },
    onError: (error) => {
      dispatch(showSnackbar({ id: 'add-step', message: error.message, severity: 'error' }));
    },
  });
};

export const useRemoveStep = () => {
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  const removeStep = async (payload: RemoveStepRequest): Promise<RemoveStepResponse> => {
    return stepsClient.removeStep(payload);
  };

  return useMutation({
    mutationFn: removeStep,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getProduct'] });
      dispatch(showSnackbar({ id: 'remove-step', message: 'Step removed', severity: 'success' }));
    },
    onError: (error) => {
      dispatch(showSnackbar({ id: 'remove-step', message: error.message, severity: 'error' }));
    },
  });
};

export const useGetStep = () => {
  const getStep = async (payload: GetStepRequest): Promise<GetStepResponse> => {
    return stepsClient.getStep(payload);
  };

  return useMutation({
    mutationFn: getStep,
    // onSuccess: () => {
    //     queryClient.invalidateQueries({queryKey: ['listSteps']});
    //     dispatch(
    //         showSnackbar({id: 'get-step', message: 'Step fetched', severity: 'success'})
    //     );
    // },
  });
};

export const useUpdateStep = () => {
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  const updateStep = async (payload: UpdateStepRequest): Promise<UpdateStepResponse> => {
    return stepsClient.updateStep(payload);
  };

  return useMutation({
    mutationFn: updateStep,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getProduct'] });
      dispatch(showSnackbar({ id: 'update-step', message: 'Step updated', severity: 'success' }));
    },
    onError: (error) => {
      dispatch(showSnackbar({ id: 'update-step', message: error.message, severity: 'error' }));
    },
  });
};

export const useUpdateStepsPositions = () => {
  const updateStepsPositions = async (payload: UpdateStepsPositionsRequest) => {
    return stepsClient.updateStepsPositions(payload);
  };

  return useMutation({
    mutationFn: updateStepsPositions,
  });
};
