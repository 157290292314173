import * as React from 'react';
import { useEffect, useState } from 'react';
import { Evaluation } from '@proto/marketplace/demand/v1/evaluation_pb';
import Card from '@components/Card/Card';
import DisabledLink from '@pages/Main/MainPage/Evaluations/DisabledLink';
import { ROUTES_PREFIXES } from '@common/routes';
import { Chip, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { useListEvaluations } from '@services/queries/MarketplaceQueries';
import { Timestamp } from '@bufbuild/protobuf';
import { isInvalidTimestamp } from '@utils/invalidTimestamp';
import CalendarComponentForCandidate from '@pages/Evaluation/EvaluationSchedulesCalendar/CalendarComponentForCandidate';
import { createOccupedEvents } from '@pages/Evaluation/EvaluationSchedulesCalendar/config';
import { useDialog } from '@components/hooks/useDialog';
import {
  calculateTimeLeft,
  formatTimestampToDateString,
} from '@pages/Main/MainPage/Evaluations/config';
import { TagType } from '@proto/profiler/tags/v1/tags_pb';

const EvaluationCard = ({ evaluation }: { evaluation: Evaluation }) => {
  const { data } = useListEvaluations({ refetch: true });
  const { isOpen, handleOpen, handleClose } = useDialog();

  const [timeUntilEvent, setTimeUntilEvent] = useState<React.ReactNode | null>(null);

  useEffect(() => {
    if (evaluation?.status?.scheduledAt) {
      const newTimeUntilEvent = calculateTimeLeft(
        evaluation?.status?.scheduledAt,
        evaluation?.feedback?.status?.sent
      );
      setTimeUntilEvent(newTimeUntilEvent);
    }
  }, [evaluation, data]);

  const isInterviewer = evaluation.participants.case === 'interviewer';
  const isCandidate = evaluation.participants.case === 'candidate';
  const isManager = evaluation.participants.case === 'profiles';

  return (
    <Box
      sx={{ cursor: 'pointer' }}
      width="80%"
      key={evaluation?.evaluationId?.value as string}
      py={1.5}
      className="transition-all hover:scale-105"
    >
      <Card>
        <DisabledLink
          disabled={!!evaluation?.hasAvailableSlots || isInterviewer || isManager}
          noScheduled={
            !isInvalidTimestamp(evaluation.status?.scheduledAt?.toJsonString()) && isCandidate
          }
          handleOpen={handleOpen}
          to={`${ROUTES_PREFIXES.EVALUATION}/${evaluation.evaluationId?.value}`}
        >
          <Box className="flex flex-col gap-2 w-full">
            <Box display="flex" gap={2} flexDirection="column" className="justify-between w-full ">
              <Box className="flex w-full justify-between">
                {!isManager && (
                  <Typography fontSize="13px" fontWeight="bold">
                    {timeUntilEvent}
                  </Typography>
                )}
                {isManager &&
                  !!evaluation?.feedback?.text?.length &&
                  !isInvalidTimestamp(evaluation?.feedback?.status?.accepted?.toJsonString()) && (
                    <Typography className="text-[#7986cb]" fontWeight="bold">
                      New feedback
                    </Typography>
                  )}
                {isInvalidTimestamp(evaluation?.feedback?.status?.accepted?.toJsonString()) && (
                  <Typography className="text-[#14a37f]" fontWeight="bold">
                    Finished
                  </Typography>
                )}
                {isManager &&
                  !evaluation?.feedback?.text?.length &&
                  isInvalidTimestamp(evaluation?.status?.scheduledAt?.toJsonString()) && (
                    <Typography fontWeight="bold" className="text-[#b26500]">
                      Waiting feedback
                    </Typography>
                  )}

                {isManager &&
                  !isInvalidTimestamp(evaluation?.status?.scheduledAt?.toJsonString()) && (
                    <Typography fontWeight="bold">Not scheduled</Typography>
                  )}
                <Typography fontSize="13px" fontWeight="bold">
                  {formatTimestampToDateString(evaluation?.status?.scheduledAt)}
                </Typography>
              </Box>
              <Box display="flex" gap={2}>
                <Box display="flex" alignItems="center">
                  {isInterviewer ? (
                    <Typography fontSize="13px" fontWeight="bold">
                      Interviewer
                    </Typography>
                  ) : null}
                  {isCandidate ? (
                    <Typography fontSize="13px" fontWeight="bold">
                      Candidate
                    </Typography>
                  ) : null}
                  {isManager ? (
                    <Typography fontSize="13px" fontWeight="bold">
                      Manager
                    </Typography>
                  ) : null}
                </Box>

                {data?.tags &&
                  data?.tags[evaluation.interviewId?.value as string]
                    .filter((tag) => tag.tagType === TagType.ROLE)
                    .map((tag) => {
                      return <Chip variant="outlined" label={tag.value} />;
                    })}
              </Box>
            </Box>
          </Box>
        </DisabledLink>
        <CalendarComponentForCandidate
          isDialogOpen={isOpen}
          handleDialogClose={handleClose}
          scheduledAt={evaluation?.status?.scheduledAt as Timestamp}
          evaluationId={evaluation?.evaluationId?.value as string}
          occupedSlots={createOccupedEvents(data?.evaluations || [])}
        />
      </Card>
    </Box>
  );
};

export default EvaluationCard;
