import { Form } from '@components/Form';
import { FIELD_LABELS, FieldNames } from '@pages/Evaluation/config';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import WysiwygController from '@components/Form/controllers/WysiwygController/WysiwygController';
import { useSaveEvaluationFeedbackDraft } from '@services/queries/MarketplaceQueries';
import { Evaluation } from '@proto/marketplace/demand/v1/evaluation_pb';
import UpdateFeedbackButton from '@pages/Evaluation/UpdateFeedbackButton';

type SendEvaluationFeedBackFormProps = {
  evaluationId: string;
  evaluation: Evaluation;
};

const SendEvaluationFeedBackForm = (props: SendEvaluationFeedBackFormProps) => {
  const { evaluationId, evaluation } = props;
  const { mutate: saveEvaluationFeedbackDraft } = useSaveEvaluationFeedbackDraft();

  return (
    <Box className="max-w-[1280px] min-h-[70%] w-full m-auto">
      <Form
        width="100%"
        display="flex"
        alignSelf="center"
        flexDirection="column"
        formProps={{ defaultValues: { evaluationId, feedback: evaluation.feedback?.text || '' } }}
        submitHandler={(data) => {
          saveEvaluationFeedbackDraft(data);
        }}
        // resolverSchema={sendEvaluationFeedbackFormSchema}
      >
        <Box display="flex" flexDirection="column" gap={2}>
          <Box>
            <Typography textAlign="center" variant="subtitle1">
              {FIELD_LABELS[FieldNames.FEEDBACK]}
            </Typography>
            <UpdateFeedbackButton evaluation={evaluation} />
          </Box>

          <WysiwygController name={FieldNames.FEEDBACK} />
        </Box>
      </Form>
    </Box>
  );
};

export default SendEvaluationFeedBackForm;
