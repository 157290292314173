import React, { useRef, useState } from 'react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin, { DateClickArg } from '@fullcalendar/interaction';
import { EventContentArg } from '@fullcalendar/core';
import FullCalendar from '@fullcalendar/react';
import Box from '@mui/material/Box';
import {
  useGetInterviewerrAvailability,
  useOccupySlot,
} from '@services/queries/MarketplaceQueries';
import {
  CalendarEvent,
  convertAvailabilityToEvents,
  convertDateToTimeStamp,
  selectAllow,
} from '@pages/Evaluation/EvaluationSchedulesCalendar/config';
import { Timestamp } from '@bufbuild/protobuf';
import { UUID } from '@proto/grpc/type/v1/uuid_pb';
import listPlugin from '@fullcalendar/list';
import { isInvalidTimestamp } from '@utils/invalidTimestamp';
import ConfirmDialog from '@components/ConfirmDialog/ConfirmDialog';
import { useDialog } from '@components/hooks/useDialog';
import Dialog from '@mui/material/Dialog';

type CalendarComponentProps = {
  isDialogOpen: boolean;
  handleDialogClose: () => void;
  evaluationId: string;
  scheduledAt: Timestamp;
  occupedSlots: CalendarEvent[];
};

const CalendarComponentForCandidate = (props: CalendarComponentProps) => {
  const { isDialogOpen, handleDialogClose, evaluationId, scheduledAt, occupedSlots } = props;
  const calendarRef = useRef<FullCalendar>(null);
  const { mutate: occupySlot } = useOccupySlot();
  const [clickedEvent, setClickedEvent] = useState<DateClickArg | null>(null);

  const { data: availability, isLoading } = useGetInterviewerrAvailability({
    case: 'evaluationId',
    value: new UUID({ value: evaluationId }),
    isEnabled: !isInvalidTimestamp(scheduledAt?.toJsonString() as string),
  });
  const { isOpen, handleOpen, handleClose } = useDialog();

  if (isLoading) return null;

  const initial = convertAvailabilityToEvents(availability?.slots, occupedSlots);

  const handleConfirmClick = () => {
    if (!clickedEvent) return;

    const calendarApi = calendarRef.current?.getApi();

    if (calendarApi) {
      calendarApi.removeAllEvents();

      occupedSlots?.forEach((event: CalendarEvent) => {
        calendarApi.addEvent({
          start: event.start as Date,
          end: event.end as Date,
          color: '#00a0b2',
        });
      });

      calendarApi.addEvent({
        start: clickedEvent.date as Date,
        // end: clickedEvent.event.end as Date,
        color: '#00a0b2',
      });
    }

    occupySlot(
      {
        evaluationId: new UUID({ value: evaluationId }),
        from: convertDateToTimeStamp(clickedEvent.date?.toISOString() as string),
      },
      {
        onSuccess: () => {
          handleClose();
          handleDialogClose();
        },
      }
    );
  };

  const handleDateClick = (info: DateClickArg) => {
    const backgroundEvents = info.view.calendar
      .getEvents()
      .filter(
        (event: any) =>
          event.display === 'background' && info.date >= event.start && info.date <= event.end
      );

    if (backgroundEvents.length > 0) {
      setClickedEvent(info);
      handleOpen();
      console.log(info);
    }
  };

  const renderEventContent = (eventContent: EventContentArg) => (
    <>
      {eventContent.timeText} {eventContent.event.title}
    </>
  );

  return (
    <Dialog open={isDialogOpen} onClose={handleDialogClose}>
      <Box height="80vh" px={6} py={4} width="1200px">
        <FullCalendar
          ref={calendarRef}
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin]}
          headerToolbar={{
            left: 'prev,next today',
            center: '',
            right: 'title',
          }}
          select={(event) => console.log({ event })}
          height="100%"
          initialView="timeGridWeek"
          dayMaxEvents
          allDaySlot={false}
          weekends
          selectable
          initialEvents={initial}
          eventContent={renderEventContent}
          selectAllow={selectAllow}
          scrollTimeReset={false}
          dateClick={handleDateClick}
        />

        <ConfirmDialog
          isOpen={isOpen}
          onClose={handleClose}
          onConfirm={handleConfirmClick}
          title="Підтвердження"
          message="Ви впевнені, що хочете забронювати цей слот?"
        />
      </Box>
    </Dialog>
  );
};
export default CalendarComponentForCandidate;
