import { create } from 'zustand/react';
import { Bell, Connect, SubscribeRequest } from '@proto/notificator/stream/v1/notifications_pb';
import { Group, Message } from '@proto/notificator/stream/v1/chat_pb';
import { getConnectClient } from '@services/api/helpers';
import { NotificationsService } from '@proto/notificator/stream/v1/notifications_connect';

const notificatorClient = getConnectClient(
  `${process.env.REACT_APP_HTTP_SCHEMA}://${process.env.REACT_APP_NOTIFICATION_SERVICE}.${process.env.REACT_APP_API_DOMAIN}`,
  NotificationsService
);

export type ExtendedMessage = Message & {
  direction?: string;
};

interface ChatGroup {
  group: Group;
  messages?: ExtendedMessage[];
  unreadMessages?: number;
}

export interface ChatGroupsMap {
  [key: string]: ChatGroup;
}

interface StreamState {
  controller: AbortController | null;
  isConnected: boolean;
  bellNotifications: Bell[];
  groups: ChatGroupsMap | null;
  clearBellNotifications: () => void;
  addGroups: (newData: ChatGroupsMap) => void;
  getOrCreateController: () => AbortController;
  setController: (controller: AbortController | null) => void;
  addBellNotification: (newData: Bell) => void;
  setIsConnected: (status: boolean) => void;
  addGroup: (newGroup: ChatGroup) => void;
  addMessageToGroup: (groupId: string, message: Message) => void;
  addMessagesToGroup: (groupId: string, messages: Message[]) => void;
  updateMessageTextInGroup: (groupId: string, messageId: string, newText: string) => void;
  removeMessageFromGroup: (groupId: string, messageId: string) => void;
  subscribe: () => Promise<void>;
  cancelStream: () => void;
}

export const useStreamStore = create<StreamState>((set, get) => ({
  controller: null,
  isConnected: false,
  bellNotifications: [],
  groups: {},
  getOrCreateController: (): AbortController => {
    const { controller } = get();
    if (controller) return controller;

    const newController = new AbortController();
    set({ controller: newController });

    return newController;
  },
  setController: (controller: AbortController | null) => set({ controller }),
  addBellNotification: (newData: Bell) => {
    set((state) => {
      const bellId = newData.id?.value as string;

      const isExisting = state.bellNotifications.some((bell) => bell.id?.value === bellId);

      if (!isExisting) {
        return {
          bellNotifications: [...state.bellNotifications, newData],
        };
      }

      return state;
    });
  },
  addGroups: (newData: ChatGroupsMap) => {
    set((state) => {
      const existingGroups = state.groups || {};

      const filteredGroups = Object.keys(newData).reduce((acc, groupId) => {
        if (!existingGroups[groupId]) {
          acc[groupId] = newData[groupId];
        }
        return acc;
      }, {} as ChatGroupsMap);

      return {
        groups: {
          ...existingGroups,
          ...filteredGroups,
        },
      };
    });
  },

  addGroup: (newGroup: ChatGroup) => {
    set((state) => {
      const groupId = newGroup.group.groupId?.value as string;
      return {
        groups: {
          ...state.groups,
          [groupId]: newGroup,
        },
      };
    });
  },
  clearBellNotifications: () => set({ bellNotifications: [] }),
  setIsConnected: (status: boolean) => set({ isConnected: status }),
  addMessageToGroup: (groupId: string, message: ExtendedMessage) => {
    set((state) => {
      if (!state.groups) return state;

      const group = state.groups[groupId];

      if (!group) {
        return state;
      }

      const updatedGroup: ChatGroup = {
        ...group,
        messages: group.messages ? [message, ...group.messages] : [message],
        unreadMessages: group.unreadMessages ? group.unreadMessages + 1 : 1,
      };

      return {
        groups: {
          ...state.groups,
          [groupId]: updatedGroup,
        },
      };
    });
  },
  addMessagesToGroup: (groupId: string, messages: ExtendedMessage[]) => {
    set((state) => {
      if (!state.groups) return state;
      const group = state.groups[groupId];
      if (!group) {
        return state;
      }

      const existingMessages = group.messages || [];
      const newMessages = messages.filter(
        (msg) =>
          !existingMessages.some(
            (existingMsg) => existingMsg.messageId?.value === msg.messageId?.value
          )
      );

      if (newMessages.length === 0) {
        return state;
      }

      const sortedMessages = [...newMessages, ...existingMessages].sort((a, b) => {
        const aCreated = a.created ? Number(a.created.seconds) : 0;
        const bCreated = b.created ? Number(b.created.seconds) : 0;
        return bCreated - aCreated;
      });

      const updatedGroup: ChatGroup = {
        ...group,
        messages: sortedMessages,
        // unreadMessages: (group.unreadMessages || 0) + newMessages.length,
      };

      return {
        groups: {
          ...state.groups,
          [groupId]: updatedGroup,
        },
      };
    });
  },
  subscribe: async () => {
    const {
      getOrCreateController,
      setIsConnected,
      addBellNotification,
      addGroups,
      cancelStream,
      addMessageToGroup,
    } = get();
    const controller = getOrCreateController();
    const { signal } = controller;

    setIsConnected(true);

    try {
      const stream = notificatorClient.subscribe(new SubscribeRequest(), { signal });

      for await (const res of stream) {
        if (res.payload.case === 'bell') {
          addBellNotification(res.payload.value as Bell);
        }

        if (res.payload.case === 'chat') {
          if (res.payload.value.payload.case === 'message') {
            addMessageToGroup(
              res.payload.value.payload.value.groupId?.value as string,
              res.payload.value.payload.value
            );
          }
        }

        if (res.payload.case === 'groups' && res.payload.value.groups?.groups) {
          addGroups(
            res.payload.value.groups?.groups.reduce((acc, group) => {
              if (group.members.length === 1) return acc;

              return {
                ...acc,
                [group.groupId?.value as string]: {
                  group,
                  unreadMessages:
                    (res.payload.value as Connect)?.unreadMessages[
                      group.groupId?.value as string
                    ] || 0,
                },
              };
            }, {} as ChatGroupsMap)
          );
        }
      }
    } catch (e) {
      if (signal.aborted) {
        return;
      }

      setIsConnected(false);
      cancelStream();

      setTimeout(() => {
        get().subscribe();
      }, 5000);
    }
  },

  removeMessageFromGroup: (groupId: string, messageId: string) => {
    set((state) => {
      if (!state.groups || !state.groups[groupId]) return state;
      const group = state.groups[groupId];

      if (!group.messages) return state;

      const updatedMessages = group.messages.filter((msg) => msg.messageId?.value !== messageId);
      if (updatedMessages.length === group.messages.length) {
        return state;
      }

      return {
        groups: {
          ...state.groups,
          [groupId]: {
            ...group,
            messages: updatedMessages,
          },
        },
      };
    });
  },

  updateMessageTextInGroup: (groupId: string, messageId: string, newText: string) => {
    set((state) => {
      if (!state.groups) return state;

      const group = state.groups[groupId];
      if (!group || !group.messages) return state;

      const updatedMessages: ExtendedMessage[] = group.messages.map((msg) => {
        if (msg.messageId?.value === messageId) {
          const updatedMessage = msg.clone();
          updatedMessage.text = newText;
          return updatedMessage as ExtendedMessage;
        }
        return msg;
      });

      return {
        groups: {
          ...state.groups,
          [groupId]: {
            ...group,
            messages: updatedMessages,
          },
        },
      };
    });
  },

  cancelStream: () => {
    const { controller, setIsConnected, setController } = get();
    if (controller) {
      controller.abort();
      setIsConnected(false);
      setController(null);
    }
  },
}));
