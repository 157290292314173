import React, { Suspense, useEffect, useState } from 'react';
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  MouseSensor,
  PointerSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import {
  Step,
  Step_Estimate_Fix,
  Step_Estimate_Rate,
  UpdateStepsPositionsRequest,
} from '@proto/marketplace/product/v1/break_down_pb';
import { UUID } from '@proto/grpc/type/v1/uuid_pb';
import { useUpdateStepsPositions } from '@services/queries/StepsQueries';
import Typography from '@mui/material/Typography';
import { Chip, Tooltip } from '@mui/material';
import CreateStepDialog from '@components/Steps/CreateStepDialog';
import { useDialog } from '@components/hooks/useDialog';
import Box from '@mui/material/Box';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useParams } from 'react-router-dom';
import { useGetProduct } from '@services/queries/ProductsQueries';
import { Page } from '@components/Page/Page';
import Container from '@mui/material/Container';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import BackArrow from '@components/BackArrow/BackArrow';
import Button from '@mui/material/Button';
import { useQueryClient } from '@tanstack/react-query';

export interface SortableItemProps {
  step: Step;
}

const complexityLabels = {
  1: 'Low',
  2: 'Medium',
  3: 'High',
};
export const complexityColors = {
  1: '!bg-[#81c784]',
  2: '!bg-[#fff176]',
  3: '!bg-[#e57373]',
};

export function sortStepsByPosition(steps: Step[]): Step[] {
  return [...steps].sort((a, b) => {
    const positionA = a.data?.position;
    const positionB = b.data?.position;

    if (positionA === undefined && positionB === undefined) return 0;
    if (positionA === undefined) return -1;
    if (positionB === undefined) return 1;

    return positionA - positionB;
  });
}

const SortableItem: React.FC<SortableItemProps & { isLast: boolean; steps: Step[] }> = ({
  step,
  isLast,
  steps,
}) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: step.stepId?.value as string,
  });
  const {
    isOpen: isOpenUpdate,
    handleOpen: handleOpenUpdate,
    handleClose: handleCloseUpdate,
  } = useDialog();
  const {
    isOpen: isOpenCreate,
    handleOpen: handleOpenCreate,
    handleClose: handleCloseCreate,
  } = useDialog();
  const { productId } = useParams();

  const [dialogPosition, setDialogPosition] = useState<number | undefined>(undefined);

  const handleOpenCreateDialog = (position?: number) => {
    setDialogPosition(position);
    handleOpenCreate();
  };

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    backgroundColor: '#04020f',
    color: '#fff',
  };

  return (
    <Box className="flex group">
      <Box
        className="flex w-full items-center gap-2"
        ref={setNodeRef}
        style={style}
        {...attributes}
      >
        {/* Кнопка для драгування */}
        {steps?.length > 1 && (
          <Box
            className="cursor-grab"
            {...listeners} // Прив'язуємо тільки до кнопки
          >
            <Tooltip title="Drag" placement="top">
              <DragIndicatorIcon />
            </Tooltip>
          </Box>
        )}
        <Box
          onClick={handleOpenUpdate}
          className="flex w-full relative border px-6 py-6 border-[rgba(255,255,255,0.12)]  transition justify-center flex-col gap-2"
        >
          {/* Верхня кнопка */}
          <Box
            onClick={(event) => {
              event.stopPropagation();
              handleOpenCreateDialog(step.data?.position); // Передаємо поточну позицію
            }}
            className="absolute flex justify-center transition opacity-0 left-0 group-hover:opacity-100 top-[-14px] w-full h-[50px] cursor-pointer"
          >
            <Tooltip title="Add step " placement="top">
              <AddCircleIcon className="absolute " />
            </Tooltip>
          </Box>

          {/* Нижня кнопка */}

          <Box
            onClick={(event) => {
              event.stopPropagation();
              handleOpenCreateDialog((step.data?.position ?? 0) + 1); // Передаємо позицію + 1
            }}
            className="absolute flex justify-center transition  left-0 group-hover:opacity-100 opacity-0 bottom-[-15px] w-full h-[15px] cursor-pointer"
          >
            <Tooltip title="Add step" placement="bottom">
              <AddCircleIcon className="absolute top-[-13px]" />
            </Tooltip>
          </Box>

          <Box className="flex items-center justify-between">
            <h2 className="text-lg font-bold ">{step.data?.title || 'No Title'}</h2>
          </Box>
          <Box className="flex gap-4">
            <Box className="flex items-center gap-2">
              <Typography>Roles </Typography>
              <Box>
                {step.data?.tags?.tags.map((tag) => (
                  <Chip label={tag.value} />
                ))}
              </Box>
            </Box>
            <Box className="flex items-center gap-2">
              <Typography>Complexity </Typography>
              <Box
                className={`p-[12px] ${
                  complexityColors[step.data?.estimate?.complexity as keyof typeof complexityColors]
                } text-[#000] rounded-full `}
              />
            </Box>
            <Box className="flex items-center gap-2">
              <Typography className="capitalize">
                {step?.data?.estimate?.price.case} price
              </Typography>
              <Chip
                label={`$${
                  (step.data?.estimate?.price.value as Step_Estimate_Rate)?.rate ||
                  (step.data?.estimate?.price.value as Step_Estimate_Fix)?.price
                }`}
              />
            </Box>
            <Box className="flex items-center gap-2">
              <Typography className="capitalize">
                Estimates{' '}
                {(step.data?.estimate?.price.value as Step_Estimate_Rate)?.hours
                  ? 'Hours'
                  : 'Fibonacci'}
              </Typography>
              <Chip
                label={`${
                  (step.data?.estimate?.price.value as Step_Estimate_Rate)?.hours ||
                  (step.data?.estimate?.price.value as Step_Estimate_Fix)?.fibonacci
                }`}
              />
            </Box>
          </Box>
          <p className="text-sm text-gray-400">{step.data?.description || 'No Description'}</p>
        </Box>
      </Box>
      <Suspense>
        <CreateStepDialog step={step} handleClose={handleCloseUpdate} isOpen={isOpenUpdate} />
      </Suspense>
      <Suspense>
        <CreateStepDialog
          productId={new UUID({ value: productId as string })}
          handleClose={handleCloseCreate}
          isOpen={isOpenCreate}
          position={dialogPosition} // Використовуємо оновлену позицію
        />
      </Suspense>
    </Box>
  );
};

const DraggableList = () => {
  const { productId } = useParams();
  const queryClient = useQueryClient();

  const { data: product, error: productError } = useGetProduct(productId as string);
  const steps = sortStepsByPosition(product?.product?.breakDown?.steps || []);
  const [items, setItems] = useState<Step[]>(steps);
  const { mutate: updatePosition } = useUpdateStepsPositions();
  const pointerSensor = useSensor(PointerSensor, {
    activationConstraint: {
      distance: 0.01,
    },
  });
  const { isOpen, handleOpen, handleClose } = useDialog();
  const mouseSensor = useSensor(MouseSensor);
  const touchSensor = useSensor(TouchSensor);

  const sensors = useSensors(mouseSensor, touchSensor, pointerSensor);

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (over && active.id !== over.id) {
      const oldIndex = items.findIndex((item) => (item.stepId?.value as string) === active.id);
      const newIndex = items.findIndex((item) => (item.stepId?.value as string) === over.id);

      setItems((currentItems) => {
        const newItems = arrayMove(currentItems, oldIndex, newIndex);

        const indexedStructure = newItems.reduce((acc, item, index) => {
          acc[index] = item.stepId as UUID;
          return acc;
        }, {} as Record<number, UUID>);
        updatePosition(
          new UpdateStepsPositionsRequest({
            positions: indexedStructure,
          })
        );
        return newItems;
      });
    }
  };

  useEffect(() => {
    // Перевіряємо, чи steps змінилися перед оновленням items
    setItems((prevItems) => {
      if (JSON.stringify(prevItems) !== JSON.stringify(steps)) {
        return steps;
      }
      return prevItems;
    });
  }, [steps]);

  return (
    <Page
      description={`Steps ${product?.product?.title}`}
      title={`Steps ${product?.product?.title}`}
    >
      <Container className="py-4">
        <Box onClick={() => queryClient.invalidateQueries({ queryKey: ['getProduct'] })}>
          <BackArrow link={`/product/${productId}`} />
        </Box>

        {!product?.product?.breakDown?.steps?.length && (
          <Button fullWidth variant="outlined" onClick={handleOpen}>
            Create step
          </Button>
        )}
        <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
          <SortableContext
            items={items.map((item) => item.stepId?.value as string)} // Передаємо лише id для бібліотеки
            strategy={verticalListSortingStrategy}
          >
            <ul className="flex flex-col gap-4 m-auto" style={{ listStyle: 'none', padding: 0 }}>
              {items.map((item, index) => (
                <SortableItem
                  key={item.stepId?.value as string}
                  step={item}
                  isLast={index === items.length - 1}
                  steps={items}
                />
              ))}
            </ul>
          </SortableContext>
        </DndContext>
      </Container>
      <Suspense>
        <CreateStepDialog
          handleClose={handleClose}
          isOpen={isOpen}
          productId={new UUID({ value: productId })}
          breakDowns={product?.product?.breakDown}
        />
      </Suspense>
    </Page>
  );
};

export default DraggableList;
