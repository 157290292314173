import Stack from '@mui/material/Stack';
import LoadingButton from '@mui/lab/LoadingButton';

import { Form } from '@components/Form';
import { InputController } from '@components/Form/controllers/InputController';

import { useProfiler } from '@store/profiler';
import Box from '@mui/material/Box';
import {
  removeHandler,
  selectHandler,
  statusChangeHandler,
} from '@components/TagAutocomplete/config';
import { Entity_Type } from '@proto/grpc/type/v1/entity_pb';
import { TTag } from '@services/api/profiler/tags';
import TagAutocomplete from '@components/TagAutocomplete/TagAutocomplete';
import * as React from 'react';
import { useTags } from '@store/tags';
import { TagType } from '@proto/profiler/tags/v1/tags_pb';
import { organizeTagsByType } from '@pages/ProfileSettings/ProfileTags/ProfileTags';
import { useGetMyProfile } from '@services/queries/ProfilesQueries';
import Typography from '@mui/material/Typography';
import { FIELD_LABELS, FieldNames, getDefaultValues, myProfileNameFormSchema } from './config';

type ProfileNameFormProps = {
  isOnboarding?: () => void;
};

export const ProfileNameForm = (props: ProfileNameFormProps) => {
  const { isOnboarding } = props;
  const { getCountries, countries, addTags, removeTags, setTagstatus } = useTags();

  const { updateProfileName } = useProfiler();
  const { data: userProfile } = useGetMyProfile();

  const defaultTags =
    userProfile?.profile?.tags?.tags &&
    organizeTagsByType(
      userProfile?.profile?.tags?.tags?.filter((tag) => tag.tagType !== TagType.COUNTRY)
    );

  return userProfile ? (
    <Form
      width="100%"
      display="flex"
      alignSelf="center"
      flexDirection="column"
      formProps={{ defaultValues: getDefaultValues(userProfile.profile) }}
      submitHandler={(data) => {
        if (isOnboarding) {
          isOnboarding();
        }
        updateProfileName(data);
      }}
      resolverSchema={myProfileNameFormSchema}
    >
      <Stack spacing={3} direction="column" mb={2} pr={2} className="second-step">
        <Box className="flex flex-col gap-2">
          <Typography className="font-bold" mb={1}>
            Your nickname
          </Typography>
          <InputController
            name={`names.${FieldNames.NICKNAME}`}
            label={FIELD_LABELS[FieldNames.NICKNAME]}
          />
          <Typography className="text-[silver]">
            Other users will be able to find you by your nickname.
          </Typography>
        </Box>

        <Box className="flex flex-col gap-4">
          <Box>
            <Typography className="font-bold" mb={1}>
              Your Name
            </Typography>
            <Typography className="text-[silver]">This is your public display name.</Typography>
          </Box>
          <InputController
            name={`names.${FieldNames.FIRSTNAME}`}
            label={FIELD_LABELS[FieldNames.FIRSTNAME]}
            fullWidth
          />
          <InputController
            name={`names.${FieldNames.LASTNAME}`}
            label={FIELD_LABELS[FieldNames.LASTNAME]}
          />
        </Box>

        {/* <Box className="flex flex-col gap-2"> */}
        {/*    <Typography className="font-bold" mb={1}> */}
        {/*        Your Birthday */}
        {/*    </Typography> */}
        {/*    */}
        {/*     <DatePickerController name={FieldNames.BIRTHDAY}/> */}
        {/*     <Typography className='text-[silver]'>Other users will be able to find you by your */}
        {/*        nickname.</Typography> */}
        {/* </Box> */}

        <Box className="flex flex-col gap-2">
          <Typography className="font-bold" mb={1}>
            Your Countries
          </Typography>
          <TagAutocomplete
            items={countries}
            getItems={getCountries}
            defaultItems={defaultTags?.TAG_TYPE_COUNTRY || []}
            label="Locations you usually work from"
            statusChanger={(id, status) => {
              statusChangeHandler(
                id,
                {
                  tagType: TagType.COUNTRY,
                  entity: Entity_Type.USER,
                  entityId: userProfile?.profile?.profileId,
                  status,
                },
                setTagstatus
              );
            }}
            onSelect={(tag: TTag) =>
              selectHandler(
                tag,
                {
                  tagType: TagType.COUNTRY,
                  entity: Entity_Type.USER,
                  entityId: userProfile?.profile?.profileId,
                },
                addTags
              )
            }
            onRemove={(tag: TTag) =>
              removeHandler(
                tag,
                {
                  tagType: TagType.COUNTRY,
                  entity: Entity_Type.USER,
                  entityId: userProfile?.profile?.profileId,
                },
                removeTags
              )
            }
          />
          <Typography className="text-[silver]">Countries where you can work</Typography>
        </Box>
      </Stack>
      <Box pb={2} display="flex">
        <LoadingButton type="submit" variant="contained">
          {isOnboarding ? 'Next' : 'Update profile info'}
        </LoadingButton>
      </Box>
    </Form>
  ) : null;
};
