import * as React from 'react';
import { useMarketplace } from '@store/marketplace';
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import { sortDemandsByDate } from '@pages/Marketplace/Dashboards/config';
import { useListFeedItems } from '@services/queries/MarketplaceQueries';
import { Typography } from '@mui/material';
import { Interview } from '@proto/marketplace/demand/v1/interview_pb';
import { Contract } from '@proto/marketplace/demand/v1/contract_pb';
import DemandCard from '@pages/Marketplace/Feed/DemandCard';

export const DateDisplay: React.FC<{ date: string; showDate: boolean }> = ({ date, showDate }) => (
  <Box
    width="10%"
    display="flex"
    justifyContent="center"
    alignItems="center"
    flexDirection="column"
  >
    {showDate && (
      <Typography fontSize="10px" color="textSecondary">
        {date}
      </Typography>
    )}
    <Box
      sx={{
        height: showDate ? '110%' : '120%',
        width: '1px',
        backgroundColor: '#424242',
        marginLeft: 2,
        marginRight: 2,
      }}
    />
  </Box>
);

const FeedList = () => {
  const { data: feedListResponse } = useListFeedItems();
  const { displayListType } = useMarketplace();
  const contractList = Object.values(feedListResponse?.feedItems?.contracts || {});
  const interviewsList = Object.values(feedListResponse?.feedItems?.interviews || {});

  const displayOptions = React.useMemo(
    () => ({
      all: [...contractList, ...interviewsList],
      contracts: contractList,
      interviews: interviewsList,
    }),
    [contractList, interviewsList]
  );

  const memoizedDisplayList = React.useMemo(
    () => sortDemandsByDate(displayOptions[displayListType as keyof typeof displayOptions]),
    [displayOptions, displayListType]
  );
  const displayedDates = new Map<string, boolean>();

  return (
    <Fade in timeout={500}>
      <Box
        flexDirection="column"
        display="flex"
        position="relative"
        alignItems="flex-end"
        gap={1}
        px={1}
      >
        <Box display="flex" flexDirection="column" width="100%">
          {memoizedDisplayList &&
            memoizedDisplayList.map((demand) => {
              const date = new Date(
                demand?.entity?.value?.createdAt?.toDate() || ''
              ).toLocaleDateString();
              let showDate = false;

              if (!displayedDates.has(date)) {
                displayedDates.set(date, true);
                showDate = true;
              }

              return (
                <Box
                  key={
                    (demand.entity.value as Interview).interviewId?.value ||
                    (demand.entity.value as Contract).contractId?.value
                  }
                  display="flex"
                  width="100%"
                  gap={4}
                >
                  <DemandCard demand={demand} />
                  <DateDisplay date={date} showDate={showDate} />
                </Box>
              );
            })}
        </Box>
      </Box>
    </Fade>
  );
};

export default FeedList;
