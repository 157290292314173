import { getConnectClient } from '@services/api/helpers';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { useAppDispatch } from '@store/helpers';
import { showSnackbar } from '@store/snackbars';
import { SquadAPI } from '@proto/api/profiler/v1/squad_connect';
import {
  CreateSquadRequest,
  CreateSquadResponse,
  GetSquadResponse,
  SetSquadStatusRequest,
  Squad,
  UpdateSquadInfoRequest,
} from '@proto/profiler/biz/v1/squad_pb';
import { UUID } from '@proto/grpc/type/v1/uuid_pb';
import { Empty } from '@bufbuild/protobuf';
import { GetSquadRequest, ListSquadsRequest } from '@proto/api/profiler/v1/squad_pb';
import { Filter } from '@proto/grpc/type/v1/entity_pb';

export const squadClient = getConnectClient<typeof SquadAPI>(
  `${process.env.REACT_APP_HTTP_SCHEMA}://${process.env.REACT_APP_APIGW_SERVICE}.${process.env.REACT_APP_API_DOMAIN}`,
  SquadAPI
);

export const useCreateSquad = () => {
  const dispatch = useAppDispatch();

  const queryClient = useQueryClient();
  const createSquad = async (payload: CreateSquadRequest): Promise<CreateSquadResponse> => {
    return squadClient.createSquad(payload);
  };

  return useMutation({
    mutationFn: createSquad,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['listUserSquads'] });
      dispatch(
        showSnackbar({
          id: 'createSquad',
          message: 'Squad created successfully',
          severity: 'success',
        })
      );
    },
    onError: (error) => {
      dispatch(
        showSnackbar({
          id: 'createSquad',
          message: error.message,
          severity: 'error',
        })
      );
    },
  });
};

export const useGetSquad = (payload: { value: string | UUID; case: 'slug' | 'id' }) => {
  const getSquad = async (): Promise<GetSquadResponse> => {
    return squadClient.getSquad(
      new GetSquadRequest({
        filter: new Filter({
          by:
            payload.case === 'id'
              ? { case: 'id', value: new UUID({ value: payload.value as string }) }
              : { case: 'slug', value: payload.value as string },
        }),
      })
    );
  };

  return useQuery({
    queryKey: ['currentSquad', payload],
    queryFn: getSquad,
  });
};

export const useSetSquadStatus = () => {
  const setSquadStatus = async (payload: { squadId: string; status: boolean }) => {
    return squadClient.setSquadStatus(
      new SetSquadStatusRequest({
        squadId: new UUID({ value: payload.squadId }),
        active: payload.status,
      })
    );
  };

  return useMutation({
    mutationFn: setSquadStatus,
  });
};

export const useUpdateSquadInfo = () => {
  const queryClient = useQueryClient();
  const updateSquadInfo = async (payload: { squadId: string; about: string }) => {
    return squadClient.updateSquadInfo(
      new UpdateSquadInfoRequest({
        squadId: new UUID({ value: payload.squadId }),
        about: payload.about,
      })
    );
  };

  return useMutation({
    mutationFn: updateSquadInfo,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['currentSquad'] });
    },
  });
};

export const useListUserSquads = () => {
  const listSquads = async () => {
    return squadClient.listUserSquads(Empty);
  };

  return useQuery({
    queryKey: ['listUserSquads'],
    queryFn: listSquads,
  });
};

export const useListSquads = (payload: ListSquadsRequest) => {
  const listSquads = async () => {
    return squadClient.listSquads(payload);
  };

  return useQuery({
    queryKey: ['listSquads', payload],
    queryFn: listSquads,
    select: (data) => {
      return data.squads?.squads.reduce((acc, squad) => {
        return { ...acc, [squad.squadId?.value as string]: squad };
      }, {} as { [key: string]: Squad });
    },
  });
};
