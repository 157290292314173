// @generated by protoc-gen-es v1.9.0 with parameter "target=ts"
// @generated from file api/profiler/v1/squad.proto (package api.profiler.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type {
  BinaryReadOptions,
  FieldList,
  JsonReadOptions,
  JsonValue,
  PartialMessage,
  PlainMessage,
} from '@bufbuild/protobuf';
import { Message, proto3 } from '@bufbuild/protobuf';
import { Filter } from '../../../grpc/type/v1/entity_pb.js';
import { UUIDS } from '../../../grpc/type/v1/uuid_pb.js';

/**
 * @generated from message api.profiler.v1.GetSquadRequest
 */
export class GetSquadRequest extends Message<GetSquadRequest> {
  /**
   * @generated from field: grpc.type.v1.Filter filter = 1;
   */
  filter?: Filter;

  constructor(data?: PartialMessage<GetSquadRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'api.profiler.v1.GetSquadRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'filter', kind: 'message', T: Filter },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetSquadRequest {
    return new GetSquadRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetSquadRequest {
    return new GetSquadRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetSquadRequest {
    return new GetSquadRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: GetSquadRequest | PlainMessage<GetSquadRequest> | undefined,
    b: GetSquadRequest | PlainMessage<GetSquadRequest> | undefined
  ): boolean {
    return proto3.util.equals(GetSquadRequest, a, b);
  }
}

/**
 * @generated from message api.profiler.v1.ListSquadsRequest
 */
export class ListSquadsRequest extends Message<ListSquadsRequest> {
  /**
   * @generated from field: grpc.type.v1.UUIDS ids = 1;
   */
  ids?: UUIDS;

  constructor(data?: PartialMessage<ListSquadsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'api.profiler.v1.ListSquadsRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'ids', kind: 'message', T: UUIDS },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListSquadsRequest {
    return new ListSquadsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListSquadsRequest {
    return new ListSquadsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListSquadsRequest {
    return new ListSquadsRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: ListSquadsRequest | PlainMessage<ListSquadsRequest> | undefined,
    b: ListSquadsRequest | PlainMessage<ListSquadsRequest> | undefined
  ): boolean {
    return proto3.util.equals(ListSquadsRequest, a, b);
  }
}
