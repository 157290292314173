import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import React from 'react';
import { Form } from '@components/Form';
import { InputController } from '@components/Form/controllers/InputController';
import LoadingButton from '@mui/lab/LoadingButton';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { useAddStep, useRemoveStep, useUpdateStep } from '@services/queries/StepsQueries';
import { UUID } from '@proto/grpc/type/v1/uuid_pb';
import { createStepSchema, FieldNames, getDefaultValues } from '@components/Steps/config';
import WysiwygController from '@components/Form/controllers/WysiwygController/WysiwygController';
import { SelectController } from '@components/Form/controllers/SelectController/SelectController';
import { createSelectOptions } from '@utils/createSelectOptionsFromEnum';
import {
  AddStepRequest,
  BreakDown,
  RemoveStepRequest,
  Step,
  Step_Data,
  Step_Estimate,
  Step_Estimate_Complexity,
  Step_Estimate_Fix,
  Step_Estimate_Fix_Fibonacci,
  Step_Estimate_Rate,
  UpdateStepRequest,
} from '@proto/marketplace/product/v1/break_down_pb';
import Button from '@mui/material/Button';
import { MaterialUISwitch } from '@components/Steps/Switch';
import AutocompleteController from '@components/Form/controllers/AutocompleteController/AutocompleteController';
import { useTags } from '@store/tags';
import { Tags } from '@proto/profiler/tags/v1/tags_pb';
import { getTagsFromPayload } from '@store/marketplace/helpers';

type CreateProductFormProps = {
  isOpen: boolean;
  handleClose: () => void;
  productId?: UUID;
  step?: Step;
  breakDowns?: BreakDown;
  position?: number;
};

function createStepEstimate(
  isRate: boolean,
  price: number,
  hours: number,
  fibonacci: Step_Estimate_Fix_Fibonacci,
  complexity: Step_Estimate_Complexity
): Step_Estimate {
  if (isRate) {
    return new Step_Estimate({
      price: {
        case: 'rate',
        value: new Step_Estimate_Rate({
          rate: price,
          hours,
        }),
      },
      complexity,
    });
  }
  return new Step_Estimate({
    price: {
      case: 'fix',
      value: new Step_Estimate_Fix({
        price,
        fibonacci,
      }),
    },
    complexity,
  });
}

// Функція для створення Step_Data
function createStepData(
  title: string,
  description: string,
  position: number,
  estimate: Step_Estimate,
  tags: Tags
): Step_Data {
  return new Step_Data({
    title,
    description,
    position,
    estimate,
    tags,
  });
}

function buildAddStepRequest(
  priceCase: string,
  productId: UUID,
  data: any, // тут використовуй конкретний тип для даних, що надходять
  position: number,
  breakDowns: BreakDown | undefined
): AddStepRequest {
  const stepEstimate = createStepEstimate(
    priceCase === 'rate',
    Number(data.price),
    Number(data.hours),
    data.fibonacci as Step_Estimate_Fix_Fibonacci,
    data.complexity as Step_Estimate_Complexity
  );

  const stepData = createStepData(
    data.title as string,
    data.description as string,
    breakDowns?.steps?.length || position || 0,
    stepEstimate,
    new Tags({ tags: getTagsFromPayload(data) })
  );

  return new AddStepRequest({
    productId,
    data: stepData,
  });
}

function buildUpdateStepRequest(stepId: UUID, data: any, isRate: boolean): UpdateStepRequest {
  const estimate = new Step_Estimate({
    price: isRate
      ? {
          case: 'rate',
          value: new Step_Estimate_Rate({
            hours: Number(data.hours),
            rate: Number(data.price),
          }),
        }
      : {
          case: 'fix',
          value: new Step_Estimate_Fix({
            price: Number(data.price),
            fibonacci: data.fibonacci as Step_Estimate_Fix_Fibonacci,
          }),
        },
    complexity: data.complexity as Step_Estimate_Complexity,
  });

  const stepData = new Step_Data({
    title: data.title as string,
    description: data.description as string,
    estimate,
    tags: new Tags({ tags: data.roles }),
  });

  return new UpdateStepRequest({
    step: new Step({
      stepId,
      data: stepData,
    }),
  });
}

const CreateStepDialog = (props: CreateProductFormProps) => {
  const { isOpen, handleClose, productId, step, breakDowns, position } = props;
  const [isRate, setIsRate] = React.useState(step?.data?.estimate?.price.case === 'rate');
  const { roles, getRoles } = useTags();
  const memoizedRoles = React.useMemo(() => roles, [roles]);
  const { mutate: addStep } = useAddStep();
  const { mutate: updateStep } = useUpdateStep();
  const { mutate: removeStep } = useRemoveStep();
  console.log('isRate', isRate);
  const complexityOptions = createSelectOptions(Step_Estimate_Complexity, [
    Step_Estimate_Complexity.UNSPECIFIED,
  ]);
  const fibonacciOptions = createSelectOptions(Step_Estimate_Fix_Fibonacci, [
    Step_Estimate_Fix_Fibonacci.FIBONACCI_UNSPECIFIED,
  ]).map((option) => ({ ...option, label: `${option.value}` }));

  return (
    <Dialog onClose={handleClose} open={isOpen}>
      <Box
        display="flex"
        className="w-full no-drag max-w-[848px]"
        flexDirection="column"
        gap={1}
        py={5}
        px={{ xs: 3 }}
      >
        <Box display="flex" gap={2}>
          <Form
            width="100%"
            minWidth="800px"
            display="flex"
            alignSelf="center"
            flexDirection="column"
            formProps={{ defaultValues: getDefaultValues(step) }}
            resolverSchema={createStepSchema}
            submitHandler={(data) => {
              console.log({ data, tags: new Tags({ tags: data.roles }) });
              handleClose();

              if (step) {
                const request = buildUpdateStepRequest(step.stepId!, data, isRate);
                updateStep(request);
              }

              if (productId) {
                const addRequest = buildAddStepRequest(
                  isRate ? 'rate' : 'fix',
                  productId,
                  data,
                  position || 0,
                  breakDowns
                );
                addStep(addRequest);
              }
            }}
          >
            <Box display="flex" flexDirection="column" gap={2}>
              <Typography variant="subtitle1">{step ? 'Update Step' : 'Create Step'}</Typography>
              <AutocompleteController
                getItems={getRoles}
                multiple
                name={`${FieldNames.ROLES}`}
                label="Select the role"
                options={memoizedRoles}
              />
              <InputController
                name={FieldNames.TITLE}
                label="Enter a compelling title for your product to attract potential clients"
              />
              <Box display="flex" flexDirection="column" gap={0.5}>
                <Typography fontWeight="bold">
                  Write a comprehensive description to give an overview of what your product offers
                </Typography>
                <WysiwygController name={FieldNames.DESCRIPTION} />
              </Box>
              <Box className="flex flex-col gap-1">
                <Typography className="!font-bold">Price type</Typography>
                <Box className="flex items-center">
                  <Typography>Rate</Typography>
                  <MaterialUISwitch
                    checked={!isRate}
                    onChange={() => setIsRate(!isRate)}
                    value={isRate}
                  />
                  <Typography>Fixed</Typography>
                </Box>
              </Box>

              {/* <RadioController name={FieldNames.PRICE_CASE} label="Price type"/> */}
              <InputController name={FieldNames.PRICE} label="Price" />
              {!isRate && (
                <SelectController
                  label="Fibonacci"
                  options={fibonacciOptions}
                  name={FieldNames.FIBONACCI}
                />
              )}
              {isRate && <InputController name={FieldNames.HOURS} label="Hours" />}

              <SelectController
                label="Complexity"
                options={complexityOptions}
                name={FieldNames.COMPLEXITY}
              />

              <Divider />
              <Box display="flex" className="gap-2" justifyContent="flex-end">
                {step && !productId && (
                  <Box>
                    <Button
                      variant="outlined"
                      color="error"
                      onClick={() =>
                        removeStep(new RemoveStepRequest({ stepId: step?.stepId }), {
                          onSuccess: handleClose,
                        })
                      }
                    >
                      Remove
                    </Button>
                  </Box>
                )}
                <LoadingButton type="submit" color="success" variant="outlined">
                  {step ? 'Update' : 'Create'}
                </LoadingButton>
              </Box>
            </Box>
          </Form>
        </Box>
      </Box>
    </Dialog>
  );
};

export default CreateStepDialog;
