import React, { Suspense, useCallback, useMemo } from 'react';
import { Box, Button, Chip, Divider, Typography } from '@mui/material';
import { format } from 'date-fns';
import { useMarketplace } from '@store/marketplace';
import DemandTraits from '@pages/Marketplace/Dashboards/DemandTraits';
import Card from '@components/Card/Card';
import { Demand } from '@proto/marketplace/demand/v1/demand_pb';
import { getDemandId } from '@pages/Marketplace/Feed/config';
import Tags from '@components/Tags/Tags';
import ContractorSelect from '@pages/Marketplace/Feed/ContractorSelect';
import {
  useApplyAsInterviewer,
  useGetInterviewerrAvailability,
  useListAppliedDemands,
  useListFeedItemsByDemand,
} from '@services/queries/MarketplaceQueries';
import { Entity } from '@proto/grpc/type/v1/entity_pb';
import { demandsTypes } from '@pages/Marketplace/Feed/FeedPage';
import { TagType } from '@proto/profiler/tags/v1/tags_pb';
import { UUID } from '@proto/grpc/type/v1/uuid_pb';
import CustomAvatar from '@components/Avatar/Avatar';
import { useGetMyProfile } from '@services/queries/ProfilesQueries';
import CalendarComponentForInterviewer from '@pages/Evaluation/EvaluationSchedulesCalendar/CalendarComponentForInterviewer';
import { useDialog } from '@components/hooks/useDialog';
import { Interview } from '@proto/marketplace/demand/v1/interview_pb';
import { Contract } from '@proto/marketplace/demand/v1/contract_pb';

interface PreAppliedDemandCardProps {
  demand: Demand;
  isPreApplied?: boolean;
  showActiveState?: boolean;
}

const PreAppliedDemandCard: React.FC<PreAppliedDemandCardProps> = React.memo(
  ({ demand, isPreApplied, showActiveState }) => {
    const { demandIdFromUrl } = useMarketplace();
    const demandId = getDemandId(demand);
    const { mutate: applyAsInterviewer } = useApplyAsInterviewer();

    const entity = useMemo(() => {
      return demandIdFromUrl
        ? new Entity({
            id: new UUID({ value: demandIdFromUrl.demandId }),
            type: demandsTypes[demandIdFromUrl.demandType as keyof typeof demandsTypes],
          })
        : undefined;
    }, [demandIdFromUrl]);

    const { data: feedByContract } = useListFeedItemsByDemand(entity);
    const [matchedTags, setMatchedTags] = React.useState<{ tags: any; traits: any } | null>(null);
    const { isOpen, handleOpen, handleClose } = useDialog();
    const { data: appliedDemands } = useListAppliedDemands({ refetch: false });
    const { data: profile } = useGetMyProfile();
    const { data: slots } = useGetInterviewerrAvailability({
      case: 'interviewerId',
      value: profile?.profile?.profileId as UUID,
    });
    console.log({ appliedDemands });

    const isHaveAvailibilitySlots = useMemo(() => !!slots && slots?.slots?.length > 0, [slots]);

    const recommendations = useMemo(
      () =>
        feedByContract?.recommendations?.recommendations[demandId?.value as string]
          ?.recommendations || [],
      [feedByContract, demandId]
    );

    const isDemandPresent = useMemo(() => {
      const interviewsApplied = appliedDemands?.demands?.applied?.interviews || {};
      const interviewsApproved = appliedDemands?.demands?.approved?.interviews || {};

      return [...Object.values(interviewsApplied), ...Object.values(interviewsApproved)].some(
        (interview) => (interview.entity.value as Interview)?.interviewId?.value === demandId?.value
      );
    }, [appliedDemands, demandId]);

    const handleSupplierClick = useCallback((sup: any) => {
      setMatchedTags({
        tags: sup.matchedTags?.tags,
        traits: sup?.traits,
      });
    }, []);

    const suppliers = useMemo(
      () =>
        recommendations &&
        Object.values(recommendations).map((supplier: any) => (
          <Box key={supplier.id}>
            {supplier?.suppliers?.map((sup: any) => (
              <Box
                key={sup.profile.profileId.value}
                onClick={() => handleSupplierClick(sup)}
                display="flex"
                gap={1}
                sx={{ cursor: 'pointer' }}
              >
                <CustomAvatar
                  alt={sup?.profile?.name?.nickName || 'User'}
                  avatarId={sup?.profile?.avatar}
                  profileId={sup?.profile?.profileId?.value}
                />
                <Typography>{sup?.profile?.name?.nickName || '?rec supplier name'}</Typography>
              </Box>
            ))}
          </Box>
        )),
      [recommendations, handleSupplierClick]
    );

    return (
      <Box className={demandIdFromUrl === demandId && showActiveState ? 'matchedCard' : ''}>
        <Card>
          <Box display="flex" gap={1} flexDirection="column" justifyContent="center" mb={2}>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Box display="flex" gap={2} alignItems="center">
                <Typography fontSize="14px" fontWeight="bold">
                  {demand?.entity?.case}
                </Typography>
                {demand.entity.value?.tags?.tags
                  .filter((tag: any) => tag.tagType === TagType.ROLE)
                  .map((tag: any) => (
                    <Chip key={tag.tagId} variant="outlined" label={tag.value} />
                  ))}
              </Box>
              <Typography fontSize="14px">
                {format(new Date(demand.entity.value?.createdAt?.toDate() || ''), 'dd/MM/yyyy')}
              </Typography>
            </Box>
            <Divider />
            {suppliers && (
              <Box display="flex" gap={1} flexDirection="column">
                <Typography fontWeight="bold">This user is eligible for this demand</Typography>
                {suppliers}
                <Divider />
              </Box>
            )}

            <Box display="flex" gap={1} justifyContent="space-between">
              <Box display="flex" flexDirection="column" gap={1}>
                <DemandTraits
                  matches={matchedTags?.traits}
                  fontSize="13px"
                  demand={demand.entity.value}
                />
                {demand.entity.value?.tags?.tags && (
                  <Tags
                    matchedTags={matchedTags?.tags}
                    showType
                    tags={demand.entity.value?.tags?.tags}
                  />
                )}
              </Box>
            </Box>
            {(demand?.entity?.value as Interview)?.requirements ||
              (demand?.entity?.value as Contract)?.description}
          </Box>
          <Box display="flex">
            {demand?.entity?.case === 'interview' && (
              <Button
                disabled={isDemandPresent}
                variant="contained"
                onClick={() => {
                  if (!isHaveAvailibilitySlots) handleOpen();
                  if (isHaveAvailibilitySlots)
                    applyAsInterviewer(demand?.entity?.value?.interviewId?.value || '');
                }}
              >
                {isDemandPresent ? 'You have been applied' : 'Apply'}
              </Button>
            )}
          </Box>
          {isPreApplied && demand?.entity?.case === 'contract' && (
            <ContractorSelect contractId={demandId?.value} />
          )}
        </Card>
        <Suspense>
          <CalendarComponentForInterviewer handleClose={handleClose} isOpen={isOpen} />
        </Suspense>
      </Box>
    );
  }
);

export default PreAppliedDemandCard;
