import { Evaluation } from '@proto/marketplace/demand/v1/evaluation_pb';
import { Timestamp } from '@bufbuild/protobuf';
import * as React from 'react';
import { ReactNode, useEffect, useState } from 'react';
import { isInvalidTimestamp } from '@utils/invalidTimestamp';
import { Typography } from '@mui/material';

export function sortEvaluationsByScheduledAt(evaluations: Evaluation[] | undefined): Evaluation[] {
  if (!evaluations) {
    return [];
  }
  const now = Date.now();

  return evaluations.sort((a, b) => {
    const aScheduledAt = a.status?.scheduledAt?.toDate().getTime() || 0;
    const bScheduledAt = b.status?.scheduledAt?.toDate().getTime() || 0;

    if (aScheduledAt > now && bScheduledAt > now) {
      return aScheduledAt - bScheduledAt;
    }

    if (aScheduledAt > now && bScheduledAt <= now) {
      return -1;
    }

    if (aScheduledAt <= now && bScheduledAt > now) {
      return 1;
    }

    return bScheduledAt - aScheduledAt;
  });
}

export function calculateTimeLeft(
  timestamp: Timestamp | undefined,
  feedbackSent: Timestamp | undefined
): ReactNode | null {
  if (!timestamp) {
    return null;
  }
  const isSent = isInvalidTimestamp(feedbackSent?.toJsonString());

  if (!isInvalidTimestamp(timestamp?.toJsonString())) {
    return <Typography>Event not scheduled</Typography>;
  }

  const eventDate = new Date(Number(timestamp.seconds) * 1000 + timestamp.nanos / 1e6);
  const now = new Date();
  const timeDifferenceMs = eventDate.getTime() - now.getTime();

  if (timeDifferenceMs <= 0) {
    return (
      <Typography>
        The event has already happened. <br />{' '}
        <span className={`${!isSent ? 'text-[#7986cb]' : 'text-[#14a37f]'}`}>
          {!isSent ? 'Dont forget to send feedback' : 'Feedback  sent'}
        </span>
      </Typography>
    );
  }

  const totalSeconds = Math.floor(timeDifferenceMs / 1000);
  const totalMinutes = Math.floor(totalSeconds / 60);
  const totalHours = Math.floor(totalMinutes / 60);
  const totalDays = Math.floor(totalHours / 24);

  if (totalDays >= 1) {
    return (
      <Typography>
        Left until the event <span className="text-[#7986cb] font-bold"> {totalDays} days</span>
      </Typography>
    );
  }

  if (totalHours >= 1) {
    return (
      <Typography>
        Less than <span className="text-[#7986cb]">{totalHours} hours </span> left until the event
      </Typography>
    );
  }

  return (
    <Typography>
      Less than <span className="text-[#7986cb]">{totalMinutes} minutes</span> left until the event
    </Typography>
  );
}

export function useTimeUntilEvent(
  timestamp: Timestamp | undefined,
  feedbackSent: Timestamp | undefined
): ReactNode {
  const [timeLeft, setTimeLeft] = useState<ReactNode | null>(() =>
    calculateTimeLeft(timestamp, feedbackSent)
  );

  useEffect(() => {
    if (!timestamp) {
      return () =>
        setInterval(() => {
          setTimeLeft(calculateTimeLeft(new Timestamp(), feedbackSent));
        }, 3600000);
    }

    const eventDate = new Date(Number(timestamp.seconds) * 1000 + timestamp.nanos / 1e6);
    const now = new Date();
    const timeDifferenceMs = eventDate.getTime() - now.getTime();

    let interval: NodeJS.Timeout | undefined;

    if (timeDifferenceMs > 0 && timeDifferenceMs <= 3600000) {
      interval = setInterval(() => {
        setTimeLeft(calculateTimeLeft(timestamp, feedbackSent));
      }, 60000);
    } else if (timeDifferenceMs > 3600000 && timeDifferenceMs <= 86400000) {
      interval = setInterval(() => {
        setTimeLeft(calculateTimeLeft(timestamp, feedbackSent));
      }, 3600000);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [timestamp, feedbackSent]);

  return timeLeft;
}

export function formatTimestampToDateString(timestamp: Timestamp | undefined): ReactNode | null {
  if (!timestamp) {
    return null;
  }
  if (!isInvalidTimestamp(timestamp?.toJsonString())) {
    return null;
  }

  const date = timestamp.toDate();

  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const weekday = date.toLocaleString('en-US', { weekday: 'long' });
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');

  return (
    <Typography className="font-bold">{`${hours}:${minutes}  ${weekday} ${day}.${month}`}</Typography>
  );
}
